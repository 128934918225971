module.exports = {
    "nav": {
        "shouye": "Awal",
        "hanqing": "Pasar",
        "jiaoyi": "Perdagangan",
        "heyue": "Swap",
        "wode": "Saya"
    },
    "common": {
        "login": "Masuk",
        "register": "Daftar",
        "loginregister": "Masuk / Daftar",
        "logout": "Keluar",
        "tip": "Peringatan",
        "delete": "Anda yakin akan menghapus?",
        "set": "Pengaturan",
        "update": "Perbaiki",
        "more": "Lainnya",
        "loading": "Memuat",
        "zanwu": "Tidak ada Data",
        "quxiao": "Batal",
        "ok": "Yakin",
        "noyanzhengma": "Kode CAPTCHA  salah",
        "clearStorage": "Hapus Cache",
        "telErr": "Masukkan nomor ponsel yang benar",
        "T404": "Platform sedang diperbarui, dan akses akan dipulihkan nanti"
    },
    "loginPage": {
        "mima": "Kata Sandi",
        "passwordTxt": "Masukkan Kata Sandi"
    },
    "homePage": {
        "charge": "Isi ulang",
        "pickup": "Withdraw",
        "exchange": "Spot",
        "promotionaward": "Pengembalian"
    },
    "pageTitle": {
        "gonggaoxiangqing": "Pengumuman",
        "wodezichang": "Saldo",
        "bangzhuxiangqing": "Rincian",
        "fundpwd": "Kata Sandi Dana",
        "yuyanshezhi": "Bahasa",
        "huazhuang2": "Transfer",
        "renzheng": "Verifikasi",
        "chooseAlterPhoneType": "Nomor Ponsel",
        "chooseAlterEmailType": "Email",
        "letterList": "Pemberitahuan",
        "appDownload": "Unduh App",
        "buy": "Beli",
        "sell": "Jual",
        "cexiao": "Batal",
        "time": "Waktu",
        "type": "Tipe",
        "jiage": "Harga",
        "weituoliang": "Jumlah",
        "changjiao": "Berhasil",
        "weituoe": "Pembayaran",
        "over": "Semua dimuat",
        "weituojia": "Harga komisi",
        "weituoer": "Jumlah total",
        "quedingchedan": "Apakah yakin dibatalkan?",
        "chedaning": "Dibatalkan",
        "jiaoyidui": "Pasangan koin",
        "fangxiang": "Arah",
        "chongzhi": "Atus ulang",
        "xianjia": "Limit",
        "shijia": "Harga Pasar",
        "plan": "Rencana",
        "quanbu": "Semua",
        "xianjiaweituo": "Pesanan terbatas",
        "yichexiao": "Dibatalkan",
        "time1": "Waktu mulai harus lebih awal dari waktu berakhir",
        "time2": "Waktu berakhir tidak boleh kurang dari waktu mulai",
        "time3": "Waktu berakhir harus lebih awal dari saat ini",
        "cedancg": "Berhasil dibatalkan",
        "cedansb": "Gagal dibatalkan",
        "mairujiage": "Harga unit",
        "yongxu": "SWAP",
        "zhanghu": "Ekuitas",
        "yugu": "Harga likuidasi EST",
        "baozheng": "Tingkat Margin",
        "kaicang": "Buka",
        "baocang": "Likuidasi",
        "pincang": "Tutup",
        "zhanghu2": "Akun",
        "pingduo": "Tutup Panjang",
        "kaiduo": "Buka Panjang",
        "pingkong": "Tutup Pendek",
        "kaigong": "Buka Pendek",
        "cufajia": "Harga pemicu",
        "zuiyou": "Harga Terbaik",
        "zhang": "Cont",
        "keyong": "Tersedia",
        "kekaiduo": "Maks. L",
        "kekaikong": "Maks. S",
        "duocang": "Maks (L)",
        "kongcang": "Maks (S)",
        "kanzhang": "Panjang",
        "kandie": "Pendek",
        "shuliang": "Jumlah",
        "chicang": "Posisi",
        "dangqianweituo": "Buka Pesanan",
        "lishiweituo": "Riwayat",
        "shouyi": "P/L",
        "shouyilv": "Rasio P/L",
        "gangganbeishu": "Leverage",
        "chicangliang": "Posisi",
        "kepingliang": "Posisi Tersedia",
        "kaichangjun": "Harga Buka",
        "zuixinjia": "Harga Terbaru",
        "dangqianbaozheng": "Margin Kur",
        "duotou": "Panjang",
        "kongtou": "Pendek",
        "baozhengjin": "Margin",
        "weituoshuliang": "Jumlah Pesanan",
        "jiesuanweik": "Penyelesaian P/L",
        "chengjiaojia": "Harga Akhir",
        "shouxufei": "Biaya",
        "xiugaichenggong": "Berhasil diperbaiki"
    },
    "views": {
        "wangjiPass": "Lupa Kata Sandi?",
        "confirmLogOut": "Apakah anda yakin untuk keluar?",
        "personalCenter": "Pusat Pribadi",
        "shezhi": "Pengaturan Berhasil",
        "yuanyouxiang": "Kode Verifikasi Email Lama",
        "newyouxiang": "Kode Verifikasi Email Baru",
        "fsyouxiang": "Kirim Kode",
        "xyouxiang": "Email Baru",
        "loginPass": "Kata Sandi Masuk",
        "baocun": "Simpan",
        "fschenggong": "Berhasil dikirim",
        "inputyouxiang": "Masukkan alamat email baru anda",
        "inputOld": "Masukkan kode verifikasi email lama",
        "inputNew": "Masukkan kode verifikasi email baru",
        "inputPass": "Masukkan Kata Sandi masuk anda",
        "OldPass": "Kata Sandi masuk lama",
        "inputOldPass": "Masukkan Kata Sandi masuk lama anda",
        "NewPass": "Kata Sandi masuk baru",
        "inputNewPass": "Masukkan Kata Sandi masuk baru anda",
        "queren": "Konfirmasi Kata Sandi",
        "email": "Kode Verifikasi",
        "liangciPass": "Kata Sandi yang dimasukkan tidak sama",
        "shuruCode": "Masukkan kode verifikasi",
        "OldZJ": "Kata Sandi dana lama",
        "inputOldZJ": "Masukkan Kata Sandi  dana lama anda",
        "NewZJ": "Kata Sandi Dana Baru",
        "inputNewZJ": "Masukkan Kata Sandi dana baru",
        "augeren": "Informasi Pribadi",
        "aushang": "Unggah KTP",
        "aushenghe": "Ditinjau",
        "name": "Nama Lengkap",
        "inputName": "Masukkan nama lengkap anda",
        "huzhao": "Nomor KTP",
        "inputhuzhao": "Silahkan masukkan nomor KTP anda",
        "next": "Selanjutnya",
        "uphuzhao": "Unggah foto KTP anda",
        "shouhuzhao": "Unggah foto anda memegang KTP",
        "tijiao": "Kirim",
        "tip1": "1. Pastikan KTP anda tidak kadaluwarsa",
        "tip2": "2. Isi tanda tangan termasuk tulisan tangan {hrefArry}, tanda tangan pribadi dan tanggal saat ini",
        "tip3": "3. Pastikan foto dan tanda tangan pribadi terlihat jelas",
        "shentijiao": "Informasi telah dikirim, muhon tunggu tinjauan",
        "fanhui": "Kembali",
        "bangding": "Ikat",
        "inputEmail": "Masukkan alamat email anda",
        "inputPhone": "Masukkan nomor ponsel anda",
        "kefu": "Silahkan hubungi customer service"
    },
    "list": {
        "wufaxiugia": "Anda tidak dapat merubah saat anda memiliki posisi atau pesanan yang sedang dalam proses",
        "submittip1": "Demi keamanan dana anda, harap lakukan otentikasi nama asli terlebih dahulu",
        "submittip2": "Demi keamanan dana anda, harap buat Kata Sandi dana terlebih dahulu",
        "shuruweituo": "Silahkan masukkan harga pesanan",
        "shuruweituoliang": "Silahkan masukkan jumlah pesanan",
        "dayukekai": "Jumlah tidak boleh lebih besar dari Maks. L",
        "dayuduocang": "Jumlah tidak boleh lebih besar dari Maks (S)",
        "dayukekaikong": "Jumlah tidak boleh lebih dari Maks. S",
        "dayukongcang": "Jumlah tidak boleh lebih dari Maks (L)",
        "shuruchufa": "Silahkan masukkan harga pemicu",
        "zhangfubang": "Peringkat",
        "liang": "Vol",
        "safar": "Klik tombol dibawah di Safari",
        "kuaisuanzhuang": "pilih [Tambah ke Layar Utama]",
        "noAccount": "Belum punya akun?",
        "LoginReg": "Daftar",
        "butigongfuwu": "Harap dipahami bahwa IP atau layanan lokal anda tidak tersedia saat ini",
        "renzheng1": "Diverifikasi",
        "renzheng2": "Verifikasi Gagal",
        "renzheng3": "Sedang diverifikasi",
        "renzheng4": "Verifikasi",
        "shenheing": "Sedang diverifikasi, harap sabar menunggu",
        "inputzaicimima": "Silahkan masukkan Kata Sandi kembali",
        "xuantian": "Silahkan Masukkan kode undangan (Opsional)",
        "yuedu": "Saya telah membaca dan menyetujui ",
        "yonghuxieyi": "Perjanjian layanan pengguna",
        "hasAccount": "Sudah punya akun?",
        "denglu": "Masuk",
        "gouxuanxieyi": "Silahkan baca dan periksa Perjanjian Layanan Pengguna",
        "inputzijinmima": "Masukkan kata sandi dana anda",
        "yijianfankui": "Masukan",
        "gao": "Tinggi",
        "di": "Rendah",
        "shengdu": "Catatan Pesanan",
        "chengjiao": "Berhasil",
        "zixuan1": "Hapus dari favorit",
        "zixuan2": "Gagal menghapus",
        "zixuan3": "Berhasil ditambah",
        "zixuan4": "gagal ditambah",
        "jiaoyie": "Jumlah total",
        "chengjiaozonger": "Jumlah Total",
        "chengjiaojun": "Harga Average",
        "chengjiaoliang": "Volume",
        "bigmairu": "Melebihi jumlah maksimal yang tersedia",
        "weituochenggong": "Berhasil diperdagangkan",
        "weituoshibai": "Gagal diperdagangkan",
        "yue": "Saldo",
        "bizhong": "Mata uang",
        "huazhuanshuliang": "Jumlah Transfer",
        "inputhzsl": "Silahkan masukkan jumlah transfer",
        "bunengweifu": "Jumlah transfer harus lebih besar dari 0",
        "dayuhuazhuan": "Jumlah transfer tidak boleh lebih besar dari jumlah yang tersedia di akun",
        "huazhuanchenggong": "Transfer berhasil",
        "huazhuangshibai": "Transfer gagal"
    },
    "user": {
        "guizeshuoming": "Aturan",
        "shenheweitongguo": "Gagal memverifikasi",
        "shiming": "Harap selesaikan otentikasi nama asli",
        "fuzhichenggong": "Berhasil Disalin",
        "frozen": "Dalam Pesanan",
        "zhanghuquanyi": "Ekuitas",
        "baozhenjin": "Margin Tersedia",
        "jiaoyishijian": "Waktu Transaksi"
    },
    "tradePage": {
        "CUSTOM": "Favorit"
    },
    "chart": {
        "minute": "Menit"
    },
    "charge": {
        "congbi": "Alamat Deposit",
        "baocun": "Simpan Kode QR"
    },
    "heyuePage": {
        "yijianpingcang": "Flash Tutup",
        "copyerr": "Gagal menyalin",
        "pincang": "Lanjutan posisi close tidak boleh 0"
    },
    "yaoqin": {
        "zuce": "Tanggal Pendaftaran",
        "showPersonInfo": "Lihat profil anda",
        "myinvitelink": "Tautan undangan",
        "my_column4": "Kelas",
        "yaoqing": "Undangan",
        "dengji": "Level Kemajuan",
        "mubiao": " Target",
        "zong": "Total rabat",
        "jiaoyi": "Transaksi",
        "wode": "Kode Undangan",
        "huodong": "Bonus Aktivitas"
    },
    "version3": {
        "hash": "Value Hash",
        "betTip": "Tebak bit terakhir dari nilai hash dari blok ETH pertama setiap 10 menit , 0-7 Kecil, 8-f Besar, 0, 2, 4, 6, 8, a, c dan e Genap, 1, 3 , 5, 7, 9, b, d dan f Ganjil.",
        "betTip2": "Tebak bit terakhir dari nilai hash, 0-7 Kecil, 8-f Besar, 0, 2, 4 ,6, 8, a, c dan e Genap, 1, 3, 5, 7, 9, b , d dan f Ganjil.",
        "betMoneyTip": "Jumlah per taruhan",
        "doBet": "Taruhan Sekarang",
        "issue": "Tidak.",
        "blockHigh": "Tinggi Blok",
        "blockTime": "Waktu Blok",
        "hash2": "Value Hash",
        "gameRecord": "Catatan Permainan",
        "betTime": "Waktu Taruhan",
        "pickUpAddress": "Alamat Withdraw",
        "arriveAccountNum": "Jumlah yang Diterima",
        "remark": "Catatan",
        "contachService": "Hubungi layanan pelanggan",
        "totalBetMoney": "Jumlah Taruhan",
        "unOpen": "Tidak ada hasil",
        "maintain": "Mempertahankan",
        "hasBingGo": "Menang",
        "unBingGo": "Kalah",
        "outOfBalance": "Saldo tidak cukup",
        "chooseBetNumTip": "Silakan pilih opsi taruhan",
        "chooseBetMoney": "Silakan masukkan jumlah per taruhan",
        "minBetAmount": "Jumlah minimal per taruhan adalah ",
        "maxBetAmount": "Jumlah maksimal per taruhan adalah ",
        "chooseWithDrawAddressTip": "Silakan pilih alamat withdraw",
        "inputRemarkTip": "Silakan masukkan alamat penarikan Anda",
        "confirmOrder": "Konfirmasi",
        "manji": "Level VIP maksimal tercapai",
        "selWithAddress": "Silakan pilih alamat penarikan",
        "withNumTip": "Jumlah penarikan harus",
        "unLiuShui": "Aliran yang belum selesai",
        "withdrawChannel": "Saluran Penarikan"
    },
    "version4": {
        "game": "Permainan",
        "baocangIntro": "Pada {date}, total ekuitas akun swap {account} anda adalah 0 USDT, dan tingkat margin adalah 0, yang memicu likuidasi paksa, yaitu likuidasi.",
        "geshi": "Masukkan jumlah yang benar",
        "mingc": "Volume",
        "zhangd": "Mengubah",
        "quanqiu": "Global",
        "yiliu": "A-team",
        "yinhang": "Security"
    },
    "version5": {
        "rechargeWay": "Saluran Deposit",
        "inputChargeNum": "Silakan masukkan jumlah deposit",
        "receivePeople": "Penerima",
        "bankCard": "Kartu bank",
        "bank": "Bank",
        "bankNumber": "Rekening bank",
        "bankProvince": "Lokasi Provinsi Rekening",
        "bankCity": "Lokasi Kota Rekening",
        "bankBrancher": "Bank cabang",
        "rengong": "Setoran & Penarikan Manual"
    },
    "version7": {
        "p6": "Harap daftarkan kartu bank Anda terlebih dahulu",
        "jinxingzhong": "Trading",
        "yiwancheng": "Selesai",
        "shibai": "Gagal",
        "nichen": "Username",
        "p8": "Kuota Transaksi Tunggal",
        "username": "Silakan masukkan username"
    },
    "version8": {
        "noArrive0": "Jumlah yang diterima tidak boleh 0",
        "xuantian": "Silakan masukkan kode undangan",
        "result": "Hasil",
        "color": "Warna",
        "hlRule": "Tebak angka antara 0-9. 1, 3, 5, 7, 9 adalah Hijau, 0, 2, 4, 6, 8 Merah, 0 dan 5 juga Ungu."
    },
    "Transfer": {
        "FriendsReport": "Laporan Tim",
        "jintian": "Hari ini",
        "zuotian": "Kemarin",
    'qiantian' : 'Kemarin lusa',

        "benzhou": "Minggu ini",
        "shangzhou": "Minggu lalu",
        "benyue": "Bulan ini",
        "shangyue": "Bulan lalu",
        "zuce": "Pengguna Pendaftaran",
        "shoucong": "Pengguna setoran pertama",
        "chongzhi": "Pengguna Deposit",
        "tixian": "Penarikan Pengguna",
        "heyue": "Tukar Pengguna",
        "youxi": "Pengguna Game",
        "touzu": "Taruhan Game",
        "zhongjiang": "Kemenangan Permainan",
        "shimingrenshu": "Pengguna Verifikasi",
        "status_1": "Meninjau",
        "status_3": "Gagal",
        "status_4": "Kesuksesan",
        "jilu": "Catatan"
    },
    "version9": {
        "SD1MRule": "Menebak banyaknya disk berwarna (merah putih). Disk dengan warna yang sama mempunyai bilangan genap adalah Genap, dan piringan dengan warna yang sama mempunyai bilangan ganjil adalah Ganjil.",
        "FourRed": "4-Merah/0-Putih",
        "ThreeRedOneWhite": "3-Merah/1-Putih",
        "FourWhite": "0-Merah/4-Putih",
        "OneRedThreeWhite": "1-Merah/3-Putih"
    },
    "version10": {
        "titMode3": "Saat menarik, Anda perlu membayar biaya mining untuk memastikan bahwa transaksi on-chain diproses. Biaya penambangan saat ini adalah {minTxFee} USDT.",
        "onGame": "Dalam proses",
        "tuihui": "Dikembalikan",
        "hasJump": "Telah terjun",
        "player": "Pemain",
        "money": "Jumlah",
        "jump": "Kas keluar",
        "jumpMulti": "Uang Tunai Berganda",
        "betMoney": "Jumlah Taruhan",
        "autoJumpTip": "Auto Cashout Multiple (Opsional)",
        "p1": "Kelipatan minimum harus lebih besar dari ",
        "p2": "kelipatan maksimum adalah ",
        "autoJump": "Pembayaran Otomatis",
        "boomValue": "Beberapa Hancur",
        "crash": "Hancur",
        "noRepeatOrder": "Jangan bertaruh berulang kali",
        "jumpSuccess": "Diuangkan",
        "tip1_plan": "1. Kelipatan auto cashout bersifat opsional. Setelah mengisi, Anda akan secara otomatis mencairkan setelah pesawat mencapai kelipatan yang sesuai, atau Anda dapat mencairkan secara manual sebelum pesawat jatuh; jika Anda belum mengisi, Anda masih dapat menguangkan secara manual sebelum pesawat jatuh.",
        "tip2_plan": "2. Kami akan mengambil {amount}% dari keuntungan pemain cashout.",
        "dizhi": "Silakan masukkan alamat yang benar"
    },
    "version11": {
        "trend": "Tren",
        "goldenBroad": "Pemenang",
        "broadTxt": "Selamat, {userName} memenangkan <span class='Tcolor'>{winMoney}</span> USDT",
        "TS": "Crash",
        "CQK1M": "Blok 1 Menit",
        "CQK5M": "Blok 5 Menit",
        "CQK": "Blok 10 Menit (Berantai)",
        "HL": "Merah-Hijau 1 Menit",
        "HL5M": "Merah-Hijau 5 Menit",
		'RG30S' : 'Merah-Hijau 30 Detik',
        "SD1M": "Disk 1 Menit",
        "SD5M": "Disk 5 Menit",
        "Gogame": "Pergi bermain",
        "yinli": "Laba",
        "munber": "Laba = Laba Perdagangan + Bonus Aktivitas + Rabat",
        "Gyinkui": "Keuntungan Permainan",
        "Hyinkui": "Tukar Untung",
        "Hlijin": "Bonus Aktivitas",
        "Yfanyong": "Potongan harga"
    },
    "version12": {
        "hl10mRule": "Tebak 0-9 pertama menghitung dari bit terakhir ke depan nilai hash dari blok ETH pertama setiap 10 menit, 1, 3, 5, 7, 9 adalah Hijau, 0, 2, 4, 6, 8 adalah Merah , 0 dan 5 juga Ungu.",
        "sd10mRule": "Tebak empat bit terakhir dari nilai hash dari blok ETH pertama setiap 10 menit, setiap kode sesuai dengan disk, 0-7 berwarna putih, 8-f berwarna merah. Cakram dengan warna yang sama memiliki angka genap adalah Genap, dan cakram dengan warna yang sama memiliki nomor ganjil adalah Ganjil.",
        "SD10M": "Disk 10 Menit (Berantai)",
        "HL10M": "Merah-Hijau 10 Menit (Berantai)",
        "refresh": "Menyegarkan"
    },
    "version13": {
        "kaijiangzhong": "Menggambar",
        "canyu": "Milikku",
        "kaijiang": "Selesai",
        "join": "Bergabung",
        "buyAmount": "Jumlah salinan untuk dibeli",
        "minBetAmount": "Jumlah minimum pembelian eksemplar adalah: ",
        "maxBetAmount": "Jumlah maksimum pembelian eksemplar adalah: ",
        "tipMax": "Mohon diperhatikan bahwa jangan membeli lebih dari jumlah salinan maksimum",
        "totalVal": "Nilai total",
        "jindu": "Kemajuan",
        "leave": "Tetap",
        "goldNumber": "Kode Kemenangan",
        "myGoldNumber": "Kode saya",
        "val": "Nilai",
        "mode": "Sisa",
        "unJoin": "Tidak Berpartisipasi",
        "calNumber": "Menghitung",
        "eachPrice": "Harga per lembar",
        "currentIssue": "Rekor di babak ini",
        "hisIssue": "50 rekor sebelum undian",
        "showDetail": "Melihat",
        "buy": "Membeli",
        "buyTime": "Membeli Waktu",
        "luckNumber": "Nomor Hadiah",
        "totalBuyAmount": "Jumlah Pembelian Total",
        "buyTip2": "Harap masukkan format per salinan yang benar",
        "goldMember": "Anggota Penghargaan",
        "openTime": "Waktu Menggambar",
        "Wtime": "Perkiraan waktu perawatan",
        "invitationNote": "Petunjuk Undangan",
        "p1": "<ins class='paramTitle'>Undang Teman</ins>Masuk ke pusat undangan, salin tautan undangan atau kode undangan, dan bagikan dengan teman Anda. Teman dapat menjadi anggota bawahan Anda dengan mendaftar dengan kode undangan Anda.",
        "p2": "<ins class='paramTitle'>Dapatkan Rabat</ins>Anda bisa mendapatkan rabat yang sesuai saat orang yang diundang berdagang, yaitu hingga tiga tingkat orang yang diundang. Misalnya, jika Anda mengundang teman A, A mengundang B, dan B mengundang C, maka A, B, dan C dapat melakukan trading game, swap, dan transaksi lainnya di platform. Anda bisa mendapatkan potongan harga yang sesuai dalam tindakan ini juga.",
        "fuk": "Mohon bayar",
        "guoq": "Kadaluwarsa",
        "exchtishi": "Harap gunakan kartu bank milik qnda untuk mengirim dana ke rekening di atas.",
        "exchtishi2": "Harap jangan perhatikan BTC, USDT, dan informasi lainnya dalam proses pengiriman uang untuk mencegah intersepsi pengiriman uang atau masalah lainnya.",
        "quxiaoOrder": "Batalkan Pesanan",
        "chengong": "Saya telah berhasil membayar",
        "fukuanren": "Nama Pembayar",
        "fukuanrenTi": "Silahkan masukkan nama pembayar",
        "zhanghao": "Akun Pembayaran",
        "zhanghaoTi": "Silakan masukkan akun pembayaran Anda",
        "yihang": "Bank Pembayar",
        "yihangTi": "Silakan masukkan bank pembayaran Anda",
        "jietu": "Screenshot  Pembayaran",
        "jietuTi": "Silakan unggah screenshot pembayaran Anda",
        "queren": "Mohon konfirmasi bahwa anda telah membayar",
        "queren2": "Dalam kasus konfirmasi berbahaya, akun akan dibekukan",
        "YYYG": "Lucky 1U",
        "dragon": "Peringkat",
        "paimng": "Peringkat"
    },
    "version15": {
        "only60Days": "Hanya simpan data dalam waktu 60 hari",
        "only100Records": "Tampilkan hanya 100 catatan terakhir",
        "status": "Status",
        "luckyWheel": "Undian",
        "option1": "Bonus",
        "option2": "Thanks",
        "option3": "Once More",
        "freeTimes": "Jumlah Undian",
        "p1": "Anda akan mendapatkan {registerGiveCount} peluang untuk mengundi setelah pendaftaran berhasil.",
        "p2": "Jika jumlah deposit mencapai {todayRechargeCondition} USDT kemarin (berdasarkan zona waktu UTC+0), maka Anda bisa mendapatkan {rechargeGiveCount} peluang penarikan.",
        "p3": "Kemarin (dalam zona waktu UTC+0) jumlah taruhan yang valid (tidak termasuk spot dan kontrak) mencapai {todayBettingCondition} USDT, maka Anda bisa mendapatkan {bettingGiveCount} peluang undian.",
        "continuDraw": "Lanjutkan Mengundi",
        "noFreeTimeTip": "Anda sudah kehabisan semua peluang mengundi Anda"
    },
    "version16": {
        "totalPerMoney": "Jumlah pesanan",
        "notFu": "Jumlah taruhan tidak boleh 0",
        "find": "Menemukan",
        "channel": "Saluran"
    },
    "version17": {
        "drawWarmTip": "Waktu undian berhadiah mungkin membutuhkan waktu sepuluh menit hingga setengah jam untuk mengonfirmasi. Harap menunggu dengan sabar.",
        "level": "Tingkat",
        "upLimit": "Batasan Peningkatan",
        "chatRoom": "Obrolan",
        "group": "Obrolan Grup",
        "privateChat": "Obrolan Pribadi",
        "groupManager": "Manajer Grup",
        "groupMember": "Anggota Grup",
        "groupSetting": "Pengaturan Grup",
        "totalChargeMoney": "Jumlah Deposit Kumulatif",
        "vip9Tip": "Level VIP maksimum tercapai",
        "dataTime": "Waktu Data",
        "sortType": "Urutkan Berdasarkan",
        "loginTime": "Waktu Masuk",
        "lowerPeople": "Undangan",
        "chargeNum": "Jumlah Deposit",
        "withdrawNum": "Jumlah penarikan",
        "lowerReport": "Laporan Tim",
        "lowerUserBetNum": "Taruhan Undangan",
        "lowerUserAwardNum": "Kemenangan Undangan",
        "lowerUserChargeNum": "Setoran Undangan",
        "lowerUserWithdrawNum": "Penarikan Undangan",
        "firstRechargeNum": "Jumlah setoran pertama",
        "warmTip": "Tampilkan data hari ini secara otomatis",
        "publishNotice": "Menerbitkan",
        "editNotice": "Edit Pemberitahuan",
        "noticeTime": "Pemberitahuan Waktu",
        "inputNoticeTip": "Silakan masukkan konten pemberitahuan",
        "tip": "Setelah 'Semua Terlarang' diaktifkan, hanya manajer grup yang diizinkan untuk berbicara",
        "allNotSpeak": "Semua Dilarang",
        "canSpeakLevel": "Tingkat Bicara",
        "eryuanqiquan": "Opsi Biner",
        "optionHelpText": "Prediksi naik dan turun dari periode waktu berikutnya. Harga di awal setiap periode adalah harga awal, dan harga di akhir setiap periode adalah harga penyelesaian. Jika harga penyelesaian lebih besar dari harga awal, itu naik (long), dan jika harga penyelesaian lebih kecil dari harga awal, itu turun (short).",
        "optionRecord": "Catatan Opsi",
        "calcuteDirection": "Arah Prediksi",
        "startPrice": "Harga Awal",
        "endPrice": "Harga Penyelesaian",
        "equal": "Setara",
        "waitResult": "Harus diselesaikan",
        "up": "Long",
        "down": "Short",
        "findPassword": "Ambil Kata Sandi",
        "calTime": "Waktu Prediksi",
        "calWeek": "Periode Prediksi",
        "kanzhang": "Long",
        "kandie": "Short"
    },
    "version18": {
        "issue": "Tidak.",
        "vipSort": "VIP",
        "jinyan": "Telah Dibisukan",
        "weijinyan": "Disuarakan",
        "confirmDelete": "Anda yakin ingin menghapus anggota?",
        "confirmUnSpeak": "Anda yakin ingin membisukan anggota?",
        "confirmSpeak": "Anda yakin ingin membunyikan suara anggota?",
        "confirmAdd": "Apakah Anda yakin ingin menambahkan anggota?",
        "addMember": "Tambahkan anggota baru",
        "delMember": "Hapus anggota",
        "lockSpeak": "Dibisukan",
        "unLockSpeak": "Disuarakan",
        "lockSpeakSelMember": "Membisukan Anggota",
        "unLockSpeakSelMember": "Suarakan Anggota",
        "noSelMemberTip": "Silakan pilih anggota",
        "recentlyPlayGame": "Sering Dimainkan",
        "sendMsg": "Mengirim pesan",
        "addGroupMember": "Tambahkan Anggota Baru",
        "picTip": "[Gambar]",
        "time3": "1 minggu yang lalu",
        "chooseAll": "Pilih Balik",
        "personalPage": "Beranda Pribadi",
        "CQK3M": "Blok 3 Menit",
        "HL3M": "Merah-Hijau 3 Menit",
        "SD3M": "Disk 3 Menit"
    },
    "version19": {
        "avatar": "Avatar",
        "share": "Bagikan",
        "todayProfit": "Keuntungan Hari Ini",
        "followBet": "Menindaklanjuti",
        "Jump": "PERGI",
        "over": "Selesai",
        "optionNumber": "Pengguna Opsi",
        "optionBet": "Taruhan Opsi",
        "optionReward": "Opsi Menang",
        "optionProfit": "Keuntungan Opsi",
        "RG_2_0": "Merah",
        "RG_5_1": "Ungu",
        "RG_2_1": "Hijau",
        "DS_16_0": "1111",
        "DS_16_1": "0000",
        "DS_4_0": "1110",
        "DS_4_1": "1000",
        "DS_2_0": "Genap",
        "DS_2_1": "Ganjil",
        "BLK_2_0": "Besar",
        "BLK_2_1": "Kecil",
        "BLK_2_2": "Ganjil",
        "BLK_2_3": "Genap",
        "BLK_4_0": "Besar-Ganjil",
        "BLK_4_1": "Besar-Genap",
        "BLK_4_2": "Kecil-Ganjil",
        "BLK_4_3": "Kecil-Genap"
    },
    "version20": {
        "server": "Layanan Pelanggan",
        "teacher": "Pengawas",
        "financer": "Keuangan",
        "planer": "Perencana",
        "chatPop": "Jendela Obrolan",
        "cancelText": "Batalkan jendela obrolan",
        "betNumber": "Opsi taruhan",
        "resendMsg": "Pengiriman pesan gagal. Apakah Anda ingin mengirim ulang pesan?",
        "delThisMsg": "Hapus pesan",
        "delThisMemberAllMsg": "Hapus semua pesan dari anggota",
        "forbitThisMember": "Membisukan anggota",
        "delThisMember": "Hapus anggota",
        "delTipModal": "Silakan pilih fungsi yang akan dioperasikan"
    },
    "version21": {
        "USDT_account": "Akun USDT",
        "closeSwapTip": "Silakan pilih rasio penutupan",
        "hasRecharge": "diisi ulang",
        "numberCoin": "Mata Uang Digital",
        "none": "-",
        "fastCharge": "Isi Ulang Cepat",
        "bankTransfer": "Transfer Bank",
        "setLegalTip": "Silakan pilih mata uang fiat Anda",
        "legalCoin": "Mata Uang Fiat",
        "orderCreateTime": "Waktu pembuatan",
        "tradeOrderNumber": "No. Transaksi",
        "completeTime": "Waktu penyelesaian",
        "fastWithdraw": "Penarikan Cepat",
        "bindCardTip1": "Silakan isi nama lengkap sesuai KTP anda yang masih berlaku, karena tidak dapat diubah setelah dikirim",
        "bindCardTip2": "Silakan isi informasi kartu bank Anda"
    },
    "saas": {
        "fastChargeType": "Isi Ulang Saluran Cepat",
        "numberCoinChargeType": "Isi Ulang Mata Uang Digital",
        "humanChargeType": "Isi Ulang Manual",
        "fastChannelWithdrawType": "Penarikan Saluran Cepat",
        "numberCoinWithdrawType": "Penarikan Mata Uang Digital",
        "humanWithdrawType": "Penarikan Manual",
        "fastChannelBackType": "Pengembalian Saluran Cepat",
        "numberChannelBackType": "Pengembalian Mata Uang Digital",
        "betType": "Bertaruh",
        "betProfitType": "Laba",
        "betEqualType": "Setara",
        "gameBackRewardType": "Rabat Permainan",
        "swapBackRewardType": "Tukar Rabat",
        "exchangeBackRewardType": "Rabat Spot",
        "tranInType": "Transfer Masuk",
        "tranOutType": "Transfer Keluar",
        "chargeRewardType": "Bonus Isi Ulang",
        "firstChargeRewardType": "Bonus setoran pertama",
        "regRewardType": "Bonus Pendaftaran",
        "upGradeRewardType": "Bonus Promosi",
        "luckyRewardType": "Undian",
        "realNameRewardType": "Bonus Nama Asli",
        "betRewardType": "Bonus Taruhan",
        "dailyFirstChargeRewardType": "Bonus Setoran Pertama Harian",
        "upScoreType": "Peningkatan Manual",
        "downScoreType": "Penurunan Manual",
        "unRecharge": "Tidak Diisi Ulang",
        "canWithdrawBalance": "Saldo yang Dapat Ditarik",
        "withPopTip1": "Penarikan tidak diperbolehkan jika ada aliran yang belum selesai.",
        "withPopTip2": "Bagian dari aliran yang belum selesai perlu membayar {flowFee}% dari biaya aliran saat menarik.",
        "alterBankCardNameTip": "Silahkan hubungi layanan pelanggan jika Anda perlu mengubah nama Anda",
        "arriveMoney": "Jumlah yang Diterima",
        "withdrawNum": "Jumlah penarikan",
        "failReason": "Alasan Kegagalan",
        "tradeHash": "TXID",
        "waitCoin": "Menunggu Pinjaman",
        "zicang": "Aset",
        "otherMoney": "Estimasi Aset Lainnya",
        "moneyList": "Daftar Aset",
        "onlyShowSwap": "Tukar Saja",
        "hideZeroMoney": "Sembunyikan Saldo Kecil",
        "chargeFlowTip1": "Aliran yang belum selesai akan ditingkatkan sebesar {deposit}% dari jumlah isi ulang akun USDT. Misalnya, jika 1000 USDT diisi ulang, maka akan menambah {depositValue} USDT dari aliran yang belum selesai.",
        "chargeFlowTip2": "Aliran yang belum selesai dapat dikurangkan melalui taruhan efektif dari permainan / opsi biner. Misalnya, jika aliran yang belum selesai adalah 200USDT, dan taruhan yang valid sebesar 10USDT dihasilkan dalam permainan, maka ada 190USDT yang tersisa di aliran yang belum selesai.",
        "chargeRecord": "Catatan Setoran",
        "withdrawRecord": "Catatan Penarikan"
    },
    "version22": {
        "verificationCode": "CAPTCHA",
        "userNameErr": "Format nama pengguna salah. Harap masukkan 6-32 huruf atau kombinasi alfanumerik yang dapat berisi. dan @",
        "tip": "Tip",
        "because": "Alasan",
        "no": "Nomor",
        "auditError": "Audit Gagal",
        "auditSuccess": "Audit Berhasil",
        "fundPassword": "Format kata sandi dana salah. Silakan masukkan 6 digit",
        "allEntrust": "Riwayat Pemesanan",
        "dealAmount": "Jumlah Transaksi",
        "watcherTeamForm": "Lihat Laporan Tim",
        "overview": "Ringkasan",
        "stopBet": "Order telah ditutup pada periode ini. Harap berhenti bertaruh",
        "balance": "Saldo Tersedia",
        "exchangeOrderMinVolume": "Jumlah minimum yang dipercayakan adalah ",
        "exchangeOrderMaxVolume": "Jumlah maksimal yang dipercayakan adalah ",
        "BLK5MC": "Blok 5 Menit (Berantai)",
        "RG5MC": "Merah-Hijau 5 Menit (Berantai)",
        "DS5MC": "Disk 5 Menit (Berantai)",
        "forbidTrade": "Modul transaksi telah melarang transaksi",
        "minTurnoverNum": "Omset minimal adalah ",
        "minSwapOpenNum": "Jumlah minimum kontrak adalah x",
        "maxSwapOpenNum": "Jumlah maksimum kontrak adalah x saat ini",
        "activity": "Aktivitas",
        "profitRecord": "Kemenangan",
        "service": "Layanan",
        "help": "Bantuan",
        "totalAssets": "Nilai total",
        "readAllLetterTip": "Anda yakin ingin menandai semua pesan sebagai telah dibaca?",
        "myTeam": "Kelompok ku",
        "lv1": "Kelas 1",
        "lv2": "Kelas 2",
        "lv3": "Kelas 3",
        "lvAll": "Kelas 1/2/3",
        "calDataTime": "Waktu Statistik",
        "dataUpdateTip": "Waktu pembaruan data: {date}",
        "total": "Jumlah Salinan",
        "lastTopUserName": "Nama Pengguna Unggul",
        "humanAdd": "Peningkatan Manual",
        "humanSub": "Penurunan Manual",
        "realNameTime": "Waktu Otentikasi",
        "chargeTime": "Waktu Deposit",
        "reSubmit": "Kirim ulang",
        "goldMoney": "Jumlah Kemenangan",
        "username": "Pengguna",
        "myNum": "Kode saya",
        "waitOpen": "Menunggu undian",
        "buyAmount": "Jumlah salinan untuk dibeli",
        "bugNum": "Beli salinan yang sesuai",
        "gainCode": "Dapatkan kode undian",
        "totalNumOpenReward": "Undian akan dibuka setelah jumlah total terjual habis",
        "winnerGainAllReward": "Pemenang akan mendapatkan semua hadiahnya",
        "teamLevel": "Tingkat Tim",
        "SD_serialGame": "Disk",
        "RG_serialGame": "Merah-Hijau",
        "BLK_serialGame": "Blok",
        "codeTip": "Kode QR Alamat Deposit",
        "relateTopUser": "Atasan",
        "lv1Num": "Kelas 1 - Undangan",
        "lv2Num": "Kelas 2 - Undangan",
        "lv3Num": "Kelas 3 - Undangan",
        "singleLevelTxt": " Kelas",
        "wrondPasswordCorrect": "Format kata sandi login salah. Silakan masukkan kombinasi 8-32 huruf dan angka",
        "withdrawAccount": "Penarikan Akun",
        "optionModeShutTip": "Mode {minute} menit sedang dalam pemeliharaan saat ini",
        "optionModuleShutTip": "Dalam Perawatan",
        "gameClosed": "Permainan ditutup",
        "doBet": "BERTARUH",
        "reAlterBindCard": "Mata uang fiat Anda tidak sesuai dengan mata uang kartu bank Anda. Harap perbarui informasi kartu bank tepat waktu",
        "t2": "Dari jumlah nilai waktu（UTC+0）dari 50 catatan pembelian terakhir sebelum pengundian",
        "emailErr": "Silakan masukkan alamat email yang benar",
        "bigSymTip": "mewakili <Besar>",
        "smallSymTip": "mewakili <Kecil>",
        "EvenSymTip": "mewakili <Genap>",
        "OddSymTip": "mewakili <Ganjil>",
        "exchangeNum": "Pengguna Spot",
        "yesterdayAward": "Kemenangan Kemarin",
        "yijixiaji": "Kelas 1 - Undangan",
        "erjixiaji": "Kelas 2 - Undangan",
        "sanjixiaji": "Kelas 3 - Undangan",
        "p3": "<ins class='paramTitle'>Level Tim</ins>Semakin banyak kelas 1 - undangan yang dipromosikan, akan semakin tinggi level tim, dan rabat yang bisa Anda nikmati. Level tim dibagi menjadi LV1-LV6. Lihat tabel berikut untuk aturan upgrade, di mana N adalah jumlah kelas 1 - undangan yang telah ditagih.",
        "p4": "<ins class='paramTitle'>Rasio Rabat Game, Opsi, Kontrak Seconds</ins>Saat orang yang diundang bertaruh pada permainan dan opsi biner, Anda bisa mendapatkan rabat sesuai dengan jumlah taruhan mereka.",
        "p5": "<ins class='paramTitle'>Rasio Rebate Spot dan Swap</ins>Saat orang yang diundang melakukan transaksi spot dan swap, Anda bisa mendapatkan rebate sesuai dengan komisi transaksi mereka. Jika komisi dalam mata uang selain USDT, rebate akan dikonversi ke USDT sesuai dengan nilai tukar real-time dan dikeluarkan ke akun USDT Anda.",
        "flag": "Fitur",
        "noZero": "Jumlah transfer tidak boleh 0",
        "networkTip": "Kesalahan jaringan, harap segarkan halaman dan muat ulang.",
        "inviteChargeTip": "Peningkatan level tim tergantung pada jumlah kelas 1 - undangan yang berhasil mengisi ulang.",
        "canWithdrawNum": "Saldo Tersedia",
        "withTip": "Silakan masukkan jumlah penarikan",
        "withModalTip": "Biaya layanan termasuk biaya aliran dan biaya mining.",
        "totalUpNum": "Jumlah Orang",
        "chargeRangeTip": "Jumlah setoran harus ",
        "jiaocheng": "Petunjuk",
        "spare1": "Informasi Alternatif 1",
        "spare2": "Informasi Alternatif 2",
        "loadAllGroupMsg": "Semua pesan dalam 7 hari telah dimuat",
        "realMember": "Keanggotaan penuh",
        "testMember": "Anggota uji",
        "noBlankMsg": "Anda tidak dapat mengirim pesan kosong",
        "maxLength1500": "Pengiriman pesan gagal karena kontennya terlalu panjang",
        "sendToMore": "Pengiriman pesan gagal karena permintaan terlalu sering",
        "countdown": "Hitung mundur",
        "toLongRemark": "Terlalu banyak huruf, harap hapus beberapa kata",
        "tip1": "<p>* Data tunduk pada waktu statistik. Data diperbarui setiap jam. Waktu pembaruan terbaru adalah: {time}; Di antaranya, pengguna pendaftaran / pengguna verifikasi / pengguna setoran pertama / Jumlah setoran pertama diperbarui secara real time</p>",
        "tip2": "<p>* Saldo USDT tidak ada hubungannya dengan waktu statistik, dan selalu menampilkan saldo total tim saat ini</p>",
        "tip3": "* Jumlah deposit / penarikan / taruhan / kemenangan / rabat tunduk pada waktu statistik. Data diperbarui setiap jam. Waktu pembaruan terbaru adalah: {time}",
        "tip4": "* Data lain tidak bergantung pada waktu statistik, dan data terbaru selalu ditampilkan",
        "wrongImgType": "Format file tidak didukung",
        "addNewNotice": "Menambahkan",
        "noChoose": "Tidak terpilih",
        "nullCoin": "Tidak ada",
        "thankyou": "Terimakasih telah berpartisipasi",
        "oncemore": "Sekali lagi",
        "tip5": "Karena nilai tukar dapat berfluktuasi, silakan lihat jumlah sebenarnya",
        "xiugaichenggong": "Berhasil diperbaiki, silahkan login kembali",
        "entrustNoZero": "Kuantitas yang didelegasikan tidak boleh 0",
        "herTeam": "Timnya",
        "betMoney": "Masukkan jumlah taruhan",
        addFilterMember : 'Tambahkan semua anggota yang dipilih',
        delFilterMember : 'Hapus semua anggota yang dipilih',
        forbidFilterMember : 'Bisukan semua anggota yang dipilih',
        freeFilterMember : 'Buka suara semua anggota dipilih',
        theme1 : 'Tema Permainan 1',
        theme2 : 'Tema Permainan 2',
        theme3 : 'Tema EX 1',
        theme4 : 'Tema EX 2',
        themeTitle : 'Tema',
        maxTradeMoney: 'Batas Maksimal transaksi adalah '
    },
    "version23": {
        inviteCenter: "Pusat undangan",
        search: 'Mencari',
        active: 'Aksi',
        openDirect: 'Arah hasil',
        profitAndLossAmount: 'P/L',
        levelMax: "Level tertinggi tercapai",
        next: 'Halaman berikutnya',
        prev: 'Halaman sebelumnya',
        secondRecord : 'Catatan Kontrak Seconds',
        entrusting : 'Catatan Kontrak Seconds',
        secondReward : 'Kemenangan Kontrak Seconds',
        secondBet : 'Taruhan Kontrak Seconds',
        secondProfit : 'Keuntungan Kontrak Seconds',
        RD100N : 'Acak 100',
        secondNum : 'Pengguna Kontrak Seconds',
        rebackTip : 'Rabat akan dibayarkan setiap jam, mohon bersabar.',
        secondOption : 'Kontrak Seconds',
        qiquan : 'Opsi',
        exportBtn : 'Export',
    "SB1M": "Kapal Terbang 1 Menit",
    "SB3M": "Kapal Terbang 3 Menit",
    "SB5M": "Kapal Terbang 5 Menit",
    "SBTM": "Kapal Terbang 10 Menit",
  },
  version24 : {
    goldBoom : 'Menginjak ranjau',
    'MS' : 'Menyapu ranjau',
    spRecord : 'Catatan Olahraga-Ponymuah',
    "QT1M": "SICBO 1 Menit",
    "QT3M": "SICBO 3 Menit",
    "QT5M": "SICBO 5 Menit",
    "QTTM": "SICBO 10 Menit",
    "KN1M": "KENO 1 Menit",
    "KN3M": "KENO 3 Menit",
    "KN5M": "KENO 5 Menit",
    "KNTM": "KENO 10 Menit",
    "electronRecord": "Catatan Arkade-Evolution",
    "LOT1M": "LOTTO 1 Menit",
    "LOT3M": "LOTTO 3 Menit",
    "LOT5M": "LOTTO 5 Menit",
    "LOTTM": "LOTTO 10 Menit",
    'LOTW3' : 'LOTTO',

    'TWTL' : 'Tunai hari ini',
    'TWTL1M' : 'Tunai hari ini 1 Menit',
    'TWTL5M' : 'Tunai hari ini 5 Menit',
    'TWTLTM' : 'Tunai hari ini 10 Menit',
    'TWTL1D' : 'Tunai hari ini',

    "RLT1M": "Roulette 1 Menit",
    "RLT3M": "Roulette 3 Menit",
    "RLT5M": "Roulette 5 Menit",
    "RLTTM": "Roulette 10 Menit",
    "LK281M": "Lucky 28 1 Menit",
    "LK283M": "Lucky 28 3 Menit",
    "LK285M": "Lucky 28 5 Menit",
    "LK28TM": "Lucky 28 10 Menit",
    "liveRecord": "Catatan Live-Ezugi",
    evoLiveRecord : 'Catatan Live-Evolution',
    pbRecord : 'Catatan Olahraga-PinnacleBet',
      LIMBO : 'LIMBO',
      HILO : 'HILO',
	'FSLOT' : 'Uah',
    'RULTI' : 'Roda',
    'RULTI_2-075_0' : 'Hitam',
    'RULTI_3-176_0' : 'Merah',
    'RULTI_5-4_0' : 'Biru',
    'RULTI_54_0' : 'Hijau',
	'RB' : 'Merah-itam',
	'RB_2-142_0' : 'Merah',
	'RB_15_0' : 'Putih',
	'RB_2-142_1' : 'Hitam',

    'FST' : 'Loteri 4-Bintang',
    'FST1M' : 'Loteri 4-Bintang 1 Menit',
    'FST5M' : 'Loteri 4-Bintang 5 Menit',
    'FSTTM' : 'Loteri 4-Bintang 10 Menit',
    'FST1D' : 'Loteri 4-Bintang 1 Hari',

    'TS' : 'Loteri 3-Bintang',
    'TS1M' : 'Loteri 3-Bintang 1 Menit',
    'TS5M' : 'Loteri 3-Bintang 5 Menit',
    'TSTM' : 'Loteri 3-Bintang 10 Menit',
    'TS1D' : 'Loteri 3-Bintang 1 Hari',

    'TWLT1M' : 'Lotto Besa 1 Menit',
    'TWLT5M' : 'Lotto Besa 5 Menit',
    'TWLTTM' : 'Lotto Besa 10 Menit',
    'TWLTW2' : 'Lotto Besa',
    'TWLT' : 'Lotto Besa',


    'SPLT1M' : 'Lotto Supe 1 Menit',
    'SPLT5M' : 'Lotto Supe 5 Menit',
    'SPLTTM' : 'Lotto Supe 10 Menit',
    'SPLTW2' : 'Lotto Supe',

    'SPLT' : 'Lotto Supe',
  },
  version25 : {
    sport : 'Olahraga-Ponymuah',
    live :'Live-Ezugi',
    electron : 'Arkade-Evolution',

    sport_index : 'Olahraga',
    live_index :'Live',
    electron_index : 'Arkade',

    PG : 'Arkade-PocketGame',
    PP : 'Arkade-PragmaticPlay',
    "sportsNum": "Jumlah olahraga",
    "sportsAward": "Jackpot Olahraga",
    "sportsBet": "Judi Olahraga",
    "sportsProfit": "Keuntungan Olahraga",

    electronNum : 'Jumlah Arkade',
    electronAward : 'Jackpot Arkade',
    electronBet:'Judi Arkade',
    electronProfit : 'Keuntungan Arkade',

    liveNum : 'Jumlah Live',
    liveAward : 'Jackpot Live',
    liveBet:'Judi Live',
    liveProfit : 'Keuntungan Live',
		
	Num : 'Jumlah',
    Award : 'Jackpot',
    Bet:'Judi',
    Profit : 'Keuntungan',

    ourGame : 'Original Games',
    "t1": "Banyak Game, Mainkan di Satu Tempat",
    "t2": "Platform game terkemuka di dunia",
  },
  version26 : {
    liveTeamReport : 'Laporan tim waktu nyata',
    tip2 : `Anda hanya dapat memilih waktu dalam 24 jam terakhi`
  },
  version27 : {
    PGrecord : 'Catatan Arkade-PocketGame',
    PPRecord : 'Catatan Arkade-PragmaticPlay',

  }
}