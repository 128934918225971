module.exports = {
  "nav": {
    "shouye": "Trang đầu",
    "hanqing": "Thị trường",
    "jiaoyi": "Giao dịch",
    "heyue": "Hợp đồng",
    "wode": "Của tôi"
  },
  "common": {
    "login": "Đăng nhập",
    "register": "Đăng ký",
    "loginregister": "Đăng nhập / Đăng ký",
    "logout": "Đăng xuất",
    "tip": "Xin lưu ý",
    "delete": "Bạn có chắc chắn muốn xóa nó không?",
    "set": "Cài đặt",
    "update": "Sửa",
    "more": "Nhiều hơn",
    "loading": "Đang tải",
    "zanwu": "Tạm thời không có dữ liệu",
    "quxiao": "Hủy bỏ",
    "ok": "Chắc chắn rồi",
    "noyanzhengma": "Mã xác minh không chính xác",
    "clearStorage": "Xóa bộ nhớ cache",
    "telErr": "Định dạng số điện thoại di động không chính xác",
    "T404": "Hệ thống đang được cập nhật và quyền truy cập sẽ sớm được khôi phục"
  },
  "loginPage": {
    "mima": "Mật khẩu",
    "passwordTxt": "Xin vui lòng nhập mật khẩu"
  },
  "homePage": {
    "charge": "Nạp",
    "pickup": "Rút",
    "exchange": "Giao ngay",
    "promotionaward": "Hoàn trả"
  },
  "pageTitle": {
    "gonggaoxiangqing": "Thông báo",
    "wodezichang": "Tài sản của tôi",
    "bangzhuxiangqing": "Thông tin chi tiết",
    "fundpwd": "Mật khẩu quỹ",
    "yuyanshezhi": "Ngôn ngữ",
    "huazhuang2": "Chuyển ",
    "renzheng": "Xác minh tên thật",
    "chooseAlterPhoneType": "Số điện thoại",
    "chooseAlterEmailType": "Thư",
    "letterList": "Thông tin",
    "appDownload": "Tải ứng dụng",
    "buy": "Mua vào",
    "sell": "Bán ra",
    "cexiao": "Huỷ đơn",
    "time": "Thời gian",
    "type": "Loại",
    "jiage": "Giá",
    "weituoliang": "Lượng uỷ thác",
    "changjiao": "Đã hoàn thành",
    "weituoe": "Số tiền uỷ thác",
    "over": "Đã tải toàn bộ",
    "weituojia": "Giá uỷ thác",
    "weituoer": "Tổng số tiền uỷ thác",
    "quedingchedan": "Bạn có chắc chắn muốn huỷ đơn không?",
    "chedaning": "Đang huỷ đơn",
    "jiaoyidui": "Cặp giao dịch",
    "fangxiang": "Hướng",
    "chongzhi": "Cài lại",
    "xianjia": "Giới hạn",
    "shijia": "Giá thị trường",
    "plan": "Kế hoạch",
    "quanbu": "Tất cả ",
    "xianjiaweituo": "Giới hạn uỷ thác",
    "yichexiao": "Đã hủy",
    "time1": "Thời gian bắt đầu không được lớn hơn thời gian kết thúc",
    "time2": "Thời gian kết thúc không được nhỏ hơn thời gian bắt đầu",
    "time3": "Thời gian kết thúc không được lớn hơn thời gian hiện tại",
    "cedancg": "Hủy thành công",
    "cedansb": "Hủy không thành công",
    "mairujiage": "Đơn giá",
    "yongxu": "SWAP",
    "zhanghu": "Tổng lợi ích của tài khoản",
    "yugu": "Dự đoán giá thanh khoản",
    "baozheng": "Tỷ lệ ký quỹ",
    "kaicang": "Mở vị thế",
    "baocang": "Thanh lý",
    "pincang": "Đóng vị thế",
    "zhanghu2": "Tài khoản",
    "pingduo": "Đóng mua",
    "kaiduo": "Mở mua",
    "pingkong": "Đóng bán",
    "kaigong": "Mở bán",
    "cufajia": "Giá khởi phát",
    "zuiyou": "Giá tốt nhất",
    "zhang": "Mở",
    "keyong": "Có thể dung",
    "kekaiduo": "Max. L",
    "kekaikong": "Max. S",
    "duocang": "Max (L)",
    "kongcang": "Max (S)",
    "kanzhang": "Tăng giá",
    "kandie": "Giảm giá",
    "shuliang": "Số lượng",
    "chicang": "Vị thế hiện tại",
    "dangqianweituo": "Uỷ thác hiện tại",
    "lishiweituo": "Lịch sử uỷ thác",
    "shouyi": "Thu nhập",
    "shouyilv": "Tỷ lệ lợi nhuận",
    "gangganbeishu": "Bội số đòn bẩy",
    "chicangliang": "Lượng nắm giữ",
    "kepingliang": "Lượng có thể đóng",
    "kaichangjun": "Giá đầu vào",
    "zuixinjia": "Giá mới nhất",
    "dangqianbaozheng": "Tiền ký quỹ hiện tại",
    "duotou": "Mua",
    "kongtou": "Bán",
    "baozhengjin": "Tiền ký quỹ",
    "weituoshuliang": "Số lượng uỷ thác",
    "jiesuanweik": "Thanh toán lãi và lỗ",
    "chengjiaojia": "Giá giao dịch thành công",
    "shouxufei": "Phí thủ tục",
    "xiugaichenggong": "Đã sửa đổi thành công"
  },
  "views": {
    "wangjiPass": "Quên mật khẩu?",
    "confirmLogOut": "Bạn có chắc chắn muốn thoát?",
    "personalCenter": "Trung tâm cá nhân",
    "shezhi": "Thiết lập thành công",
    "yuanyouxiang": "Mã xác minh email gốc",
    "newyouxiang": "Mã xác minh email mới",
    "fsyouxiang": "Gửi mã xác minh",
    "xyouxiang": "Hộp thư mới",
    "loginPass": "Mật khẩu đăng nhập",
    "baocun": "Tiết kiệm",
    "fschenggong": "Gửi thành công",
    "inputyouxiang": "Vui lòng nhập email mới",
    "inputOld": "Vui lòng nhập mã xác minh email ban đầu",
    "inputNew": "Vui lòng nhập mã xác minh email mới",
    "inputPass": "Vui lòng nhập mật khẩu của bạn",
    "OldPass": "Mật khẩu đăng nhập cũ",
    "inputOldPass": "Vui lòng nhập mật khẩu đăng nhập cũ",
    "NewPass": "Mật khẩu đăng nhập mới",
    "inputNewPass": "Vui lòng nhập mật khẩu đăng nhập mới",
    "queren": "Xác nhận mật khẩu",
    "email": "Mã xác minh email",
    "liangciPass": "Hai mật khẩu đã nhập không khớp",
    "shuruCode": "Vui lòng nhập mã xác nhận",
    "OldZJ": "Mật khẩu ví cũ",
    "inputOldZJ": "Vui lòng nhập mật khẩu ví cũ",
    "NewZJ": "Mật khẩu ví mới",
    "inputNewZJ": "Vui lòng nhập mật khẩu ví mới",
    "augeren": "Thông tin cá nhân",
    "aushang": "Tải giấy tờ tuỳ hơn",
    "aushenghe": "Xét duyệt",
    "name": "Họ Tên",
    "inputName": "Bui lòng nhập tên thật của bạn",
    "huzhao": "Số giấy tờ tuỳ thân",
    "inputhuzhao": "Vui lòng nhập số giấy tờ tuỳ thân",
    "next": "Bước tiếp theo",
    "uphuzhao": "Tải ảnh giấy tờ tuỳ thân",
    "shouhuzhao": "Tải ảnh hộ chiếu",
    "tijiao": "Gửi đi",
    "tip1": "1. Giấy tờ tuỳ thân phải còn trong thời hạn hiệu lực",
    "tip2": "2. Nội dung chữ ký cá nhân bao gồm {hrefArry} viết tay, chữ ký chính chủ và ngày tháng hiện tại",
    "tip3": "3. Hãy đảm bảo rằng nội dung của ảnh và chữ ký cá nhân được hiển thị rõ ràng",
    "shentijiao": "Thông tin đã được gửi, đang chờ xét duyệt",
    "fanhui": "Trở về",
    "bangding": "Liên kết lời mời",
    "inputEmail": "Vui lòng nhập email của bạn",
    "inputPhone": "Vui lòng nhập số điện thoại",
    "kefu": "Vui lòng liên hệ với bộ phận chăm sóc khách hàng"
  },
  "list": {
    "wufaxiugia": "Bạn đang có một vị thế hoặc đơn uỷ thác, không thể sửa đổi bộ số",
    "submittip1": "Để đảm bảo an toàn cho số tiền của bạn, vui lòng thực hiện xác thực tên thật trước ",
    "submittip2": "Để bảo mật ví của bạn, vui lòng đặt mật khẩu của ví trước",
    "shuruweituo": "Vui lòng nhập giá uỷ thác",
    "shuruweituoliang": "Vui lòng nhập lượng uỷ thác",
    "dayukekai": "Lượng uỷ thác hiện giờ lớn hơn số lượng có thể mua",
    "dayuduocang": "Lượng uỷ thác hiện giờ lớn hơn số lượng vị thế có thể mua",
    "dayukekaikong": "Lượng uỷ thác hiện giờ lớn hơn số lượng có thể bán",
    "dayukongcang": "Lượng uỷ thác hiện giờ lớn hơn số lượng vị thế có thể bán",
    "shuruchufa": "Vui lòng nhập giá khởi phát",
    "zhangfubang": "Danh sách tăng",
    "liang": "Số lượng",
    "safar": "Nhấp vào nút bên dưới trong Safari",
    "kuaisuanzhuang": "Chọn [Thêm vào MH chính]",
    "noAccount": "Không có tài khoản?",
    "LoginReg": "Đăng ký ngay",
    "butigongfuwu": "IP hoặc khu vực của bạn tạm thời không khả dụng",
    "renzheng1": "Đã xác thực",
    "renzheng2": "Xác thực thất bại",
    "renzheng3": "Đang xét duyệt",
    "renzheng4": "Vui lòng xác thực",
    "shenheing": "Đang xét duyệt, vui lòng đợi",
    "inputzaicimima": "Vui lòng nhập lại mật khẩu",
    "xuantian": "Vui lòng nhập mã mời (tùy chọn)",
    "yuedu": "Tôi đã đọc và đồng ý ",
    "yonghuxieyi": "Thỏa thuận người dùng",
    "hasAccount": "Đã có tài khoản?",
    "denglu": "Đăng nhập ngay",
    "gouxuanxieyi": "Vui lòng đọc và đánh dấu vào Thỏa thuận người dùng",
    "inputzijinmima": "Vui lòng nhập mật khẩu ví",
    "yijianfankui": "Nhận xét",
    "gao": "Cao",
    "di": "Thấp",
    "shengdu": "Chiều sâu",
    "chengjiao": "Giao dịch thành công",
    "zixuan1": "Đã huỷ tuỳ chọn",
    "zixuan2": "Huỷ tuỳ chọn thất bại",
    "zixuan3": "Thêm tùy chọn thành công",
    "zixuan4": "Thêm tùy chọn thất bại",
    "jiaoyie": "Tổng giao dịch",
    "chengjiaozonger": "Tổng giao dịch thành công",
    "chengjiaojun": "Giá giao dịch thành công",
    "chengjiaoliang": "Lượng giao dịch thành công",
    "bigmairu": "Đã vượt quá số lượng tối đa có thể giao dịch",
    "weituochenggong": "Đã ủy thác thành công",
    "weituoshibai": "Ủy thác không thành công",
    "yue": "Số tiền",
    "bizhong": "Loại tiền tệ",
    "huazhuanshuliang": "Số lượng chuyển đổi",
    "inputhzsl": "Vui lòng nhập số lượng chuyển đổi",
    "bunengweifu": "Số lượng chuyển đổi không được âm",
    "dayuhuazhuan": "Số lượng chuyển đổi hiện giờ lớn hơn số lượng có thể chuyển đổi trong tài khoản",
    "huazhuanchenggong": "Chuyển đổi thành công",
    "huazhuangshibai": "Chuyển đổi thành công"
  },
  "user": {
    "guizeshuoming": "Qui định",
    "shenheweitongguo": "Xét được duyệt",
    "shiming": "Vui lòng hoàn thành xác thực tên thật",
    "fuzhichenggong": "Sao chép thành công",
    "frozen": "Đóng băng (tạm khoá)",
    "zhanghuquanyi": "Quyền lợi tài khoản",
    "baozhenjin": "Tiền ký quỹ có thể sử dụng",
    "jiaoyishijian": "Giờ giao dịch"
  },
  "tradePage": {
    "CUSTOM": "Tự chọn"
  },
  "chart": {
    "minute": "Phút"
  },
  "charge": {
    "congbi": "Địa chỉ nạp tiền",
    "baocun": "Lưu hình ảnh mã QR"
  },
  "heyuePage": {
    "yijianpingcang": "Flash Close",
    "copyerr": "Sao chép không thành công",
    "pincang": "Số vị thể đóng không thể bằng 0"
  },
  "yaoqin": {
    "zuce": "Thời gian đăng ký",
    "showPersonInfo": "Xem thông tin cá nhân của bạn",
    "myinvitelink": "Liên kết lời mời",
    "my_column4": "Cấp bậc",
    "yaoqing": "Mời",
    "dengji": "Tiến độ thăng cấp",
    "mubiao": " Mục tiêu",
    "zong": "Tổng hoàn trả",
    "jiaoyi": "Chi tiết giao dịch",
    "wode": "Mã mời",
    "huodong": "Thưởng hoạt động"
  },
  "version3": {
    "hash": "Giá trị băm",
    "betTip": "Đoán chữ số cuối cùng của giá trị băm của khối ETH đầu tiên cứ sau 10 phút, 0-7 là nhỏ, 8-f là lớn; 0, 2, 4, 6, 8, a, c, e là chẵn, 1, 3, 5, 7, 9, b, d, f là lẻ.",
    "betTip2": "Đoán chữ số cuối cùng của giá trị băm, 0-7 là nhỏ, 8-f là lớn; 0, 2, 4, 6, 8, a, c, e là chẵn, 1, 3, 5, 7, 9, b, d, f là lẻ.",
    "betMoneyTip": "Số tiền đơn cược",
    "doBet": "Cược ngay",
    "issue": "No.",
    "blockHigh": "chiều cao khối",
    "blockTime": "Thời gian ra khối",
    "hash2": "Giá trị băm",
    "gameRecord": "Lịch sử trò chơi",
    "betTime": "Thời gian đặt cược",
    "pickUpAddress": "Địa chỉ rút tiền",
    "arriveAccountNum": "Số lượng vào tài khoản",
    "remark": "Ghi chú",
    "contachService": "Liên hệ với dịch vụ khách hàng",
    "totalBetMoney": "Tổng đơn đặt cược",
    "unOpen": "Chưa mở thưởng",
    "maintain": "Đang bảo trì",
    "hasBingGo": "Đã trúng thưởng",
    "unBingGo": "Không trúng thưởng",
    "outOfBalance": "Số tiền không đủ",
    "chooseBetNumTip": "Vui lòng chọn hạng mục cược",
    "chooseBetMoney": "Vui lòng nhập số tiền đơn cược",
    "minBetAmount": "Số tiền đơn cược ít nhất là ",
    "maxBetAmount": "Số tiền đơn cược nhiều nhất là ",
    "chooseWithDrawAddressTip": "Vui lòng chọn địa chỉ",
    "inputRemarkTip": "Vui lòng nhập địa chỉ rút tiền",
    "confirmOrder": "Xác nhận đơn cược",
    "manji": "Cấp VIP đã đầy",
    "selWithAddress": "Vui lòng chọn địa chỉ rút tiền",
    "withNumTip": "Số tiền rút phải là",
    "unLiuShui": "Vòng cược chưa hoàn thành",
    "withdrawChannel": "Kênh rút tiền"
  },
  "version4": {
    "game": "Trò chơi",
    "baocangIntro": "Vào {date}, tổng quyền lợi tài khoản hợp đồng {account} của bạn là 0 USDT và tỷ lệ ký quỹ là ≤0, điều này kích hoạt thanh toán bắt buộc, tức là thanh lý.",
    "geshi": "Vui lòng nhập đúng định dạng số tiền",
    "mingc": "âm lượng",
    "zhangd": "Tăng giảm",
    "quanqiu": "Global",
    "yiliu": "A-team",
    "yinhang": "Security"
  },
  "version5": {
    "rechargeWay": "Kênh nạp tiền",
    "inputChargeNum": "Vui lòng nhập số tiền nạp",
    "receivePeople": "Người nhận ",
    "bankCard": "Thẻ ngân hàng",
    "bank": "Ngân hàng",
    "bankNumber": "Tài khoản ngân hàng",
    "bankProvince": "Tỉnh mở tài khoản",
    "bankCity": "Thành phố mở tài khoản",
    "bankBrancher": "Chi nhánh mở tài khoản",
    "rengong": "Gửi và rút tiền thủ công"
  },
  "version7": {
    "p6": "Vui lòng liên kết thẻ ngân hàng của bạn trước",
    "jinxingzhong": "Đang giao dịch",
    "yiwancheng": "Đã hoàn thành",
    "shibai": "Thất bại",
    "nichen": "Tên tài khoản",
    "p8": "Giới hạn mỗi đơn cược",
    "username": "Vui lòng nhập tên người dùng"
  },
  "version8": {
    "noArrive0": "Số tiền nhận được không được bằng 0",
    "xuantian": "Vui lòng nhập mã mời",
    "result": "kết quả",
    "color": "màu sắc",
    "hlRule": "Đoán giá trị của 0-9, 1, 3, 5, 7, 9 là màu xanh lục, 0, 2, 4, 6, 8 là màu đỏ và 0 hoặc 5 đồng thời là màu tím."
  },
  "Transfer": {
    "FriendsReport": "Báo cáo nhóm",
    "jintian": "Hôm nay",
    'qiantian' : 'Ngày trước',

    "zuotian": "Hôm qua",
    "benzhou": "Tuần này",
    "shangzhou": "Tuần trước",
    "benyue": "Tháng này",
    "shangyue": "Tháng trước",
    "zuce": "Số lượng người đăng ký",
    "shoucong": "Số lượng người nạp đầu",
    "chongzhi": "Số lượng người nạp",
    "tixian": "Số lượng người rút",
    "heyue": "Số lượng người hợp đồng",
    "youxi": "Số lượng người chơi",
    "touzu": "Cược trò chơi",
    "zhongjiang": "Trò chơi trúng thưởng",
    "shimingrenshu": "Só lượng người xác thực",
    "status_1": "Đang xét duyệt",
    "status_3": "Thất bại",
    "status_4": "Thành công",
    "jilu": "Lịch sử"
  },
  "version9": {
    "SD1MRule": "Đoán số lượng màu đỏ và màu trắng trên đĩa màu. Số chẵn của đĩa cùng màu là chẵn và số lẻ của đĩa cùng màu là lẻ.",
    "FourRed": "4đỏ/0trắng",
    "ThreeRedOneWhite": "3đỏ/1trắng",
    "FourWhite": "0đỏ/4trắng",
    "OneRedThreeWhite": "1đỏ/3trắng"
  },
  "version10": {
    "titMode3": "Khi rút tiền, bạn cần phải trả phí khai thác để đảm bảo rằng các giao dịch trên  được xử lý. Phí khai thác hiện tại là {minTxFee} USDT.",
    "onGame": "Đang tiến hành",
    "tuihui": "Đã trở về",
    "hasJump": "Đã nhảy dù",
    "player": "người chơi",
    "money": "Số tiền",
    "jump": "Nhảy dù",
    "jumpMulti": "Bội số nhảy dù",
    "betMoney": "Số tiền đặt cược",
    "autoJumpTip": "Bội số nhảy dù tự động ( lựa chọn ）",
    "p1": "Bội số nhảy dù tối thiểu phải lớn hơn ",
    "p2": "Bội số nhảy dù tối đa là ",
    "autoJump": "nhảy dù tự động",
    "boomValue": "Bội số bùng nổ",
    "crash": "tai nạn",
    "noRepeatOrder": "Không cược trùng lập",
    "jumpSuccess": "nhảy dù thành công",
    "tip1_plan": "1. Bội số nhảy dù tự động là tùy chọn. Sau khi điền, bạn sẽ tự động nhảy dù khi máy bay đạt đến bội số tương ứng hoặc bạn có thể nhảy dù theo cách thủ công trước khi máy bay gặp sự cố; nếu chưa điền, bạn vẫn có thể nhảy dù thủ công trước khi máy bay sự cố",
    "tip2_plan": "2. Chúng tôi sẽ rút {amount}% từ lợi nhuận của những người chơi nhảy dù",
    "dizhi": "Vui lòng nhập địa chỉ chính xác"
  },
  "version11": {
    "trend": "Xu hướng",
    "goldenBroad": "Thông báo tin trúng thưởng",
    "broadTxt": "Xin chúc mừng {userName} trúng <span class = 'Tcolor'> {winMoney} </span> USDT",
    "TS": "Nhảy dù",
    "CQK1M": "Đoán khối 1 phút",
    "CQK5M": "Đoán khối 5 phút",
    "CQK": "Đoán khối 10 phút (liên kết)",
    "HL": "Xanh đỏ 1 phút",
    "HL5M": "Xanh đỏ 5 phút",
	"RG30S": "Xanh đỏ 30 giây",
    "SD1M": "Xóc dĩa 1 phút",
    "SD5M": "Xóc dĩa 5 phút",
    "Gogame": "vào trò chơi",
    "yinli": "Số tiền lợi nhuận",
    "munber": "Số tiền lợi nhuận = lãi và lỗ giao dịch + thưởng hoạt động + hoàn trả",
    "Gyinkui": "Lãi lỗ trò chơi",
    "Hyinkui": "Lãi lỗ hợp đồng",
    "Hlijin": "Thưởng hoạt động",
    "Yfanyong": "Hoàn trả"
  },
  "version12": {
    "hl10mRule": "Đoán giá trị 0-9  từ chữ số cuối cùng của giá trị băm của ETH đầu tiên 10 phút một lần, 1, 3, 5, 7, 9 có màu xanh lục, 0, 2, 4, 6, 8 là màu đỏ và 0 hoặc 5 cũng có màu tím.",
    "sd10mRule": "Đoán bốn chữ số cuối cùng của giá trị băm của khối ETH đầu tiên sau mỗi 10 phút, mỗi chữ số tương ứng với một đĩa màu, 0-7 là màu trắng, 8-f là màu đỏ.Đĩa có màu tương đồng có số chẵn là chẵn,đĩa có màu tương đồng có số lẻ là lẻ.",
    "SD10M": "Xóc dĩa 10 phút (liên kết)",
    "HL10M": "Xanh đỏ 10 phút (liên kết)",
    "refresh": "Làm mới"
  },
  "version13": {
    "kaijiangzhong": "Đang mở thưởng",
    "canyu": "của tôi",
    "kaijiang": "Đã mở thưởng",
    "join": "Tham gia",
    "buyAmount": "Vui lòng nhập số lượng phần mua",
    "minBetAmount": "Số lượng phần mua tối thiểu là ",
    "maxBetAmount": "Số lượng phần mua tối đa là ",
    "tipMax": "Vui lòng không quá số lượng phần",
    "totalVal": "Tổng giá trị",
    "jindu": "Tiến độ",
    "leave": "còn lại",
    "goldNumber": "Số trúng thưởng",
    "myGoldNumber": "Số của tôi",
    "val": "Giá trị số",
    "mode": "Giá trị số",
    "unJoin": "Chưa tham gia",
    "calNumber": "Đang tính toán",
    "eachPrice": "Giá mỗi phần",
    "currentIssue": "Lịch sử tham gia kỳ này",
    "hisIssue": "50 hồ sơ tham gia và lịch sử trước mở thưởng",
    "showDetail": "Kiểm tra",
    "buy": "Mua",
    "buyTime": "thời gian mua ",
    "luckNumber": "số đổi thưởng",
    "totalBuyAmount": "tổng số mua",
    "buyTip2": "Vui lòng nhập đúng qui cách phần mua",
    "goldMember": "Thành viên trúng thưởng",
    "openTime": "Thời gian mở thưởng",
    "Wtime": "Thời gian bảo trì ước tính",
    "invitationNote": "Hướng dẫn lời mời",
    "p1": "<ins class = 'paramTitle'>Mời bạn bè</ins>Vào \"Trung tâm Lời mời\", sao chép liên kết hoặc mã lời mời và chia sẻ với bạn bè của bạn. Bạn bè có thể trở thành cấp dưới của bạn bằng cách đăng ký bằng mã mời của bạn.",
    "p2": "<ins class = 'paramTitle'>Kiếm chiết khấu</ins>Khi cấp dưới giao dịch, bạn có thể nhận được các khoản giảm giá tương ứng, tối đa ba cấp dưới được hỗ trợ. Ví dụ: nếu bạn mời người bạn A, A mời B và B mời C, sau đó A, B và C tiến hành trò chơi, hợp đồng và các giao dịch khác trên nền tảng và bạn có thể nhận được các khoản giảm giá tương ứng.",
    "fuk": "Vui lòng thanh toán",
    "guoq": "Hết hạn",
    "exchtishi": "Vui lòng sử dụng thẻ ngân hàng tên thật của bạn để chuyển tiền vào tài khoản trên",
    "exchtishi2": "Vui lòng không ghi chú BTC, USDT và các thông tin khác trong quá trình chuyển tiền để tránh việc chuyển tiền bị chặn và các vấn đề khác",
    "quxiaoOrder": "Hủy đơn",
    "chengong": "Tôi đã thanh toán thành công",
    "fukuanren": "tên thanh toán",
    "fukuanrenTi": "Vui lòng điền tên thanh toán",
    "zhanghao": "tài khoản thanh toán",
    "zhanghaoTi": "Vui lòng điền vào tài khoản thanh toán",
    "yihang": "ngân hàng thanh toán",
    "yihangTi": "Vui lòng điền vào ngân hàng thanh toán",
    "jietu": "Ảnh chụp màn hình thanh toán",
    "jietuTi": "Vui lòng tải lên ảnh chụp màn hình thanh toán",
    "queren": "Vui lòng xác nhận rằng bạn đã thanh toán",
    "queren2": "Xác nhận ác ý sẽ trực tiếp đóng băng tài khoản",
    "YYYG": "1U May mắn",
    "dragon": "Bảng xếp hạng",
    "paimng": "xếp hạng"
  },
  "version15": {
    "only60Days": "Chỉ lưu hồ sơ trong 60 ngày",
    "only100Records": "Chỉ hiển thị lịch sử 100 bản cuối cùng",
    "status": "Trạng thái",
    "luckyWheel": "Rút thăm trúng thưởng",
    "option1": "Thưởng",
    "option2": "Thanks",
    "option3": "Once More",
    "freeTimes": "Số lần rút thăm",
    "p1": "Sau khi đăng ký thành công, bạn sẽ có cơ hội bốc thăm trúng thưởng {registerGiveCount}.",
    "p2": "Ngày hôm qua (dựa trên múi giờ UTC + 0).Số tiền nạp đạt đến {todayRechargeCondition} USDT, bạn có thể nhận được cơ hội rút thăm may mắn {rechargeGiveCount}.",
    "p3": "Hôm qua (dựa trên múi giờ UTC + 0), số tiền đặt cược hiệu quả (không bao gồm giao ngay và hợp đồng) đạt {todayBettingCondition} USDT và bạn có thể nhận được cơ hội rút thăm may mắn {bettingGiveCount}.",
    "continuDraw": "Tiếp tục rút thăm",
    "noFreeTimeTip": "Số lần rút thăm trúng thưởng của bạn đã hết"
  },
  "version16": {
    "totalPerMoney": "Tổng số tiền cược",
    "notFu": "Số tiền đặt cược không được âm",
    "find": "Tìm thấy",
    "channel": "kênh "
  },
  "version17": {
    "drawWarmTip": "Có thể mất 10 phút đến nửa tiếng để xác nhận số lượng rút thăm may mắn được đưa ra, vui lòng kiên nhẫn chờ",
    "level": "Cấp bậc",
    "upLimit": "Tiêu chuẩn thăng cấp",
    "chatRoom": "Trò chuyện",
    "group": "Trò chuyện nhóm",
    "privateChat": "Trò chuyện riêng",
    "groupManager": "người quản lý",
    "groupMember": "thành viên nhóm",
    "groupSetting": "Cài đặt nhóm",
    "totalChargeMoney": "Tích luỹ nạp",
    "vip9Tip": "Cấp VIP đã đầy",
    "dataTime": "Dữ liệu thời gian",
    "sortType": "sắp xếp theo",
    "loginTime": "Thời gian đăng nhập",
    "lowerPeople": "Số lượng cấp dưới",
    "chargeNum": "Số tiền nạp",
    "withdrawNum": "Số tiền rút",
    "lowerReport": "áo cáo nhóm",
    "lowerUserBetNum": "Cược cấp dưới",
    "lowerUserAwardNum": "Trúng thưởng cấp dưới",
    "lowerUserChargeNum": "Nạp cấp dưới",
    "lowerUserWithdrawNum": "Rút cấp dưới",
    "firstRechargeNum": "Số tiền nạp đầu ",
    "warmTip": "Hiển thị dữ liệu ngày hôm nay theo mặc định",
    "publishNotice": "Thông báo",
    "editNotice": "Thông báo biên tập",
    "noticeTime": "Thời gian công bố",
    "inputNoticeTip": "Vui lòng nhập nội dung thông báo",
    "tip": "Sau khi tất cả thành viên bị cấm phát ngôn, chỉ quản trị viên nhóm mới được phép phát ngôn",
    "allNotSpeak": "Tất cả thành viên bị cấm phát ngon",
    "canSpeakLevel": "Cấp bậc phát ngôn",
    "eryuanqiquan": "Quyền chọn nhị phân",
    "optionHelpText": "Dự đoán sự tăng và giảm của khoảng thời gian tiếp theo. Giá đầu kỳ là giá khởi điểm, giá cuối kỳ là giá quyết toán, nếu giá thanh toán lớn hơn giá khởi điểm thì giá khởi điểm sẽ tăng lên, còn nếu giá thanh toán nhỏ hơn. so với giá khởi điểm, nó sẽ giảm xuống.",
    "optionRecord": "Lịch sử tùy chọn",
    "calcuteDirection": "Hướng dự đoán",
    "startPrice": "Giá khởi điểm",
    "endPrice": "Giá thanh toán",
    "equal": "Không tăng giảm",
    "waitResult": "Đang thanh toán",
    "up": "Tăng",
    "down": "Giảm",
    "findPassword": "Lấy lại mật khẩu",
    "calTime": "Thời gian dự báo",
    "calWeek": "Chu kỳ dự báo",
    "kanzhang": "Xem tăng",
    "kandie": "Xem giảm"
  },
  "version18": {
    "issue": "Số kỳ",
    "vipSort": "Cấp độ VIP",
    "jinyan": "Đã cấm phát ngôn",
    "weijinyan": "Chưa cấm phát ngôn",
    "confirmDelete": "Bạn có chắc chắn muốn xóa thành viên không?",
    "confirmUnSpeak": "Bạn có chắc chắn muốn cấm thành viên phát ngôn không?",
    "confirmSpeak": "Bạn có chắc chắn muốn bỏ cấm thành viên không?",
    "confirmAdd": "Bạn có chắc chắn muốn thêm thành viên không?",
    "addMember": "Thêm thành viên",
    "delMember": "Xóa thành viên",
    "lockSpeak": "Cấm phát ngôn",
    "unLockSpeak": "Bỏ cấm ",
    "lockSpeakSelMember": "Thành viên bị cấm phát ngôn",
    "unLockSpeakSelMember": "Bỏ cấm thành viên",
    "noSelMemberTip": "Vui lòng chọn một thành viên",
    "recentlyPlayGame": "Chơi gần đây",
    "sendMsg": "Gửi tin nhắn",
    "addGroupMember": "Thêm thành viên",
    "picTip": "[hình ảnh]",
    "time3": "1 tuần trước",
    "chooseAll": "Chọn lại",
    "personalPage": "Trang chủ cá nhân",
    "CQK3M": "Đoán khối 3 phút",
    "HL3M": "Xanh đỏ 3 phút",
    "SD3M": "Xóc dĩa 3 phút"
  },
  "version19": {
    "avatar": "Hình đại diện",
    "share": "Chia sẻ",
    "todayProfit": "Lợi nhuận hôm nay",
    "followBet": "Cược theo",
    "Jump": "Đi đến",
    "over": "đã  kết thúc",
    "optionNumber": "Số người quyền chọn",
    "optionBet": "Cược quyền chọn",
    "optionReward": "Trúng thưởng quyền chọn",
    "optionProfit": "Lời lỗ quyền chọn",
    "RG_2_0": "Đỏ",
    "RG_5_1": "Tím",
    "RG_2_1": "Xanh",
    "DS_16_0": "1111",
    "DS_16_1": "0000",
    "DS_4_0": "1110",
    "DS_4_1": "1000",
    "DS_2_0": "Chẵn ",
    "DS_2_1": "Lẻ",
    "BLK_2_0": "Lớn",
    "BLK_2_1": "Nhỏ ",
    "BLK_2_2": "Lẻ",
    "BLK_2_3": "Chẵn ",
    "BLK_4_0": "Lớn-Lẻ",
    "BLK_4_1": "Lớn-Chẵn",
    "BLK_4_2": "Nhỏ-Lẻ",
    "BLK_4_3": "Nhỏ-Chẵn"
  },
  "version20": {
    "server": "Customer Service",
    "teacher": "Người hướng dẫn",
    "financer": "tài chính",
    "planer": "người lập kế hoạch",
    "chatPop": "Cửa sổ trò chuyện",
    "cancelText": "Hủy cửa sổ ",
    "betNumber": "Lựa chọn cược",
    "resendMsg": "Không gửi được tin nhắn, bạn có muốn gửi lại tin nhắn không?",
    "delThisMsg": "xóa tin nhắn này",
    "delThisMemberAllMsg": "xóa tất cả tin nhắn từ thành viên này",
    "forbitThisMember": "Cấm phát ngôn thành viên",
    "delThisMember": "xóa thành viên",
    "delTipModal": "Vui lòng chọn chức năng để thao tác"
  },
  "version21": {
    "USDT_account": "Tài khoản USDT",
    "closeSwapTip": "Vui lòng chọn tỷ lệ đóng vị thể",
    "hasRecharge": "Đã nạp",
    "numberCoin": "Tiền kỹ thuật số",
    "none": "-",
    "fastCharge": "Nạp tiền nhanh ",
    "bankTransfer": "Chuyển khoản ngân hàng",
    "setLegalTip": "Vui lòng chọn đơn vị tiền tệ fiat của bạn",
    "legalCoin": "Tiền tệ fiat",
    "orderCreateTime": "thời gian tạo",
    "tradeOrderNumber": "Số giao dịch",
    "completeTime": "Thời gian hoàn thành",
    "fastWithdraw": "Rút tiền nhanh ",
    "bindCardTip1": "Vui lòng điền tên đầy đủ giấy tờ tuỳ thân hợp lệ của bạn, không thể sửa đổi sau khi gửi",
    "bindCardTip2": "Vui lòng điền thông tin thẻ ngân hàng của bạn"
  },
  "saas": {
    "fastChargeType": "Kênh nạp nhanh",
    "numberCoinChargeType": "nạp tiền kỹ thuật số",
    "humanChargeType": "Nạp tiền thủ công",
    "fastChannelWithdrawType": "Kênh rút nhanh",
    "numberCoinWithdrawType": "rút tiền kỹ thuật số",
    "humanWithdrawType": "Rút tiền thủ công",
    "fastChannelBackType": "Trở về kênh nhanh",
    "numberChannelBackType": "Trở về tiền kỹ thuật số",
    "betType": "Đặt cược",
    "betProfitType": "Lãi",
    "betEqualType": "Hoà",
    "gameBackRewardType": "Hoàn trả trò chơi",
    "swapBackRewardType": "Hoàn trả hợp đồng",
    "exchangeBackRewardType": "Hoàn trả giao ngay",
    "tranInType": "Chuyền vào",
    "tranOutType": "chuyển ra",
    "chargeRewardType": "Thưởng nạp",
    "firstChargeRewardType": "Thưởng nạp đầu",
    "regRewardType": "Thưởng đăng ký",
    "upGradeRewardType": "Thưởng thăng cấp",
    "luckyRewardType": "Rút thăm may mắn",
    "realNameRewardType": "Thưởng xác minh tên thật",
    "betRewardType": "Thưởng cược",
    "dailyFirstChargeRewardType": "Thưởng nạp đầu mỗi ngày",
    "upScoreType": "Thêm thủ công",
    "downScoreType": "Giảm thủ công",
    "unRecharge": "Chưa nạp",
    "canWithdrawBalance": "Số dư khả dụng",
    "withPopTip1": "Khi rút tiền,có vòng cược còn chưa hoàn thành,không thể rút tiền",
    "withPopTip2": "Khi rút tiền,số vòng cược chưa hoàn thành cần phải trả {flowFee}% phí thủ tục vòng cược.",
    "alterBankCardNameTip": "Nếu bạn cần thay đổi tên của mình, vui lòng liên hệ với bộ phận chăm sóc khách hàng",
    "arriveMoney": "Số tiền nhận được",
    "withdrawNum": "Số tiền rút",
    "failReason": "Lý do thất bại",
    "tradeHash": "Giao dịch băm ( hash )",
    "waitCoin": "chờ vay",
    "zicang": "tài sản",
    "otherMoney": "Ước tính các tài sản khác",
    "moneyList": "Danh sách tài sản",
    "onlyShowSwap": "Chỉ nhần hợp đồng",
    "hideZeroMoney": "ẩn 0 tài sản",
    "chargeFlowTip1": "Số dư được nạp vào tài khoản USDT sẽ tăngvòng cược chưa hoàn thành lên {deposit}%. Ví dụ: nếu khoản tiền gửi là 1000USDT, vòng cược chưa hoàn thành sẽ tăng {depositValue} USDT.",
    "chargeFlowTip2": "Các vòng cược chưa hoàn thành có thể được khấu trừ từ cược hợp lệ trên trò chơi / quyền chọn nhị phân. Ví dụ: nếu vòng cược chưa hoàn thành là 200USDT và đặt cược hợp lệ 10USDT được tạo ra trong trò chơi, sẽ còn lại 190USDT trong vòng cược chưa hoàn thành.",
    "chargeRecord": "Lịch sử nạp",
    "withdrawRecord": "Lịch sử rút"
  },
  "version22": {
    "verificationCode": "Mã xác mình hình ảnh",
    "userNameErr": "Định dạng tên người dùng không chính xác, vui lòng nhập kết hợp 6-32 chữ cái và@ hoặc chữ và số.",
    "tip": "Mẹo",
    "because": "lý do",
    "no": "Số",
    "auditError": "Xét duyệt thất bại",
    "auditSuccess": "Xét duyệt thành công",
    "fundPassword": "Định dạng mật khẩu quỹ không chính xác, vui lòng nhập số có 6 chữ số",
    "allEntrust": "Uỷ thác toàn bộ",
    "dealAmount": "Số tiền giao dịch thành công",
    "watcherTeamForm": "Xem báo cáo nhóm",
    "overview": "Tổng quan",
    "stopBet": "Kỳ này đã đóng,ngưng đặt cược",
    "balance": "Số dư khả dụng",
    "exchangeOrderMinVolume": "Số lượng uỷ thác tối thiểu là ",
    "exchangeOrderMaxVolume": "Số lượng uỷ thác tối đa là ",
    "BLK5MC": "Đoán khối 5 phút (liên kết)",
    "RG5MC": "Xanh đỏ 5 phút (liên kết)",
    "DS5MC": "Xóc dĩa 5 phút (liên kết)",
    "forbidTrade": "Module giao dịch này đã bị cấm giao dịch",
    "minTurnoverNum": "Giao dịch thành công tối thiểu là ",
    "minSwapOpenNum": "Số trang tối thiểu là x trang",
    "maxSwapOpenNum": "Số trang tối đa lần này là x trang",
    "activity": "Hoạt động",
    "profitRecord": "Thành tích",
    "service": "Service",
    "help": "Trợ giúp",
    "totalAssets": "Tổng tài sản",
    "readAllLetterTip": "Bạn có chắc chắn muốn đọc tất cả tin nhắn không?",
    "myTeam": "Nhóm của tôi",
    "lv1": "Cấp 1",
    "lv2": "Cấp 2",
    "lv3": "Cấp 3",
    "lvAll": "Cấp 123",
    "calDataTime": "Thống kê thời gian",
    "dataUpdateTip": "Thời gian cập nhật dữ liệu: {date}",
    "total": "Tổng số lượng phần",
    "lastTopUserName": "Tài khoản cấp trên",
    "humanAdd": "Tăng thủ công",
    "humanSub": "Giảm thủ công",
    "realNameTime": "Thời gian xác minh tên thực",
    "chargeTime": "Thời gian nạp",
    "reSubmit": "Gửi lại",
    "goldMoney": "Số tiền trúng thưởng",
    "username": "Tài khoản",
    "myNum": "Số của tôi",
    "waitOpen": "Chờ mở thưởng",
    "buyAmount": "Số phần mua",
    "bugNum": "Số phần mua tưởng ứng",
    "gainCode": "Nhận mã đổi thưởng",
    "totalNumOpenReward": "Tổng số lượng phần bán hết sẽ mở thưởng",
    "winnerGainAllReward": "Người chiến thắng nhận được tất cả tiền thưởng",
    "teamLevel": "Cấp đội",
    "SD_serialGame": "Xóc dĩa",
    "RG_serialGame": "Xanh đỏ",
    "BLK_serialGame": "Đoán khối",
    "codeTip": "Mã QR địa chỉ nạp tiền",
    "relateTopUser": "Thuộc cấp trên",
    "lv1Num": "Số người cấp 1",
    "lv2Num": "Số người cấp 2",
    "lv3Num": "Số người cấp 3",
    "singleLevelTxt": " Cấp",
    "wrondPasswordCorrect": "Sai định dạng mật khẩu đăng nhập,vui lòng nhập kết hợp 8-32 chữ cái và số",
    "withdrawAccount": "Tài khoản rút tiền",
    "optionModeShutTip": "Chế độ {minute} phút hiện tại đang được bảo trì",
    "optionModuleShutTip": "Đang bảo trì",
    "gameClosed": "Trò chơi đã đóng",
    "doBet": "Cược",
    "reAlterBindCard": "Đơn vị tiền tệ fiat của bạn không phù hợp với đơn vị tiền tệ của thẻ ngân hàng, vui lòng cập nhật thông tin thẻ ngân hàng kịp thời",
    "t2": "Tổng giá trị thời gian (UTC + 0) của 50 bản mua cuối cùng của trò chơi trước khi bốc thăm",
    "emailErr": "Vui lòng nhập chính xác địa chỉ email ",
    "bigSymTip": "Hiển thị <Lớn>",
    "smallSymTip": "Hiển thị <nhỏ>",
    "EvenSymTip": "Hiển thị <Chẵn>",
    "OddSymTip": "Hiển thị <Lẻ>",
    "exchangeNum": "Số lượng người giao ngay",
    "yesterdayAward": "Trúng thưởng hôm qua",
    "yijixiaji": "Cấp dưới cấp 1",
    "erjixiaji": "Cấp dưới cấp 2",
    "sanjixiaji": "Cấp dưới cấp 3",
    "p3": "<ins class = 'paramTitle'>Cấp bậc nhóm</ins>Càng thăng cấp nhiều cấp dưới, cấp bậc nhóm càng cao và bạn có thể được hưởng khoản chiết khấu càng cao. Các cấp bậc nhóm của nhóm được chia thành LV1-LV6 và quy tắc nâng cấp được hiển thị trong bảng sau, trong đó N là số cấp dưới cấp một đã nạp tiền.",
    "p4": "<ins class='paramTitle'>Trò chơi, Tùy chọn nhị phân, second contracts, tỷ lệ hoàn trả thể thao</ins> Cấp dưới cược trò chơi, cược tùy chọn nhị phân, cượcsecond contracts, cược thể thao, bạn có thể nhận được tỷ lệ hoàn trả số tiền cá cược tương ứng.",
    "p5": "<ins class = 'paramTitle'>Tỷ lệ hoàn trả giao ngay và hợp đồng</ins>Đối với giao dịch giao ngay và giao dịch hợp đồng, bạn có thể nhận được khoản hoàn trả tương ứng với phí giao dịch. Nếu phí xử lý bằng đơn vị tiền tệ không phải USDT, khoản hoàn phí sẽ được chuyển đổi thành USDT theo tỷ giá hối đoái thời gian thực và được cấp vào tài khoản USDT của bạn.",
    "flag": "Đặc trưng",
    "noZero": "Số tiền chuyển không được bằng 0",
    "networkTip": "Lỗi mạng, vui lòng làm mới trang để tải lại",
    "inviteChargeTip": "Việc nâng cấp nhóm tùy thuộc vào số lượng cấp dưới nạp tiền thành công",
    "canWithdrawNum": "Số dư khả dụng",
    "withTip": "Vui lòng nhập số tiền rút",
    "withModalTip": "Phí xử lý bao gồm phí vận hành và phí khai thác.",
    "totalUpNum": "ổng số người",
    "chargeRangeTip": "Số tiền nạp phải là ",
    "jiaocheng": "Hướng dẫn",
    "spare1": "Dự trữ 1",
    "spare2": "Dự trữ 2",
    "loadAllGroupMsg": "Đã tải toàn bộ tin nhắn trong 7 ngày",
    "realMember": "Đầy đủ thành viên",
    "testMember": "Kiểm tra thành viên",
    "noBlankMsg": "Không thể gửi tin nhắn trống",
    "maxLength1500": "Không gửi được tin nhắn, nội dung quá dài",
    "sendToMore": "Gửi tin nhắn không thành công, yêu cầu quá thường xuyên",
    "countdown": "Đếm ngược",
    "toLongRemark": "Quá nhiều từ, vui lòng xóa",
    "tip1": "<p> * Dữ liệu tùy thuộc vào thời gian thống kê và dữ liệu được cập nhật hàng giờ. Thời gian cập nhật mới nhất là: {time}; trong đó, số người đã đăng ký / số người xác minh tên thực / số người nạp đầu/số tiền nạp đầu được cập nhật theo thời gian thực </p>",
    "tip2": "<p> * Số dư USDT không liên quan gì đến thời gian thống kê và luôn hiển thị tổng số dư hiện tại của nhóm </p>",
    "tip3": "* Số tiền gửi / rút / đặt cược / thắng / hoàn trả tùy thuộc vào thời gian thống kê và dữ liệu được cập nhật hàng giờ. Thời gian cập nhật mới nhất là: {time}",
    "tip4": "* Các dữ liệu khác không liên quan gì đến thời gian thống kê, luôn hiển thị dữ liệu mới nhất hiện tại",
    "wrongImgType": "định dạng tập tin không được hỗ trợ",
    "addNewNotice": "Thêm",
    "noChoose": "Tạm thời không chọn",
    "nullCoin": "Không",
    "thankyou": "Cảm ơn bạn đã tham gia",
    "oncemore": "Thêm 1 lần nữa",
    "tip5": "Tỷ giá  có thể thay đổi, dựa trên số tiền thực tế",
    "xiugaichenggong": "Sửa đổi thành công, vui lòng đăng nhập lại",
    "entrustNoZero": "Số tiền uỷ thác không được bằng 0",
    "herTeam": "Nhóm của bạn ấy",
    "betMoney": "Vui lòng nhập số tiền đặt cược",
    addFilterMember : 'Thêm tất cả thành viên sau khi lọc',
    delFilterMember : 'Xóa tất cả thành viên sau khi lọc',
    forbidFilterMember : 'Cấm phát ngôn tất cả các thành viên sau khi  lọc',
    freeFilterMember : 'Bỏ cấm phát ngôn tất cả các thành viên sau khi  lọc',
    theme1 : 'Chủ đề trò chơi 1',
    theme2 : 'Chủ đề trò chơi 2',
    theme3 : 'Chủ đề phòng giao dịch 1',
    theme4 : 'Chủ đề phòng giao dịch 2',
    themeTitle : 'Chuyển đổi chủ đề',
    maxTradeMoney: 'Số tiền giao dịch thành công tối đa là '
  },
  "version23": {
    inviteCenter: "Trung tâm lời mời",
    search: 'Tìm kiếm',
    active: 'Thao tác',
    openDirect: 'Hướng mở thưởng',
    profitAndLossAmount: 'Số tiền lãi lỗ',
    levelMax: "Đã đạt cấp bậc cao nhất",
    next: 'Trang tiếp theo',
    prev: 'Trang trước',
    secondRecord : 'Lịch sử Seconds contract',
    entrusting : 'Đang ủy thác',
    secondReward : 'Trúng thưởng seconds contract',
    secondBet : 'Đặt cược seconds contract',
    secondProfit : 'Lãi lỗ seconds contract',
    RD100N : 'Lãi lỗ seconds contract',
    secondNum : 'Số người seconds contract',
    rebackTip : 'Mỗi tiếng sẽ hoàn trả 1 lần,xin vui lòng đợi',
    secondOption : 'Seconds Contract',
    qiquan : 'Tùy chọn',
    exportBtn : 'Xuất file',
    "SB1M": "Khinh khí 1 phút",
    "SB3M": "Khinh khí cầu 3 phút",
    "SB5M": "Khinh khí cầu 5 phút",
    "SBTM": "Khinh khí cầu 10 phút",
  },
  version24 : {
    goldBoom : 'Đạp mìn',
    'MS' : 'Quét mìn',
    spRecord : 'Lịch sử thể thao-Ponymuah',
    "QT1M": "Tài Xỉu 1 phút",
    "QT3M": "Tài Xỉu 3 phút",
    "QT5M": "Tài Xỉu 5 phút",
    "QTTM": "Tài Xỉu 10 phút",
    "KN1M": "KENO 1 phút",
    "KN3M": "KENO 3 phút",
    "KN5M": "KENO 5 phút",
    "KNTM": "KENO 10 phút",
    "electronRecord": "Lịch sử trò chơi-Evolution",
    "LOT1M": "LOTTO 1 phút",
    "LOT3M": "LOTTO 3 phút",
    "LOT5M": "LOTTO 5 phút",
    "LOTTM": "LOTTO 10 phút",
    'LOTW3' : 'LOTTO',

    'TWTL' : 'Daily cash',
    'TWTL1M' : '1 phút Daily cash',
    'TWTL5M' : '5 phút Daily cash',
    'TWTLTM' : '10 phút Daily cash',
    'TWTL1D' : 'Daily cash',

    "RLT1M": "Roulette 1 phút",
    "RLT3M": "Roulette 3 phút",
    "RLT5M": "Roulette 5 phút",
    "RLTTM": "Roulette 10 phút",
    "LK281M": "1 phút may mắn 28",
    "LK283M": "3 phút may mắn 28",
    "LK285M": "5 phút may mắn 28",
    "LK28TM": "10 phút may mắn 28",
    "liveRecord": "Lịch sử sòng bài-Ezugi",
    evoLiveRecord : 'Lịch sử sòng bài-Evolution',
    pbRecord : 'Lịch sử thể thao-PinnacleBet',
    LIMBO : 'LIMBO',
    HILO : 'HILO',
    'FSLOT' : 'Trái cây',
    'RULTI' : 'Bánh xe',
    'RULTI_2-075_0' : 'Đen',
    'RULTI_3-176_0' : 'Đỏ',
    'RULTI_5-4_0' : 'Xanh da',
    'RULTI_54_0' : 'Xanh',
	'RB' : 'Đen đỏ',
	'RB_2-142_0' : 'Đỏ',
	'RB_15_0' : 'Trắng',
	'RB_2-142_1' : 'Đen',
	
    'FST' : 'Xổ số 4 sao',
    'FST1M' : '1 phút xổ số 4 sao',
    'FST5M' : '5 phút xổ số 4 sao',
    'FSTTM' : '10 phút xổ số 4 sao',
    'FST1D' : '1 ngày xổ số 4 sao',

    'TS' : 'Xổ số 3 sao',
    'TS1M' : '1 phút xổ số 3 sao',
    'TS5M' : '5 phút xổ số 3 sao',
    'TSTM' : '10 phút xổ số 3 sao',
    'TS1D' : '1 ngày xổ số 3 sao',

    'TWLT1M' : '1 phút Lotto lớn',
    'TWLT5M' : '5 phút Lotto lớn',
    'TWLTTM' : '10 phút Lotto lớn',
    'TWLTW2' : 'Lotto lớn',
    'TWLT' : 'Lotto lớn',


    'SPLT1M' : '1 phút Siêu xổ số',
    'SPLT5M' : '5 phút Siêu xổ số',
    'SPLTTM' : '10 phút Siêu xổ số',
    'SPLTW2' : 'Siêu xổ số',

    'SPLT' : 'Siêu xổ số',
  },
  version25 : {
    sport : 'Thể thao-Ponymuah',
    live :'Sòng bài-Ezugi',
    electron : 'Trò chơi điện tử-Evolution',

    sport_index : 'Thể thao',
    live_index :'Sòng bài',
    electron_index : 'Trò chơi điện tử',

    PG : 'Trò chơi điện tử-PocketGame',
    PP : 'Trò chơi điện tử-PragmaticPlay',
    "sportsNum": "Số người thể thao",
    "sportsAward": "Trúng giải thể thao",
    "sportsBet": "Cược thể thao",
    "sportsProfit": "Lãi lỗ thể thao",

    electronNum : 'Số người trò chơi điện tử',
    electronAward : 'Trúng giải trò chơi điện tử',
    electronBet:'Cược trò chơi điện tử',
    electronProfit : 'Lãi lỗ trò chơi điện tử',

    liveNum : 'Số người sòng bài',
    liveAward : 'Trúng giải sòng bài',
    liveBet:'Cược sòng bài',
    liveProfit : 'Lãi lỗ sòng bài',
	
	Num : 'Số người trong',
    Award : 'Trúng thưởng',
    Bet:'Cược',
    Profit : 'Lãi lỗ',

    ourGame : 'Trò chơi gốc',
    "t1": "Lượng trò chơi lớn chỉ trong một trang",
    "t2": "Nền tảng trò chơi hàng đầu thế giới",
  },
  version26 : {
    liveTeamReport : 'Báo cáo nhóm thời gian thực',
    tip2 : `Chỉ có thể chọn thời gian trong vòng gần 24 giờ`
  },
  version27 : {
    PGrecord : 'Lịch sử trò chơi-PocketGame',
    PPRecord : 'Lịch sử trò chơi-PragmaticPlay',

  }
}