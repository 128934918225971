module.exports = {
  "nav": {
    "shouye": "首頁 ",
    "hanqing": "行情",
    "jiaoyi": "交易",
    "heyue": "合約",
    "wode": "我的"
  },
  "common": {
    "login": "登錄",
    "register": "註冊",
    "loginregister": "登錄/註冊",
    "logout": "退出登錄",
    "tip": "溫馨提示",
    "delete": "確定要刪除嗎？",
    "set": "設置",
    "update": "修改",
    "more": "更多",
    "loading": "加載中",
    "zanwu": "暫無數據",
    "quxiao": "取消",
    "ok": "確定",
    "noyanzhengma": "驗證碼不正確",
    "clearStorage": "清除緩存",
    "telErr": "手機號碼格式不正確",
    "T404": "系統正在更新中，即將恢復訪問"
  },
  "loginPage": {
    "mima": "密碼",
    "passwordTxt": "請輸入密碼"
  },
  "homePage": {
    "charge": "儲值",
    "pickup": "提款",
    "exchange": "現貨",
    "promotionaward": "返傭"
  },
  "pageTitle": {
    "gonggaoxiangqing": "公告",
    "wodezichang": "我的資產",
    "bangzhuxiangqing": "詳情",
    "fundpwd": "資金密碼",
    "yuyanshezhi": "語言",
    "huazhuang2": "劃轉",
    "renzheng": "實名認證",
    "chooseAlterPhoneType": "手機號",
    "chooseAlterEmailType": "郵箱",
    "letterList": "消息",
    "appDownload": "下載APP",
    "buy": "買入",
    "sell": "賣出",
    "cexiao": "撤單",
    "time": "時間",
    "type": "類型",
    "jiage": "價格",
    "weituoliang": "委託量",
    "changjiao": "已成交",
    "weituoe": "委託金額",
    "over": "已全部加載",
    "weituojia": "委託價",
    "weituoer": "委託總額",
    "quedingchedan": "是否確認撤單?",
    "chedaning": "撤單中",
    "jiaoyidui": "交易對",
    "fangxiang": "方向",
    "chongzhi": "重置",
    "xianjia": "限價",
    "shijia": "市價",
    "plan": "計畫",
    "quanbu": "全部",
    "xianjiaweituo": "限價委託",
    "yichexiao": "已撤單",
    "time1": "開始時間不能大於結束時間",
    "time2": "結束時間不能小於開始時間",
    "time3": "結束時間不能大於當前時間",
    "cedancg": "撤單成功",
    "cedansb": "撤單失敗",
    "mairujiage": "單價",
    "yongxu": "永續",
    "zhanghu": "帳戶總權益",
    "yugu": "預估強平價",
    "baozheng": "保證金率",
    "kaicang": "開倉",
    "baocang": "爆倉",
    "pincang": "平倉",
    "zhanghu2": "帳戶",
    "pingduo": "平多",
    "kaiduo": "開多",
    "pingkong": "平空",
    "kaigong": "開空",
    "cufajia": "觸發價",
    "zuiyou": "最優價格",
    "zhang": "張",
    "keyong": "可用",
    "kekaiduo": "可開多",
    "kekaikong": "可開空",
    "duocang": "多倉可平",
    "kongcang": "空倉可平",
    "kanzhang": "看漲",
    "kandie": "看跌",
    "shuliang": "數量",
    "chicang": "當前持倉",
    "dangqianweituo": "當前委託",
    "lishiweituo": "歷史委託",
    "shouyi": "收益",
    "shouyilv": "收益率",
    "gangganbeishu": "杠杆倍數",
    "chicangliang": "持倉量",
    "kepingliang": "可平量",
    "kaichangjun": "開倉均價",
    "zuixinjia": "最新價",
    "dangqianbaozheng": "當前保證金",
    "duotou": "多倉",
    "kongtou": "空倉",
    "baozhengjin": "保證金",
    "weituoshuliang": "委託數量",
    "jiesuanweik": "結算盈虧",
    "chengjiaojia": "成交價",
    "shouxufei": "手續費",
    "xiugaichenggong": "修改成功"
  },
  "views": {
    "wangjiPass": "忘記密碼？",
    "confirmLogOut": "確定要退出登錄嗎？",
    "personalCenter": "個人中心",
    "shezhi": "設置成功",
    "yuanyouxiang": "舊郵箱驗證碼",
    "newyouxiang": "新郵箱驗證碼",
    "fsyouxiang": "發送驗證碼",
    "xyouxiang": "新郵箱",
    "loginPass": "登錄密碼",
    "baocun": "保存",
    "fschenggong": "發送成功",
    "inputyouxiang": "請輸入新郵箱",
    "inputOld": "請輸入舊郵箱驗證碼",
    "inputNew": "請輸入新郵箱驗證碼",
    "inputPass": "請輸入登錄密碼",
    "OldPass": "舊登錄密碼",
    "inputOldPass": "請輸入舊登錄密碼",
    "NewPass": "新登錄密碼",
    "inputNewPass": "請輸入新登錄密碼",
    "queren": "確認密碼",
    "email": "郵箱驗證碼",
    "liangciPass": "兩次輸入的密碼不一致",
    "shuruCode": "請輸入驗證碼",
    "OldZJ": "舊資金密碼",
    "inputOldZJ": "請輸入舊資金密碼",
    "NewZJ": "新資金密碼",
    "inputNewZJ": "請輸入新資金密碼",
    "augeren": "個人資訊",
    "aushang": "上傳證件",
    "aushenghe": "審核",
    "name": "姓名",
    "inputName": "請輸入您的真實姓名",
    "huzhao": "身份證件號碼",
    "inputhuzhao": "請輸入身份證件號碼",
    "next": "下一步",
    "uphuzhao": "上傳證件照片",
    "shouhuzhao": "上傳手持證件照",
    "tijiao": "提交",
    "tip1": "1、證件必須在有效期內",
    "tip2": "2、個人簽名的內容包含手寫的{hrefArry}、本人簽字和當前日期",
    "tip3": "3、請確保照片和個人簽字的內容清晰可見",
    "shentijiao": "資訊已提交，等待審核中",
    "fanhui": "返回",
    "bangding": "綁定",
    "inputEmail": "請輸入郵箱",
    "inputPhone": "請輸入手機號",
    "kefu": "請聯繫客服"
  },
  "list": {
    "wufaxiugia": "您當前有持倉或委託中的訂單，無法修改倍數",
    "submittip1": "為了您的資金安全，請先進行實名認證",
    "submittip2": "為了您的資金安全，請先設置資金密碼",
    "shuruweituo": "請輸入委託價格",
    "shuruweituoliang": "請輸入委託量",
    "dayukekai": "當前委託量大於可開多數量",
    "dayuduocang": "當前委託量大於多倉可平數量",
    "dayukekaikong": "當前委託量大於可開空數量",
    "dayukongcang": "當前委託量大於空倉可平數量",
    "shuruchufa": "請輸入觸發價",
    "zhangfubang": "漲幅榜",
    "liang": "量",
    "safar": "Safari流覽器中點擊下方按鈕",
    "kuaisuanzhuang": "選擇【添加到主螢幕】",
    "noAccount": "沒有帳號?",
    "LoginReg": "立即註冊",
    "butigongfuwu": "您的IP或所在地區暫不提供服務",
    "renzheng1": "已認證",
    "renzheng2": "認證失敗",
    "renzheng3": "審核中",
    "renzheng4": "認證",
    "shenheing": "正在審核中，請耐心等待",
    "inputzaicimima": "請再次輸入密碼",
    "xuantian": "請輸入邀請碼（選填）",
    "yuedu": "我已閱讀並同意",
    "yonghuxieyi": "用戶協議",
    "hasAccount": "已有帳號?",
    "denglu": "立即登錄",
    "gouxuanxieyi": "請閱讀並勾選用戶協議",
    "inputzijinmima": "請輸入資金密碼",
    "yijianfankui": "意見回饋",
    "gao": "高",
    "di": "低",
    "shengdu": "深度",
    "chengjiao": "成交",
    "zixuan1": "已取消自選",
    "zixuan2": "取消自選失敗",
    "zixuan3": "添加自選成功",
    "zixuan4": "添加自選失敗",
    "jiaoyie": "交易總額",
    "chengjiaozonger": "成交總額",
    "chengjiaojun": "成交均價",
    "chengjiaoliang": "成交量",
    "bigmairu": "超出最大可交易數量",
    "weituochenggong": "委託成功",
    "weituoshibai": "委託失敗",
    "yue": "餘額",
    "bizhong": "幣種",
    "huazhuanshuliang": "劃轉數量",
    "inputhzsl": "請輸入劃轉數量",
    "bunengweifu": "劃轉數量不能為負數",
    "dayuhuazhuan": "當前劃轉數量大於帳戶可劃轉數量",
    "huazhuanchenggong": "劃轉成功",
    "huazhuangshibai": "劃轉失敗"
  },
  "user": {
    "guizeshuoming": "規則",
    "shenheweitongguo": "審核未通過",
    "shiming": "請完成實名認證",
    "fuzhichenggong": "複製成功",
    "frozen": "凍結",
    "zhanghuquanyi": "帳戶權益",
    "baozhenjin": "可用保證金",
    "jiaoyishijian": "交易時間"
  },
  "tradePage": {
    "CUSTOM": "自選"
  },
  "chart": {
    "minute": "分鐘"
  },
  "charge": {
    "congbi": "儲值地址",
    "baocun": "保存二維碼圖片"
  },
  "heyuePage": {
    "yijianpingcang": "一鍵平倉",
    "copyerr": "複製失敗",
    "pincang": "平倉張數不能為0"
  },
  "yaoqin": {
    "zuce": "註冊時間",
    "showPersonInfo": "查看您的個人資訊",
    "myinvitelink": "邀請鏈接",
    "my_column4": "級別",
    "yaoqing": "邀請",
    "dengji": "等級成長進度",
    "mubiao": "目標",
    "zong": "總返傭",
    "jiaoyi": "交易明細",
    "wode": "邀請碼",
    "huodong": "活動禮金"
  },
  "version3": {
    "hash": "哈希值",
    "betTip": "猜每10分鐘第一個ETH區塊的哈希值末位，0-7為小，8-f為大；0、2、4、6、8、a、c、e為雙，1、3、5、7、9、b、d、f為單。",
    "betTip2": "猜哈希值末位，0-7為小，8-f為大；0、2、4、6、8、a、c、e為雙，1、3、5、7、9、b、d、f為單。",
    "betMoneyTip": "單注金額",
    "doBet": "立即投注",
    "issue": "No.",
    "blockHigh": "區塊高度",
    "blockTime": "出塊時間",
    "hash2": "哈希值",
    "gameRecord": "遊戲記錄",
    "betTime": "下單時間",
    "pickUpAddress": "提款地址",
    "arriveAccountNum": "到賬數量",
    "remark": "備註",
    "contachService": "聯繫客服",
    "totalBetMoney": "下單總額",
    "unOpen": "未開獎",
    "maintain": "維護中",
    "hasBingGo": "已中獎",
    "unBingGo": "未中獎",
    "outOfBalance": "餘額不足",
    "chooseBetNumTip": "請選擇投注選項",
    "chooseBetMoney": "請輸入單注金額",
    "minBetAmount": "單注最小金額為",
    "maxBetAmount": "單注最大金額為",
    "chooseWithDrawAddressTip": "請選擇地址",
    "inputRemarkTip": "請輸入提款地址",
    "confirmOrder": "確認訂單",
    "manji": "VIP等級已滿",
    "selWithAddress": "請選擇提款地址",
    "withNumTip": "提款數量應為",
    "unLiuShui": "未完成流水",
    "withdrawChannel": "提款通道"
  },
  "version4": {
    "game": "遊戲",
    "baocangIntro": "在{date}，您的{account}合約帳戶總權益為 0 USDT，保證金率≤0，觸發強制平倉，也就是爆倉。",
    "geshi": "請輸入正確的金額格式",
    "mingc": "成交量",
    "zhangd": "漲跌",
    "quanqiu": "全球運營",
    "yiliu": "一流團隊",
    "yinhang": "銀行級加密"
  },
  "version5": {
    "rechargeWay": "儲值通道",
    "inputChargeNum": "請輸入儲值金額",
    "receivePeople": "收款人",
    "bankCard": "銀行卡",
    "bank": "銀行",
    "bankNumber": "銀行帳號",
    "bankProvince": "開戶省份",
    "bankCity": "開戶城市",
    "bankBrancher": "開戶支行",
    "rengong": "人工充提"
  },
  "version7": {
    "p6": "請先綁定銀行卡",
    "jinxingzhong": "交易中",
    "yiwancheng": "已完成",
    "shibai": "交易失敗",
    "nichen": "用戶名",
    "p8": "單筆限額",
    "username": "請輸入用戶名"
  },
  "version8": {
    "noArrive0": "到賬金額不能為0",
    "xuantian": "請輸入邀請碼",
    "result": "結果",
    "color": "顏色",
    "hlRule": "猜0-9的數值，1、3、5、7、9為綠色，0、2、4、6、8為紅色，0或5同時也為紫色。"
  },
  "Transfer": {
    "FriendsReport": "團隊報表",
    "jintian": "今天",
    "zuotian": "昨天",
    "benzhou": "本周",
    "shangzhou": "上周",
    "benyue": "本月",
    "shangyue": "上月",
    "zuce": "註冊人數",
    "shoucong": "首充人數",
    "chongzhi": "儲值人數",
    "tixian": "提款人數",
    "heyue": "合約人數",
    "youxi": "遊戲人數",
    "touzu": "遊戲投注",
    "zhongjiang": "遊戲中獎",
    "shimingrenshu": "實名人數",
    "status_1": "審核中",
    "status_3": "失敗",
    "status_4": "成功",
    "jilu": "記錄"
  },
  "version9": {
    "SD1MRule": "猜色碟的紅白數量，相同顏色的色碟有偶數個為雙，相同顏色的色碟有奇數個為單。",
    "FourRed": "4紅0白",
    "ThreeRedOneWhite": "3紅1白",
    "FourWhite": "0紅4白",
    "OneRedThreeWhite": "1紅3白"
  },
  "version10": {
    "titMode3": "提款時，需支付礦費以確保鏈上交易得到處理，當前礦費為 {minTxFee} USDT。",
    "onGame": "進行中",
    "tuihui": "已退回",
    "hasJump": "已跳傘",
    "player": "玩家",
    "money": "金額",
    "jump": "跳傘",
    "jumpMulti": "跳傘倍數",
    "betMoney": "下單金額",
    "autoJumpTip": "自動跳傘倍數（選填）",
    "p1": "最小跳傘倍數應大於",
    "p2": "最大跳傘倍數為",
    "autoJump": "自動跳傘",
    "boomValue": "爆點倍數",
    "crash": "墜毀",
    "noRepeatOrder": "請勿重複下注",
    "jumpSuccess": "跳傘成功",
    "tip1_plan": "1、自動跳傘倍數為選填項，填寫後，飛機到達對應倍數後您將自動跳傘，也可在飛機墜毀前進行手動跳傘；若未填寫，仍可以在飛機墜毀前進行手動跳傘",
    "tip2_plan": "2、我們會從已跳傘玩家的盈利金額中抽水{amount}%",
    "dizhi": "請輸入正確的地址"
  },
  "version11": {
    "trend": "走勢",
    "goldenBroad": "中獎播報",
    "broadTxt": "恭喜 {userName} 喜中 <span class='Tcolor'>{winMoney}</span> USDT",
    "TS": "跳傘",
    "CQK1M": "1分區塊",
    "CQK5M": "5分區塊",
    "CQK": "10分區塊（鏈上）",
    "HL": "1分紅綠",
    "HL5M": "5分紅綠",
	'RG30S' : '30秒紅綠',
    "SD1M": "1分色碟",
    "SD5M": "5分色碟",
    "Gogame": "進入遊戲",
    "yinli": "盈利金額",
    "munber": "盈利金額=交易盈虧+活動禮金+返傭",
    "Gyinkui": "遊戲盈虧",
    "Hyinkui": "合約盈虧",
    "Hlijin": "活動禮金",
    "Yfanyong": "返傭"
  },
  "version12": {
    "hl10mRule": "猜每10分鐘內第一個ETH的哈希值從末位往前數的第一個0-9的數值，1、3、5、7、9為綠色，0、2、4、6、8為紅色，0或5同時也為紫色。",
    "sd10mRule": "猜每10分鐘內第一個ETH區塊的哈希值末四位，每一位對應一個色碟，0-7為白，8-f為紅，相同顏色的色碟有偶數個為雙，相同顏色的色碟有奇數個為單。",
    "SD10M": "10分色碟（鏈上）",
    "HL10M": "10分紅綠（鏈上）",
    "refresh": "刷新"
  },
  "version13": {
    "kaijiangzhong": "開獎中",
    "canyu": "我的",
    "kaijiang": "已開獎",
    "join": "參與",
    "buyAmount": "請輸入購買份數",
    "minBetAmount": "最小購買份數為",
    "maxBetAmount": "最大購買份數為",
    "tipMax": "請勿超過最大份數",
    "totalVal": "總價值",
    "jindu": "進度",
    "leave": "剩餘",
    "goldNumber": "中獎號碼",
    "myGoldNumber": "我的號碼",
    "val": "數值",
    "mode": "數值",
    "unJoin": "未參與",
    "calNumber": "計算中",
    "eachPrice": "每份價格",
    "currentIssue": "本期參與記錄",
    "hisIssue": "開獎前50個參與記錄",
    "showDetail": "查看",
    "buy": "購買",
    "buyTime": "購買時間",
    "luckNumber": "兌獎號碼",
    "totalBuyAmount": "購買總額",
    "buyTip2": "請輸入正確的份數格式",
    "goldMember": "獲獎會員",
    "openTime": "開獎時間",
    "Wtime": "預計維護時間",
    "invitationNote": "邀請說明",
    "p1": "<ins class='paramTitle'>邀請好友</ins>進入“邀請中心”，複製邀請鏈接或邀請碼，分享給您的好友。好友通過您的邀請碼註冊就可以成為您的下級。",
    "p2": "<ins class='paramTitle'>賺取返傭</ins>下級進行交易的時候，您可以獲得相應的返傭，最多支持三級下級。 舉個例子，您邀請了好友A，A邀請了B，B邀請了C，則A、B、C在平臺內進行遊戲、合約等交易，您都可以獲得相應的返傭。",
    "fuk": "請付款",
    "guoq": "已過期",
    "exchtishi": "請使用本人實名銀行卡向以上帳號轉賬",
    "exchtishi2": "在轉賬過程中請勿備註BTC、USDT等資訊，防止匯款被攔截等問題",
    "quxiaoOrder": "取消訂單",
    "chengong": "我已付款成功",
    "fukuanren": "付款姓名",
    "fukuanrenTi": "請填寫付款姓名",
    "zhanghao": "付款帳號",
    "zhanghaoTi": "請填寫付款帳號",
    "yihang": "付款銀行",
    "yihangTi": "請填寫付款銀行",
    "jietu": "付款截圖",
    "jietuTi": "請上傳付款截圖",
    "queren": "請確認您已付款",
    "queren2": "惡意確認將直接凍結帳戶",
    "YYYG": "幸運1U",
    "dragon": "排行榜",
    "paimng": "排名"
  },
  "version15": {
    "only60Days": "僅保留60天內的記錄",
    "only100Records": "僅展示最近100條記錄",
    "status": "狀態",
    "luckyWheel": "幸運抽獎",
    "option1": "獎金",
    "option2": "謝謝參與",
    "option3": "再來一次",
    "freeTimes": "抽獎次數",
    "p1": "註冊成功後即可獲得 {registerGiveCount} 次抽獎機會。",
    "p2": "昨日（以UTC+0時區為准）儲值金額達到 {todayRechargeCondition} USDT，可獲得 {rechargeGiveCount} 次抽獎機會。",
    "p3": "昨日（以UTC+0時區為准）有效投注金額（不包含現貨、合約）達到 {todayBettingCondition} USDT，可獲得 {bettingGiveCount} 次抽獎機會。",
    "continuDraw": "繼續抽獎",
    "noFreeTimeTip": "您的抽獎次數已用完"
  },
  "version16": {
    "totalPerMoney": "下單總額",
    "notFu": "下單金額不能為負數",
    "find": "發現",
    "channel": "頻道"
  },
  "version17": {
    "drawWarmTip": "贈送的抽獎次數，可能需要十分鐘到半小時才能確認，請耐心等候",
    "level": "等級",
    "upLimit": "升級門檻",
    "chatRoom": "聊天",
    "group": "群組",
    "privateChat": "私聊",
    "groupManager": "管理員",
    "groupMember": "群成員",
    "groupSetting": "群設置",
    "totalChargeMoney": "累計儲值",
    "vip9Tip": "VIP等級已滿",
    "dataTime": "數據時間",
    "sortType": "排序方式",
    "loginTime": "登錄時間",
    "lowerPeople": "下級人數",
    "chargeNum": "儲值金額",
    "withdrawNum": "提款金額",
    "lowerReport": "團隊報表",
    "lowerUserBetNum": "下級投注",
    "lowerUserAwardNum": "下級中獎",
    "lowerUserChargeNum": "下級儲值",
    "lowerUserWithdrawNum": "下級提款",
    "firstRechargeNum": "首充金額",
    "warmTip": "默認顯示今天數據",
    "publishNotice": "發佈",
    "editNotice": "編輯公告",
    "noticeTime": "公告時間",
    "inputNoticeTip": "請輸入公告內容",
    "tip": "全員禁言啟用後，只允許群管理員發言",
    "allNotSpeak": "全員禁言",
    "canSpeakLevel": "發言等級",
    "eryuanqiquan": "二元期權",
    "optionHelpText": "預測下一時間段的漲跌。每期開始時的價格為起始價，每期結束時的價格為結算價，結算價大於起始價則為漲，結算價小於起始價則為跌。",
    "optionRecord": "期權記錄",
    "calcuteDirection": "預測方向",
    "startPrice": "起始價",
    "endPrice": "結算價",
    "equal": "無漲跌",
    "waitResult": "待結算",
    "up": "漲",
    "down": "跌",
    "findPassword": "找回密碼",
    "calTime": "預測時間",
    "calWeek": "預測週期",
    "kanzhang": "看漲",
    "kandie": "看跌"
  },
  "version18": {
    "issue": "期號",
    "vipSort": "VIP等級",
    "jinyan": "已禁言",
    "weijinyan": "未禁言",
    "confirmDelete": "確定要刪除成員嗎？",
    "confirmUnSpeak": "確定要禁言成員嗎？",
    "confirmSpeak": "確定要解禁成員嗎？",
    "confirmAdd": "確定要添加成員嗎？",
    "addMember": "添加成員",
    "delMember": "刪除成員",
    "lockSpeak": "禁言",
    "unLockSpeak": "解禁",
    "lockSpeakSelMember": "禁言成員",
    "unLockSpeakSelMember": "解禁成員",
    "noSelMemberTip": "請選擇會員",
    "recentlyPlayGame": "最近常玩",
    "sendMsg": "發消息",
    "addGroupMember": "添加成員",
    "picTip": "[圖片]",
    "time3": "1星期前",
    "chooseAll": "反選",
    "personalPage": "個人主頁",
    "CQK3M": "3分區塊",
    "HL3M": "3分紅綠",
    "SD3M": "3分色碟"
  },
  "version19": {
    "avatar": "頭像",
    "share": "分享",
    "todayProfit": "今日盈利",
    "followBet": "跟投",
    "Jump": "前往",
    "over": "已結束",
    "optionNumber": "期權人數",
    "optionBet": "期權投注",
    "optionReward": "期權中獎",
    "optionProfit": "期權盈虧",
    "RG_2_0": "紅",
    "RG_5_1": "紫",
    "RG_2_1": "綠",
    "DS_16_0": "1111",
    "DS_16_1": "0000",
    "DS_4_0": "1110",
    "DS_4_1": "1000",
    "DS_2_0": "雙",
    "DS_2_1": "單",
    "BLK_2_0": "大",
    "BLK_2_1": "小",
    "BLK_2_2": "單",
    "BLK_2_3": "雙",
    "BLK_4_0": "大單",
    "BLK_4_1": "大雙",
    "BLK_4_2": "小單",
    "BLK_4_3": "小雙"
  },
  "version20": {
    "server": "客服",
    "teacher": "導師",
    "financer": "財務",
    "planer": "計畫員",
    "chatPop": "聊天浮窗",
    "cancelText": "取消浮窗",
    "betNumber": "投注選項",
    "resendMsg": "消息發送失敗，是否重發消息？",
    "delThisMsg": "刪除此條消息",
    "delThisMemberAllMsg": "刪除此成員全部消息",
    "forbitThisMember": "禁言成員",
    "delThisMember": "刪除成員",
    "delTipModal": "請選擇要操作的功能"
  },
  "version21": {
    "USDT_account": "USDT帳戶",
    "closeSwapTip": "請選擇平倉比例",
    "hasRecharge": "已儲值",
    "numberCoin": "數字貨幣",
    "none": "-",
    "fastCharge": "快捷儲值",
    "bankTransfer": "銀行轉賬",
    "setLegalTip": "請選擇您使用的法定貨幣",
    "legalCoin": "法定貨幣",
    "orderCreateTime": "創建時間",
    "tradeOrderNumber": "交易單號",
    "completeTime": "完成時間",
    "fastWithdraw": "快捷提款",
    "bindCardTip1": "請填寫您有效身份證件上的完整姓名，提交後不可修改",
    "bindCardTip2": "請填寫您的銀行卡資訊"
  },
  "saas": {
    "fastChargeType": "快捷通道儲值",
    "numberCoinChargeType": "數字貨幣儲值",
    "humanChargeType": "人工儲值",
    "fastChannelWithdrawType": "快捷通道提款",
    "numberCoinWithdrawType": "數字貨幣提款",
    "humanWithdrawType": "人工提款",
    "fastChannelBackType": "快捷通道退回",
    "numberChannelBackType": "數字貨幣退回",
    "betType": "下注",
    "betProfitType": "盈利",
    "betEqualType": "平局",
    "gameBackRewardType": "遊戲返傭",
    "swapBackRewardType": "合約返傭",
    "exchangeBackRewardType": "現貨返傭",
    "tranInType": "轉入",
    "tranOutType": "轉出",
    "chargeRewardType": "儲值禮金",
    "firstChargeRewardType": "首充禮金",
    "regRewardType": "註冊禮金",
    "upGradeRewardType": "晉級禮金",
    "luckyRewardType": "幸運抽獎",
    "realNameRewardType": "實名禮金",
    "betRewardType": "投注禮金",
    "dailyFirstChargeRewardType": "每日首充禮金",
    "upScoreType": "人工增加",
    "downScoreType": "人工減少",
    "unRecharge": "未儲值",
    "canWithdrawBalance": "可提餘額",
    "withPopTip1": "提款時，存在未完成流水，不可提款。",
    "withPopTip2": "提款時，未完成流水的部分需支付{flowFee}%的流水手續費。",
    "alterBankCardNameTip": "如需修改姓名，請聯繫客服",
    "arriveMoney": "到賬金額",
    "withdrawNum": "提款數量",
    "failReason": "失敗原因",
    "tradeHash": "交易哈希",
    "waitCoin": "等待放款",
    "zicang": "資產",
    "otherMoney": "其他資產估算",
    "moneyList": "資產列表",
    "onlyShowSwap": "只看合約",
    "hideZeroMoney": "隱藏0資產",
    "chargeFlowTip1": "儲值到USDT帳戶的餘額，會增加{deposit}%的未完成流水。例如儲值1000USDT，則未完成流水增加{depositValue}USDT。",
    "chargeFlowTip2": "未完成流水可通過遊戲/二元期權的有效投注扣除。例如未完成流水為200USDT，在遊戲內產生了10USDT的有效投注，則未完成流水還剩190USDT。",
    "chargeRecord": "儲值記錄",
    "withdrawRecord": "提款記錄"
  },
  "version22": {
    "verificationCode": "圖形驗證碼",
    "userNameErr": "用戶名格式錯誤，請輸入可包含.和@的6-32位的字母或字母數字組合",
    "tip": "提示",
    "because": "原因",
    "no": "號碼",
    "auditError": "審核失敗",
    "auditSuccess": "審核成功",
    "fundPassword": "資金密碼格式錯誤，請輸入6位數字",
    "allEntrust": "全部委託",
    "dealAmount": "成交金額",
    "watcherTeamForm": "查看團隊報表",
    "overview": "總覽",
    "stopBet": "本期已封單，停止下注",
    "balance": "可用餘額",
    "exchangeOrderMinVolume": "最小委託量為",
    "exchangeOrderMaxVolume": "最大委託量為",
    "BLK5MC": "5分區塊（鏈上）",
    "RG5MC": "5分紅綠（鏈上）",
    "DS5MC": "5分色碟（鏈上）",
    "forbidTrade": "該交易模組已禁止交易",
    "minTurnoverNum": "最小成交額為",
    "minSwapOpenNum": "最小張數為x張",
    "maxSwapOpenNum": "本次的最大張數為x張",
    "activity": "活動",
    "profitRecord": "戰績",
    "service": "客服",
    "help": "幫助",
    "totalAssets": "總資產",
    "readAllLetterTip": "確定要將所有消息置為已讀嗎？",
    "myTeam": "我的團隊",
    "lv1": "1級",
    "lv2": "2級",
    "lv3": "3級",
    "lvAll": "123級",
    "calDataTime": "統計時間",
    "dataUpdateTip": "數據更新時間：{date}",
    "total": "總份數",
    "lastTopUserName": "上級用戶名",
    "humanAdd": "人工增加",
    "humanSub": "人工減少",
    "realNameTime": "實名時間",
    "chargeTime": "儲值時間",
    "reSubmit": "重新提交",
    "goldMoney": "中獎金額",
    "username": "用戶",
    "myNum": "我的號碼",
    "waitOpen": "等待開獎",
    "buyAmount": "購買份數",
    "bugNum": "購買相應份數",
    "gainCode": "獲得兌獎碼",
    "totalNumOpenReward": "總份數售完後開獎",
    "winnerGainAllReward": "中獎者獲得全部獎金",
    "teamLevel": "團隊等級",
    "SD_serialGame": "猜色碟",
    "RG_serialGame": "猜紅綠",
    "BLK_serialGame": "猜區塊",
    "codeTip": "儲值地址二維碼",
    "relateTopUser": "所屬上級",
    "lv1Num": "1級人數",
    "lv2Num": "2級人數",
    "lv3Num": "3級人數",
    "singleLevelTxt": "級",
    "wrondPasswordCorrect": "登錄密碼格式錯誤，請輸入8-32位的字母與數字的組合",
    "withdrawAccount": "提款帳戶",
    "optionModeShutTip": "當前{minute}分鐘模式處在維護中",
    "optionModuleShutTip": "維護中",
    "gameClosed": "遊戲已關閉",
    "doBet": "投注",
    "reAlterBindCard": "您的法定貨幣與銀行卡的幣種不一致，請及時更新銀行卡資訊",
    "t2": "截至開獎前該款遊戲最後50個購買記錄的時間（UTC+0）數值之和",
    "emailErr": "請輸入正確的郵箱地址",
    "bigSymTip": "表示<大>",
    "smallSymTip": "表示<小>",
    "EvenSymTip": "表示<雙>",
    "OddSymTip": "表示<單>",
    "exchangeNum": "現貨人數",
    "yesterdayAward": "昨日中獎",
    "yijixiaji": "1級下級",
    "erjixiaji": "2級下級",
    "sanjixiaji": "3級下級",
    "p3": "<ins class='paramTitle'>團隊等級</ins>推廣的一級下級越多，團隊等級越高，可享受的返傭越高。團隊等級分為LV1-LV6，升級規則見如下表格，其中N為已儲值的一級下級人數。",
    "p4": "<ins class='paramTitle'>遊戲、期權、秒合約、娛樂返傭比例</ins>下級進行遊戲投注、二元期權投注、秒合約投注、娛樂投注，您可獲得其投注額相應比例的返傭。",
    "p5": "<ins class='paramTitle'>現貨、合約返傭比例</ins>下級進行現貨交易、合約交易，您可獲得其交易手續費相應比例的返傭。若手續費為USDT外的其他幣種，則返傭將根據即時匯率換算為USDT發放到您的USDT帳戶中。",
    "flag": "特徵",
    "noZero": "劃轉數量不能為0",
    "networkTip": "網路錯誤，請刷新頁面重新加載",
    "inviteChargeTip": "團隊等級的升級，取決於儲值成功的一級下級人數",
    "canWithdrawNum": "可提餘額",
    "withTip": "請輸入提款數量",
    "withModalTip": "手續費包含流水手續費和礦費。",
    "totalUpNum": "總人數",
    "chargeRangeTip": "儲值金額應為",
    "jiaocheng": "教程",
    "spare1": "備用1",
    "spare2": "備用2",
    "loadAllGroupMsg": "已加載七天內全部消息",
    "realMember": "正式會員",
    "testMember": "測試會員",
    "noBlankMsg": "不能發送空白消息",
    "maxLength1500": "消息發送失敗，內容過長",
    "sendToMore": "消息發送失敗，請求過於頻繁",
    "countdown": "倒計時",
    "toLongRemark": "字數過多，請刪減",
    "tip1": "<p>* 數據以統計時間為准，每小時更新一次數據，最近更新時間為：{time}；其中，註冊人數/實名人數/首充人數/首充金額是即時更新的</p>",
    "tip2": "<p>* USDT餘額與統計時間無關，總是顯示團隊當前總餘額</p>",
    "tip3": "* 儲值/提款/投注/中獎/返傭金額以統計時間為准，每小時更新一次數據，最近更新時間為：{time}",
    "tip4": "* 其他數據與統計時間無關，總是顯示當前最新數據",
    "wrongImgType": "不支持的檔格式",
    "addNewNotice": "新增",
    "noChoose": "暫不選擇",
    "nullCoin": "無",
    "thankyou": "謝謝參與",
    "oncemore": "再來一次",
    "tip5": "匯率可能變動，以實際金額為准",
    "xiugaichenggong": "修改成功，請重新登錄",
    "entrustNoZero": "委託量不能為0",
    "herTeam": "他的團隊",
    "betMoney": "請輸入下單金額",
    addFilterMember : '添加篩選後全部成員',
    delFilterMember : '刪除篩選後全部成員',
    forbidFilterMember : '禁言篩選後全部成員',
    freeFilterMember : '解禁篩選後全部成員',
    theme1 : '遊戲主題1',
    theme2 : '遊戲主題2',
    theme3 : '交易所主題1',
    theme4 : '交易所主題2',
    themeTitle : '主題',
    maxTradeMoney: '最大成交額為'
  },
  "version23": {
    inviteCenter: "邀請中心",
    search: '搜索',
    active: '操作',
    openDirect: '開獎方向',
    profitAndLossAmount: '盈虧金額',
    levelMax: "已達到最高等級",
    next: '下一頁',
    prev: '上一頁',
    secondRecord : '秒合約記錄',
    entrusting : '委託中',
    secondReward : '秒合約中獎',
    secondBet : '秒合約投注',
    secondProfit : '秒合約盈虧',
    RD100N : '隨機100',
    secondNum : '秒合約人數',
    rebackTip : '返傭每小時發放一次，請耐心等待',
    secondOption : '秒合約',
    qiquan : '期權',
    exportBtn : '導出',
    SB1M : '1分飛艇',
    SB3M : '3分飛艇',
    SB5M : '5分飛艇',
    SBTM : '10分飛艇',
  },
  version24 : {
    goldBoom : '踩雷',
    'MS' : '掃雷',
    spRecord : '體育記錄-Ponymuah',
    'QT1M' : '1分骰寶',
    'QT3M' : '3分骰寶',
    'QT5M' : '5分骰寶',
    'QTTM' : '10分骰寶',
    'KN1M' : '1分KENO',
    'KN3M' : '3分KENO',
    'KN5M' : '5分KENO',
    'KNTM' : '10分KENO',
    electronRecord : '電遊記錄-Evolution',
    'RLT1M' : '1分輪盤',
    'RLT3M' : '3分輪盤',
    'RLT5M' : '5分輪盤',
    'RLTTM' : '10分輪盤',
    'LOT1M' : '1分LOTTO',
    'LOT3M' : '3分LOTTO',
    'LOT5M' : '5分LOTTO',
    'LOTTM' : '10分LOTTO',
    'LOTW3' : 'LOTTO',

    'LK281M' : '1分幸運28',
    'LK283M' : '3分幸運28',
    'LK285M' : '5分幸運28',
    'LK28TM' : '10分幸運28',
    liveRecord : '真人記錄-Ezugi',
    evoLiveRecord : '真人記錄-Evolution',
    pbRecord : '體育記錄-PinnacleBet',
    LIMBO : 'LIMBO',
    HILO : 'HILO',
    'FSLOT' : '水果',
    'RULTI' : '轉盤',
    'RULTI_2-075_0' : '黑',
    'RULTI_3-176_0' : '紅',
    'RULTI_5-4_0' : '藍',
    'RULTI_54_0' : '綠',
    'RB' : '紅黑',
    'RB_2-142_0' : '紅',
    'RB_15_0' : '白',
    'RB_2-142_1' : '黑',
	
	'FST' : '四星彩',
    'FST1M' : '1分四星彩',
    'FST5M' : '5分四星彩',
    'FSTTM' : '10分四星彩',
    'FST1D' : '1天四星彩',

    'TS' : '三星彩',
    'TS1M' : '1分三星彩',
    'TS5M' : '5分三星彩',
    'TSTM' : '10分三星彩',
    'TS1D' : '1天三星彩',

    'TWTL' : '今彩539',
    'TWTL1M' : '1分今彩539',
    'TWTL5M' : '5分今彩539',
    'TWTLTM' : '10分今彩539',
    'TWTL1D' : '1天今彩539',
	
    'TWLT1M' : '1分大樂透',
    'TWLT5M' : '5分大樂透',
    'TWLTTM' : '10分大樂透',
    'TWLTW2' : '大樂透',
    'TWLT' : '大樂透',


    'SPLT1M' : '1分威力彩',
    'SPLT5M' : '5分威力彩',
    'SPLTTM' : '10分威力彩',
    'SPLTW2' : '威力彩',

    'SPLT' : '威力彩',

  },
  version25 : {
    sport : '體育-Ponymuah',
    live :'真人-Ezugi',
    electron : '電遊-Evolution',

    sport_index : '體育',
    live_index :'真人',
    electron_index : '電遊',

    PG : '電遊-PocketGame',
    PP : '電遊-PragmaticPlay',
    sportsNum : '體育人數',
    sportsAward : '體育中獎',
    sportsBet:'體育投注',
    sportsProfit : '體育盈虧',

    electronNum : '電遊人數',
    electronAward : '電遊中獎',
    electronBet:'電遊投注',
    electronProfit : '電遊盈虧',

    liveNum : '真人人數',
    liveAward : '真人中獎',
    liveBet:'真人投注',
    liveProfit : '真人盈虧',
		
	Num : '人數',
    Award : '獎金',
    Bet:'投注',
    Profit : '盈虧',

    ourGame : '原創遊戲',
    t1 : '海量遊戲 一站暢玩',
    t2 : '全球領先的遊戲平臺'
  },
  version26 : {
    liveTeamReport : '即時團隊報表',
    tip2 : `只能選擇近24小時內的時間`
  },
  version27 : {
    PGrecord : '電遊記錄-PocketGame',
    PPRecord : '電遊記錄-PragmaticPlay',

  }
}