module.exports = {
  "nav": {
    "shouye": "ホーム",
    "hanqing": "相場",
    "jiaoyi": "取引",
    "heyue": "スワップ",
    "wode": "自分"
  },
  "common": {
    "login": "ログイン",
    "register": "登録",
    "loginregister": "ログイン／登録",
    "logout": "ログアウト",
    "tip": "ご注意",
    "delete": "削除しますか",
    "set": "設定",
    "update": "修正",
    "more": "もっと",
    "loading": "読込み中",
    "zanwu": "データ無し",
    "quxiao": "キャンセル",
    "ok": "確定",
    "noyanzhengma": "CAPTCHAコードエラー",
    "clearStorage": "キャッシュクリア",
    "telErr": "スマホフォーマットエアー",
    "T404": "システムは更新中で、間もなくアクセスは再開する"
  },
  "loginPage": {
    "mima": "暗号",
    "passwordTxt": "暗号を入力"
  },
  "homePage": {
    "charge": "チャージ",
    "pickup": "換金",
    "exchange": "スポット",
    "promotionaward": "リベート"
  },
  "pageTitle": {
    "gonggaoxiangqing": "公告",
    "wodezichang": "私の資産",
    "bangzhuxiangqing": "詳細",
    "fundpwd": "資金暗号",
    "yuyanshezhi": "言語",
    "huazhuang2": "振替",
    "renzheng": "実名認証",
    "chooseAlterPhoneType": "スマホ番号",
    "chooseAlterEmailType": "メール",
    "letterList": "メッセージ",
    "appDownload": "アプリダウンロード",
    "buy": "購入",
    "sell": "売却",
    "cexiao": "撤去",
    "time": "タイム",
    "type": "タイプ",
    "jiage": "価格",
    "weituoliang": "委託量",
    "changjiao": "成約済み",
    "weituoe": "委託金額",
    "over": "すべて読込済み",
    "weituojia": "委託価格",
    "weituoer": "委託総額",
    "quedingchedan": "委託をキャンセルしますか",
    "chedaning": "委託をキャンセル中",
    "jiaoyidui": "取引ペア",
    "fangxiang": "方向",
    "chongzhi": "リセット",
    "xianjia": "指値",
    "shijia": "時価",
    "plan": "トリガー価格",
    "quanbu": "すべて",
    "xianjiaweituo": "指値注文",
    "yichexiao": "委託をキャンセル",
    "time1": "開始時間は終了時間より大きくてはいけない",
    "time2": "終了時間は開始時間より小さくてはいけない",
    "time3": "終了時間は今の時間より大きくてはいけない",
    "cedancg": "委託キャンセル成功",
    "cedansb": "委託キャンセル失敗",
    "mairujiage": "単価",
    "yongxu": "SWAP",
    "zhanghu": "勘定総権益",
    "yugu": "強制的に閉じる価格予測",
    "baozheng": "保証金率",
    "kaicang": "開く",
    "baocang": "ロスカット",
    "pincang": "閉じる",
    "zhanghu2": "勘定",
    "pingduo": "ロングを売却",
    "kaiduo": "ロングを購入",
    "pingkong": "ショットを売却",
    "kaigong": "ショットを購入",
    "cufajia": "トリガー価格",
    "zuiyou": "ベスト価格",
    "zhang": "枚",
    "keyong": "使用可能",
    "kekaiduo": "Max. L",
    "kekaikong": "Max. S",
    "duocang": "Max (L)",
    "kongcang": "Max (S)",
    "kanzhang": "先高",
    "kandie": "先安",
    "shuliang": "数量",
    "chicang": "今の所有ポジション",
    "dangqianweituo": "今の委託",
    "lishiweituo": "履歴委託",
    "shouyi": "収益",
    "shouyilv": "収益率",
    "gangganbeishu": "レバレッジ倍数",
    "chicangliang": "ポジション量",
    "kepingliang": "売却可能量",
    "kaichangjun": "オープンの平均金額",
    "zuixinjia": "最新価格",
    "dangqianbaozheng": "今の保証金",
    "duotou": "ロング",
    "kongtou": "ショート",
    "baozhengjin": "保証金",
    "weituoshuliang": "委託数量",
    "jiesuanweik": "損益決済",
    "chengjiaojia": "成約価格",
    "shouxufei": "手数料",
    "xiugaichenggong": "修正成功"
  },
  "views": {
    "wangjiPass": "暗号をお忘れですか？",
    "confirmLogOut": "ログアウトをしますか？",
    "personalCenter": "パーソナルセンター",
    "shezhi": "設定成功",
    "yuanyouxiang": "元メールの認証コード",
    "newyouxiang": "新しいメールの検証コード",
    "fsyouxiang": "認証コード送信",
    "xyouxiang": "新しいメール",
    "loginPass": "ログイン暗号",
    "baocun": "保存",
    "fschenggong": "送信成功",
    "inputyouxiang": "新しいメールを入力",
    "inputOld": "元メールの認証コードを入力",
    "inputNew": "新しいメールの認証コードを入力",
    "inputPass": "ロングイン暗号を入力",
    "OldPass": "旧ログイン暗号",
    "inputOldPass": "旧ログイン暗号を入力",
    "NewPass": "新しいログイン暗号",
    "inputNewPass": "新しいログイン暗号を入力",
    "queren": "暗号確認",
    "email": "メールの認証コード",
    "liangciPass": "２回に入力した暗号は合わない",
    "shuruCode": "認証コードを入力",
    "OldZJ": "旧資金暗号",
    "inputOldZJ": "旧資金暗号を入力",
    "NewZJ": "新しい資金暗号",
    "inputNewZJ": "新しい資金暗号を入力",
    "augeren": "個人情報",
    "aushang": "書類をアップロード",
    "aushenghe": "審査",
    "name": "名前",
    "inputName": "真実な名前を入力",
    "huzhao": "ID番号",
    "inputhuzhao": "ID番号を入力",
    "next": "次へ",
    "uphuzhao": "ID写真をアップロード",
    "shouhuzhao": "手持ちID写真をアップロード",
    "tijiao": "コミット",
    "tip1": "1、書類は有効期間内に必須",
    "tip2": "2、個人サインの内容は手書きの{hrefArry}、本人サインと今の日付を含む",
    "tip3": "3、写真と個人サインの内容ははっきり見えるのを確保してください",
    "shentijiao": "情報はコミットした、審査をお待ちください",
    "fanhui": "戻す",
    "bangding": "バインド",
    "inputEmail": "メールを入力",
    "inputPhone": "スマホを入力",
    "kefu": "顧客サービスに連絡してください"
  },
  "list": {
    "wufaxiugia": "今は所有しているポジションと委託している注文があるので、倍数を修正できない",
    "submittip1": "資金安全のために、まず実名を認証してください",
    "submittip2": "資金安全のために、まず資金案暗号を設定してください",
    "shuruweituo": "委託価格を入力",
    "shuruweituoliang": "委託量を入力",
    "dayukekai": "今の委託量はロング購入可能の数量より大きい",
    "dayuduocang": "今の委託量はロング売却可能の数量より大きい",
    "dayukekaikong": "今の委託量はショット購入可能の数量より大きい",
    "dayukongcang": "今の委託量はショット売却可能の数量より大きい",
    "shuruchufa": "トリガー価格を入力",
    "zhangfubang": "上昇幅ランキング",
    "liang": "　量",
    "safar": "Safariブラウザに下のボタンをクリック",
    "kuaisuanzhuang": "【ホーム画面に追加】を選択",
    "noAccount": "アカウントはありますか？",
    "LoginReg": "すぐに登録する",
    "butigongfuwu": "あなたのIP或いは所在地域に暫くサービスを提供しない",
    "renzheng1": "認証済み",
    "renzheng2": "認証失敗",
    "renzheng3": "審査中",
    "renzheng4": "認証",
    "shenheing": "審査中で、お待ちください",
    "inputzaicimima": "再度暗号を入力",
    "xuantian": "招待コードを入力（オプション）",
    "yuedu": "既読且つ同意 ",
    "yonghuxieyi": "ユーザー協議",
    "hasAccount": "アカウントはありますか？",
    "denglu": "すぐにログイン",
    "gouxuanxieyi": "読んでからユーザー協議をチェックしてください",
    "inputzijinmima": "資金暗号を入力",
    "yijianfankui": "意見フィードバック",
    "gao": "高",
    "di": "低",
    "shengdu": "深度",
    "chengjiao": "成約",
    "zixuan1": "自選をキャンセル済み",
    "zixuan2": "自選をキャンセル失敗",
    "zixuan3": "自選を追加成功",
    "zixuan4": "自選を追加失敗",
    "jiaoyie": "取引総額",
    "chengjiaozonger": "成約総額",
    "chengjiaojun": "成約平均金額",
    "chengjiaoliang": "成約量",
    "bigmairu": "最大取引可能数量を超える",
    "weituochenggong": "委託成功",
    "weituoshibai": "委託失敗",
    "yue": "残高",
    "bizhong": "通貨",
    "huazhuanshuliang": "振替数量",
    "inputhzsl": "振替数量を入力",
    "bunengweifu": "振替数量は負数不可",
    "dayuhuazhuan": "今の振替数量は勘定の振替可能数量より大きい",
    "huazhuanchenggong": "振替成功",
    "huazhuangshibai": "振替失敗"
  },
  "user": {
    "guizeshuoming": "ルール",
    "shenheweitongguo": "審査は通れない",
    "shiming": "実名認証を完成してください",
    "fuzhichenggong": "コピー成功",
    "frozen": "凍結",
    "zhanghuquanyi": "勘定権益",
    "baozhenjin": "可用保証金",
    "jiaoyishijian": "取引時間"
  },
  "tradePage": {
    "CUSTOM": "自選"
  },
  "chart": {
    "minute": "min"
  },
  "charge": {
    "congbi": "チャージアドレス",
    "baocun": "QRコード画像を保存"
  },
  "heyuePage": {
    "yijianpingcang": "Flash Close",
    "copyerr": "コピー失敗",
    "pincang": "閉じる数量は０不可"
  },
  "yaoqin": {
    "zuce": "登録時間",
    "showPersonInfo": "個人情報を見る",
    "myinvitelink": "招待URL ",
    "my_column4": "レベル",
    "yaoqing": "招待",
    "dengji": "レベル成長進捗",
    "mubiao": " 目標",
    "zong": "トータルリベート",
    "jiaoyi": "取引明細",
    "wode": "招待コード",
    "huodong": "イベント特典"
  },
  "version3": {
    "hash": "ハッシュ値",
    "betTip": "10分毎に一つ目のETHブロックのハッシュ値の末尾を当てて、0-7は小で、8-fは大で、0、2、4、6、8、a、c、eは双で、1、3、5、7、9、b、d、fは単です。",
    "betTip2": "ハッシュ値の末尾を当てて、0-7は小で、8-fは大で、0、2、4、6、8、a、c、eは双で、1、3、5、7、9、b、d、fは単です。",
    "betMoneyTip": "一口金額",
    "doBet": "すぐにベット",
    "issue": "No.",
    "blockHigh": "ブロック高度",
    "blockTime": "ブロック生成時間",
    "hash2": "ハッシュ値",
    "gameRecord": "ゲーム記録",
    "betTime": "注文時間",
    "pickUpAddress": "換金アドレス",
    "arriveAccountNum": "入金数量",
    "remark": "備考",
    "contachService": "顧客サービスに連絡",
    "totalBetMoney": "注文総額",
    "unOpen": "まだ当選を発表してない",
    "maintain": "メンテナス中",
    "hasBingGo": "当選しました",
    "unBingGo": "当選しない",
    "outOfBalance": "残高は不足",
    "chooseBetNumTip": "ベットオプションを選択",
    "chooseBetMoney": "一口金額を入力",
    "minBetAmount": "最小一口金額は",
    "maxBetAmount": "最大一口金額は",
    "chooseWithDrawAddressTip": "アドレスを選択",
    "inputRemarkTip": "換金アドレスを入力",
    "confirmOrder": "注文を確認",
    "manji": "VIP等級はいっぱいです",
    "selWithAddress": "換金アドレスを選択",
    "withNumTip": "換金数量は",
    "unLiuShui": "未完成フロー",
    "withdrawChannel": "換金チャネル"
  },
  "version4": {
    "game": "ゲーム",
    "baocangIntro": "{date}にあなたの{account}スワップ勘定毛総権益は0 USDTで、保証金率≤0で、強制的にクローズさていてい、つまりロスカットです。",
    "geshi": "正確な金額フォーマットを入力",
    "mingc": "成約量",
    "zhangd": "騰落",
    "quanqiu": "Global",
    "yiliu": "A-team",
    "yinhang": "Security"
  },
  "version5": {
    "rechargeWay": "チャージチャネル",
    "inputChargeNum": "チャージ金額を入力",
    "receivePeople": "受取人",
    "bankCard": "銀行カード",
    "bank": "銀行",
    "bankNumber": "銀行アカウント",
    "bankProvince": "口座開設都道府",
    "bankCity": "口座開設都市",
    "bankBrancher": "口座開設支店",
    "rengong": "手動チャージ＆換金"
  },
  "version7": {
    "p6": "まず銀行カードをバインド",
    "jinxingzhong": "取引中",
    "yiwancheng": "完了",
    "shibai": "取引失敗",
    "nichen": "ユーザー名",
    "p8": "一回の限度額",
    "username": "ユーザー名を入力"
  },
  "version8": {
    "noArrive0": "入金金額は０不可",
    "xuantian": "招待コードを入力",
    "result": "結果",
    "color": "色",
    "hlRule": "0-9の数値を当てて、1、3、5、7、9は緑で、0、2、4、6、8は赤で、0又は5も同時に紫でもある。"
  },
  "Transfer": {
    "FriendsReport": "チームレポート",
    "jintian": "今日",
    'qiantian' : 'おととい',

    "zuotian": "昨日",
    "benzhou": "今週",
    "shangzhou": "先週",
    "benyue": "今月",
    "shangyue": "先月",
    "zuce": "登録人数",
    "shoucong": "初回チャージ人数",
    "chongzhi": "チャージ人数",
    "tixian": "換金人数",
    "heyue": "スワップ人数",
    "youxi": "ゲーム人数",
    "touzu": "ゲームベット",
    "zhongjiang": "ゲーム当選",
    "shimingrenshu": "実名人数",
    "status_1": "審査中",
    "status_3": "失敗",
    "status_4": "成功",
    "jilu": "記録"
  },
  "version9": {
    "SD1MRule": "ディスクの赤白数量を当てて、同じ色のディスクは偶数であれば双で、同じ色のディスクは奇数であれば単です。",
    "FourRed": "4赤0白",
    "ThreeRedOneWhite": "3赤1白",
    "FourWhite": "0赤4白",
    "OneRedThreeWhite": "1赤3白"
  },
  "version10": {
    "titMode3": "換金した際、チェーンアップの取引が処理されることを確保するためにマイニング費用を支払う必要あり、今のマイニング費用は {minTxFee} USDTです。",
    "onGame": "進行中",
    "tuihui": "戻し済み",
    "hasJump": "クラッシュ済み",
    "player": "プレイヤー",
    "money": "金額",
    "jump": "クラッシュ",
    "jumpMulti": "クラッシュ倍数",
    "betMoney": "注文金額",
    "autoJumpTip": "自動クラッシュ倍数（オプション）",
    "p1": "最小クラッシュ倍数は　より大きい必須",
    "p2": "最大クラッシュ倍数は",
    "autoJump": "自動クラッシュ",
    "boomValue": "爆発倍数",
    "crash": "墜落",
    "noRepeatOrder": "重複に注文しないでください",
    "jumpSuccess": "クラッシュ成功",
    "tip1_plan": "１、自動クラッシュ倍数はオプションで、選択してから、飛行機は相応する倍数に達すると自動的にクラッシュできるだけではなく、飛行機が墜落する前に手動的にクラッシュにもできます。選択してない場合、飛行機が値墜落する前に動的にクラッシュもできます。",
    "tip2_plan": "2、クラッシュ済みのプレイヤーの利益から{amount}%歩合を取ります",
    "dizhi": "正確なアドレスを入力"
  },
  "version11": {
    "trend": "トレンド",
    "goldenBroad": "当選朗報",
    "broadTxt": "{userName}は<span class='Tcolor'>{winMoney}</span> USDTを当選しておめでとうございます",
    "TS": "クラッシュ",
    "CQK1M": "１分ブロック",
    "CQK5M": "5分ブロック",
    "CQK": "10分ブロック（チェーンアップ）",
    "HL": "1分赤緑",
    "HL5M": "5分赤緑",
	"RG30S": "30秒赤緑",
    "SD1M": "１分ディスク",
    "SD5M": "５分ディスク",
    "Gogame": "ゲームに入る",
    "yinli": "黒字金額",
    "munber": "黒字金額＝取引損益＋イベント特典＋リベート",
    "Gyinkui": "ゲーム損益",
    "Hyinkui": "スワップ損益",
    "Hlijin": "イベント特典",
    "Yfanyong": "リベート"
  },
  "version12": {
    "hl10mRule": "10分毎に一つ目のETHのハッシュ値を末尾から一つ目の0-9数値を当てて、1、3、5、7、9は緑で、0、2、4、6、8は赤で、０又は５は同時に紫でもある",
    "sd10mRule": "10分毎に一つ目のETHブロックのハッシュ値の末尾４桁を当てて、各位数値は一つのディスクに対応し、0-7は白で、8－fは赤で、同じ色のディスクは偶数であれば双で、同じ色のディスクは奇数であれば単です。",
    "SD10M": "10分ディスク（チェーンアップ）",
    "HL10M": "10分赤緑（チェーンアップ）",
    "refresh": "更新"
  },
  "version13": {
    "kaijiangzhong": "当選発表中",
    "canyu": "私の",
    "kaijiang": "当選発表済み",
    "join": "参与",
    "buyAmount": "購入件数を入力",
    "minBetAmount": "最小購入件数は",
    "maxBetAmount": "最大購入件数は",
    "tipMax": "最大件数を超えないで",
    "totalVal": "総価値",
    "jindu": "進捗",
    "leave": "残り",
    "goldNumber": "当選番号",
    "myGoldNumber": "私の番号",
    "val": "数値",
    "mode": "数値",
    "unJoin": "参与してない",
    "calNumber": "計算中",
    "eachPrice": "毎件の価格",
    "currentIssue": "今期の参与記録",
    "hisIssue": "当選を発表する前の50件参与記録",
    "showDetail": "見る",
    "buy": "購買",
    "buyTime": "購買時間",
    "luckNumber": "賞番号",
    "totalBuyAmount": "購買総額",
    "buyTip2": "正確な件数フォーマットを入力",
    "goldMember": "当選会員",
    "openTime": "当選を発表する時間",
    "Wtime": "予測のメンテナス時間",
    "invitationNote": "招待説明",
    "p1": "<ins class='paramTitle'>友達</ins>を招待して“招待センター”に入って、招待URL或は招待コードをコピーして、あなたの友達にシェアします。友達は招待コードで登録してからあなたの下級になります。",
    "p2": "<ins class='paramTitle'>リベートを稼ぐ</ins>下級は取引した際、相応するリベートを取得でき、最大三つの下位レベルがサポートされています。例えば、あなたはAを招待して、AはＢを招待して、ＢはⅭ招待して、A、B、Cはプラットフォームにゲーム、スワップなど取引る場合、あなたは相応するリベートを取得できます。",
    "fuk": "払ってください",
    "guoq": "期限切れ",
    "exchtishi": "本人が実名した銀行カードで上記の口座に振り込んでください",
    "exchtishi2": "送金が遮れるなど問題を防ぐために、振り込んでいるうちにBTC、USDTなど情報を備考しない",
    "quxiaoOrder": "注文キャンセル",
    "chengong": "支払う成功",
    "fukuanren": "支払う名前",
    "fukuanrenTi": "支払う名前を入力",
    "zhanghao": "支払うアカウント",
    "zhanghaoTi": "支払うアカウントを入力",
    "yihang": "支払う銀行",
    "yihangTi": "支払う銀行を入力",
    "jietu": "支払うキャプチャー",
    "jietuTi": "支払うキャプチャーをアップロード",
    "queren": "支払いを確認してください",
    "queren2": "悪意のある確認ならばアカウントは直接に凍結される",
    "YYYG": "ラッキー1U",
    "dragon": "ランキング",
    "paimng": "ランク付け"
  },
  "version15": {
    "only60Days": "60日内の記録のみを保留する",
    "only100Records": "最近100件の記録のみを展示する",
    "status": "状態",
    "luckyWheel": "ラッキー抽せん",
    "option1": "ボーナス",
    "option2": "Thanks",
    "option3": "Once More",
    "freeTimes": "抽せん回数",
    "p1": "登録に成功したら{registerGiveCount} 回の抽せん機会を取得できます。",
    "p2": "昨日（UTC+0時区を準する）チャージ金額は {todayRechargeCondition} USDTに至り、{rechargeGiveCount} 回の抽せん機会を取得できます。",
    "p3": "昨日（UTC+0時区を準する）有効なベット金額（スポットとコントラクトを除く）は{todayBettingCondition} USDTに至り、{bettingGiveCount}回の抽せん機会を取得できます。",
    "continuDraw": "引き続き抽せん",
    "noFreeTimeTip": "あなたの抽せん回数は使い切りました"
  },
  "version16": {
    "totalPerMoney": "注文総額",
    "notFu": "注文金額は負数不可",
    "find": "発見",
    "channel": "チャネル"
  },
  "version17": {
    "drawWarmTip": "おまけの抽せん回数、10分から半時間まで確認できていますので、お待ちください",
    "level": "レベル",
    "upLimit": "アップグレード敷居",
    "chatRoom": "チャット",
    "group": "グループ",
    "privateChat": "プライベートチャット",
    "groupManager": "管理員",
    "groupMember": "グループメンバー",
    "groupSetting": "グループ設定",
    "totalChargeMoney": "累計チャージ",
    "vip9Tip": "VIP等級はいっぱいです",
    "dataTime": "データ時間",
    "sortType": "ランク方式",
    "loginTime": "ログイン時間",
    "lowerPeople": "下級人数",
    "chargeNum": "チャージ金額",
    "withdrawNum": "換金金額",
    "lowerReport": "チームレポート",
    "lowerUserBetNum": "下級ベット",
    "lowerUserAwardNum": "下級当選",
    "lowerUserChargeNum": "下級チャージ",
    "lowerUserWithdrawNum": "下級換金",
    "firstRechargeNum": "初回チャージ金額",
    "warmTip": "今日のデータを表示するとデフォルトする",
    "publishNotice": "公布",
    "editNotice": "公告を編集する",
    "noticeTime": "公告時間",
    "inputNoticeTip": "公告内容を入力",
    "tip": "全員発言禁止を起用したら、グループ管理員だけ発言できる",
    "allNotSpeak": "全員発言禁止",
    "canSpeakLevel": "発言レベル",
    "eryuanqiquan": "オプション",
    "optionHelpText": "これから一段階の騰落を予測します。毎期に始める価格は開始価格で、毎期の終わる価格は決済価格で、決済価格は開始価格より大きければ上昇で、決済価格は開始価格より小さければ下落です。",
    "optionRecord": "オプション記録",
    "calcuteDirection": "予測方向",
    "startPrice": "開始価格",
    "endPrice": "決済価格",
    "equal": "騰落無し",
    "waitResult": "決済待ち",
    "up": "上昇",
    "down": "下落",
    "findPassword": "暗号を探し戻す",
    "calTime": "予測時間",
    "calWeek": "予測周期",
    "kanzhang": "先高",
    "kandie": "先安"
  },
  "version18": {
    "issue": "ナンバリング",
    "vipSort": "VIP等級",
    "jinyan": "発言が禁止されている",
    "weijinyan": "発言が禁止されてない",
    "confirmDelete": "メンバーを削除しますか?",
    "confirmUnSpeak": "メンバーの発言を禁止しますか?",
    "confirmSpeak": "メンバーを解禁しますか?",
    "confirmAdd": "メンバーを追加しますか?",
    "addMember": "メンバーを追加する",
    "delMember": "メンバーを削除する",
    "lockSpeak": "発言禁止",
    "unLockSpeak": "解禁",
    "lockSpeakSelMember": "発言が禁止されたメンバー",
    "unLockSpeakSelMember": "解禁されたメンバー",
    "noSelMemberTip": "会員を選択する",
    "recentlyPlayGame": "最近よく遊ぶ",
    "sendMsg": "メッセジーを送信する",
    "addGroupMember": "メンバーを追加する",
    "picTip": "[画像]",
    "time3": "１週間前",
    "chooseAll": "逆オプション",
    "personalPage": "個人ページ",
    "CQK3M": "3分ブロック",
    "HL3M": "3分赤緑",
    "SD3M": "3分ディスク"
  },
  "version19": {
    "avatar": "プロフィル写真",
    "share": "シェア",
    "todayProfit": "本日利益",
    "followBet": "フォローアップ",
    "Jump": "行く",
    "over": "終了",
    "optionNumber": "オプション人数",
    "optionBet": "オプションベット",
    "optionReward": "オプション当選",
    "optionProfit": "オプション損益",
    "RG_2_0": "赤",
    "RG_5_1": "紫",
    "RG_2_1": "緑",
    "DS_16_0": "1111",
    "DS_16_1": "0000",
    "DS_4_0": "1110",
    "DS_4_1": "1000",
    "DS_2_0": "双",
    "DS_2_1": "単",
    "BLK_2_0": "大",
    "BLK_2_1": "小",
    "BLK_2_2": "単",
    "BLK_2_3": "双",
    "BLK_4_0": "大単",
    "BLK_4_1": "大双",
    "BLK_4_2": "小単",
    "BLK_4_3": "小双"
  },
  "version20": {
    "server": "顧客サービス",
    "teacher": "メンター",
    "financer": "財務",
    "planer": "計画員",
    "chatPop": "チャットフローティングウィンドウズ",
    "cancelText": "フローティングウィンドウズを消す",
    "betNumber": "ベットオプション",
    "resendMsg": "メッセージは送信に失敗して、改めて送信しますか?",
    "delThisMsg": "当メッセージを削除する",
    "delThisMemberAllMsg": "当メンバーのメッセージを削除する",
    "forbitThisMember": "発言が禁止されたメンバー",
    "delThisMember": "メンバーを削除する",
    "delTipModal": "操作する機能を選択"
  },
  "version21": {
    "USDT_account": "USDT勘定",
    "closeSwapTip": "ポジションを閉じる割合を選択",
    "hasRecharge": "チャージ済み",
    "numberCoin": "デジタル通貨",
    "none": "-",
    "fastCharge": "ファストチャージ",
    "bankTransfer": "銀行振込",
    "setLegalTip": "あなたは使った法定通貨を選択",
    "legalCoin": "法定通貨",
    "orderCreateTime": "作る時間",
    "tradeOrderNumber": "取引",
    "completeTime": "完成時間",
    "fastWithdraw": "ファスト換金",
    "bindCardTip1": "有効的なIDに書いてある名前を入力し、コミットしてから修正できない",
    "bindCardTip2": "銀行カードの情報を入力"
  },
  "saas": {
    "fastChargeType": "ファストトラックチャージ",
    "numberCoinChargeType": "デジタル通貨チャージ",
    "humanChargeType": "手動チャージ",
    "fastChannelWithdrawType": "ファストトラック換金",
    "numberCoinWithdrawType": "デジタル通貨換金",
    "humanWithdrawType": "手動換金",
    "fastChannelBackType": "ファストトラック戻し",
    "numberChannelBackType": "デジタル通貨戻し",
    "betType": "ベット",
    "betProfitType": "黒字",
    "betEqualType": "引分け",
    "gameBackRewardType": "ゲームリベート",
    "swapBackRewardType": "スワップリベート",
    "exchangeBackRewardType": "スポットリベート",
    "tranInType": "振込",
    "tranOutType": "送金",
    "chargeRewardType": "チャージ特典",
    "firstChargeRewardType": "初回チャージ特典",
    "regRewardType": "登録特典",
    "upGradeRewardType": "進級特典",
    "luckyRewardType": "ラッキー抽せん",
    "realNameRewardType": "実名特典",
    "betRewardType": "ベット特典",
    "dailyFirstChargeRewardType": "毎日初回チャージ特典",
    "upScoreType": "人工増加",
    "downScoreType": "人工減少",
    "unRecharge": "チャージしてない",
    "canWithdrawBalance": "換金できる残高",
    "withPopTip1": "換金した際、未完成フローがあれば、換金できません。",
    "withPopTip2": "換金した際、フローが未完成した分は{flowFee}%のフロー手数料を払う必要があります。",
    "alterBankCardNameTip": "名前を修正する場合、顧客サービスに連絡してください",
    "arriveMoney": "入金金額",
    "withdrawNum": "換金数量",
    "failReason": "失敗原因",
    "tradeHash": "取引ハッシュ",
    "waitCoin": "貸し付け待ち",
    "zicang": "資産",
    "otherMoney": "他の資産概算",
    "moneyList": "資産リスト",
    "onlyShowSwap": "スワップのみ見る",
    "hideZeroMoney": "0資産を隠す",
    "chargeFlowTip1": "USDT勘定にチャージする残高に対して、{deposit}%の未完成フローを増やします。例えば、1000USDTをチャージしたら、{depositValue}USDTの未完成フローをふやします。",
    "chargeFlowTip2": "未完成フローはゲーム/オプションの有効なベットを通して差引きます。例えば未完成フローは200USDTで、ゲームに10USDTの有効なベットが生じる場合、未完成フローは190USDTを残ります。",
    "chargeRecord": "チャージ記録",
    "withdrawRecord": "換金記録"
  },
  "version22": {
    "verificationCode": "CAPTCHAコード",
    "userNameErr": "ユーザー名のフォーマットは違い、.と@を含む6-32桁の英字又は英字と数字の組合せを入力してください",
    "tip": "提示",
    "because": "原因",
    "no": "番号",
    "auditError": "審査不合格",
    "auditSuccess": "審査合格",
    "fundPassword": "資金暗号のフォーマットは違い、６桁数字を入力してください",
    "allEntrust": "全部委託",
    "dealAmount": "成約金額",
    "watcherTeamForm": "チームレポートを見る",
    "overview": "一覧",
    "stopBet": "今期は委託終了ので、ベットを停止します",
    "balance": "可用残高",
    "exchangeOrderMinVolume": "最小委託量は",
    "exchangeOrderMaxVolume": "最大委託量は",
    "BLK5MC": "5分ブロック（チェーンアップ）",
    "RG5MC": "5分赤緑（チェーンアップ）",
    "DS5MC": "５分ディスク（チェーンアップ）",
    "forbidTrade": "当取引モジュールは取引を禁止されている",
    "minTurnoverNum": "最小成約額は",
    "minSwapOpenNum": "最小枚数はx枚",
    "maxSwapOpenNum": "今回の最大枚数はx枚",
    "activity": "イベント",
    "profitRecord": "戦績",
    "service": "顧客サービス",
    "help": "ヘルプ",
    "totalAssets": "総資産",
    "readAllLetterTip": "すべてのメッセジーを既読に設定しますか?",
    "myTeam": "私のチーム",
    "lv1": "1級",
    "lv2": "2級",
    "lv3": "3級",
    "lvAll": "123級",
    "calDataTime": "統計時間",
    "dataUpdateTip": "データ更新時間：{date}",
    "total": "総件数",
    "lastTopUserName": "上級ユーザー名",
    "humanAdd": "手動増加",
    "humanSub": "手動減少",
    "realNameTime": "実名時間",
    "chargeTime": "チャージ時間",
    "reSubmit": "改めてコミット",
    "goldMoney": "当選金額",
    "username": "ユーザー",
    "myNum": "私の番号",
    "waitOpen": "当選発表待ち",
    "buyAmount": "購入件数",
    "bugNum": "相応する件数を購入",
    "gainCode": "賞番号を取得",
    "totalNumOpenReward": "総件数が売り切れてから当選を発表する",
    "winnerGainAllReward": "当選の方はすべての奨励金を獲得する",
    "teamLevel": "チーム等級",
    "SD_serialGame": "ディスク",
    "RG_serialGame": "赤緑",
    "BLK_serialGame": "ブロック",
    "codeTip": "チャージアドレスQRコード",
    "relateTopUser": "所属上級",
    "lv1Num": "1級人数",
    "lv2Num": "2級人数",
    "lv3Num": "3級人数",
    "singleLevelTxt": "　級",
    "wrondPasswordCorrect": "ログイン暗号のフォーマットは違い、8-32桁の英字と数字の組合せを入力してください",
    "withdrawAccount": "換金勘定",
    "optionModeShutTip": "今{minute}ミニットモードはメンテナス中",
    "optionModuleShutTip": "メンテナス中",
    "gameClosed": "ゲームはクローズされている",
    "doBet": "ベット",
    "reAlterBindCard": "あなたの法定通貨は銀行カードの通貨と合わないので、タイムリーに銀行カード情報をご更新ください",
    "t2": "当選を発表する前の当ゲームの最後の50件購買記録時間（UTC+0）の数値の合計",
    "emailErr": "正確なメールアドレスを入力",
    "bigSymTip": "表示<大>",
    "smallSymTip": "表示<小>",
    "EvenSymTip": "表示<双>",
    "OddSymTip": "表示<単>",
    "exchangeNum": "スポット人数",
    "yesterdayAward": "昨日当選",
    "yijixiaji": "一級下級",
    "erjixiaji": "二級下級",
    "sanjixiaji": "三級下級",
    "p3": "<ins class='paramTitle'>チーム等級</ins>押し広げた一級の下級が多ければ多いほど、チームの等級は高くなり、取得したリベートも高くなります。チーム等級はLV1-LV6で、アップグレードのルールは下記の表通り、そのうちNはチャージした一級の下級人数です。",
    "p4": "<ins class='paramTitle'>ゲーム、オプション、秒契約、娯楽のリベート割合</ins>下級はゲーム、秒契約、オプション、娯楽をベットする場合、あなたはベット金額に相応する割合のリベートを取得できます。",
    "p5": "<ins class='paramTitle'>スポット、スワップのリベート割合</ins>下級はスポット取引、スワップ取引をする場合、あなたは取引の手数料に相応する割合のリベートを取得できます。手数料はUSDT以外の通貨であれば、リベートはリアルタイム為替レートでUSDTに換算してあなたのUSDT勘定に送金します。",
    "flag": "特徴",
    "noZero": "振替数量は0不可",
    "networkTip": "ネットエラーで、ページを更新して改めてローディング",
    "inviteChargeTip": "チーム等級のアップグレード、チャージした一級の下級人数次第",
    "canWithdrawNum": "換金できる残高",
    "withTip": "換金数量を入力",
    "withModalTip": "手数料はフロー手数料とマイニング費用を含む",
    "totalUpNum": "総人数",
    "chargeRangeTip": "チャージ金額は",
    "jiaocheng": "チュートリアル",
    "spare1": "予備1",
    "spare2": "予備2",
    "loadAllGroupMsg": "7日間内のすべてのメッセージはローディングされ済み",
    "realMember": "正式会員",
    "testMember": "テスト会員",
    "noBlankMsg": "空白のメッセージを送信できない",
    "maxLength1500": "内容は長すぎ、メッセージは送信に失敗",
    "sendToMore": "リクエストが頻繁すぎ、メッセージは送信に失敗",
    "countdown": "カウントダウン",
    "toLongRemark": "文字データは多すぎ、削減してください",
    "tip1": "<p>* データは統計時間を準し、一時間毎にデータを更新して、最新更新時間：{time}；そのうち、登録人数/実名人数/初回チャージ人数/初回チャージ金額はリアルタイムに更新します</p>",
    "tip2": "<p>* USDT残高は統計時間と関係なし、いつもチーム今の総残高</p>を表示しています",
    "tip3": "* チャージ/換金/ベット/当選/リベート金額は統計時間を準し、一時間毎にデータを更新して、最近更新時間：{time}",
    "tip4": "* その他データは統計時間と関係なし、いつも今の最新データを表示しています",
    "wrongImgType": "サポートされないフィルフォーマット",
    "addNewNotice": "増加",
    "noChoose": "取りえず選択しない",
    "nullCoin": "無し",
    "thankyou": "ご参考ありがとうございました",
    "oncemore": "もう一回してください",
    "tip5": "レートは変わる可能ので、実際金額を準します",
    "xiugaichenggong": "修正成功した、改めてログインしてください",
    "entrustNoZero": "委託量は０不可",
    "herTeam": "彼のチーム",
    "betMoney": "ベット金額を入力",
    addFilterMember : 'フィルターした全部成員を添加',
    delFilterMember : 'フィルターした全部成員を削除',
    forbidFilterMember : 'フィルターした全部成員を発言禁止',
    freeFilterMember : 'フィルターした全部成員を解禁',
    theme1 : 'ゲームテーマ1',
    theme2 : 'ゲームテーマ2',
    theme3 : '取引所テーマ１',
    theme4 : '取引所テーマ2',
    themeTitle : 'テーマ',
    maxTradeMoney: '最大取引高は'
  },
  "version23": {
    inviteCenter: "招待センター",
    search: '検索',
    active: '操作',
    openDirect: '当籤発表方向',
    profitAndLossAmount: '損益金額',
    levelMax: "最高レベルに至った",
    next: '次ページ',
    prev: '前のページ',
    secondRecord : '秒契約記録',
    entrusting : '注文中',
    secondReward : '秒契約当籤',
    secondBet : '秒契約ベット',
    secondProfit : '秒契約損益',
    RD100N : 'ランダム100',
    secondNum : '秒契約人数',
    rebackTip : '1時間毎にリベートが発行され、暫くお待ちください',
    secondOption : '秒契約',
    qiquan : 'オプション',
    exportBtn : '導出',
    "SB1M": "1分飛行船",
    "SB3M": "3分飛行船",
    "SB5M": "5分飛行船",
    "SBTM": "10分飛行船",
  },
  version24 : {
    goldBoom : '地雷を踏む',
    'MS' : 'マインスイーパー',
    spRecord : '体育記録-Ponymuah',
    "QT1M": "１分ダイス",
    "QT3M": "３分ダイス",
    "QT5M": "５分ダイス",
    "QTTM": "１０分ダイス",
    "KN1M": "１分KENO",
    "KN3M": "3分KENO",
    "KN5M": "5分KENO",
    "KNTM": "１0分KENO",
    "electronRecord": "ゲームセンター記録-Evolution",
    "LOT1M": "1分LOTTO",
    "LOT3M": "3分LOTTO",
    "LOT5M": "5分LOTTO",
    "LOTTM": "10分LOTTO",
    'LOTW3' : 'LOTTO',

    'TWTL' : '今彩539',
    'TWTL1M' : '1分今彩539',
    'TWTL5M' : '5分今彩539',
    'TWTLTM' : '10分今彩539',
    'TWTL1D' : '1天今彩539',

    "RLT1M": "1分ルーレット",
    "RLT3M": "3分ルーレット",
    "RLT5M": "5分ルーレット",
    "RLTTM": "10分ルーレット",
    "LK281M": "1分ラッキー28",
    "LK283M": "3分ラッキー28",
    "LK285M": "5分ラッキー28",
    "LK28TM": "10分ラッキー28",
    "liveRecord": "ライブ記録-Ezugi",
    evoLiveRecord : 'ライブ記録-Evolution',
    pbRecord : '体育記録-PinnacleBet',
    LIMBO : 'LIMBO',
    HILO : 'HILO',
	'FSLOT' : 'フルーツ',
    'RULTI' : 'ホイール',
    'RULTI_2-075_0' : '黒',
    'RULTI_3-176_0' : '赤',
    'RULTI_5-4_0' : '青',
    'RULTI_54_0' : '緑',
	'RB' : '赤黒',
	'RB_2-142_0' : '赤',
	'RB_15_0' : '白',
	'RB_2-142_1' : '黒',
	
    'FST' : '4つ星宝くじ',
    'FST1M' : '1分4つ星宝くじ',
    'FST5M' : '5分4つ星宝くじ',
    'FSTTM' : '10分4つ星宝くじ',
    'FST1D' : '1日4つ星宝くじ',

    'TS' : '3つ星宝くじ',
    'TS1M' : '1分3つ星宝くじ',
    'TS5M' : '5分3つ星宝くじ',
    'TSTM' : '10分3つ星宝くじ',
    'TS1D' : '1日3つ星宝くじ',

    'TWLT1M' : '1分大楽透',
    'TWLT5M' : '5分大楽透',
    'TWLTTM' : '10分大楽透',
    'TWLTW2' : '大楽透',
    'TWLT' : '大楽透',

    'SPLT1M' : '1分威力彩',
    'SPLT5M' : '5分威力彩',
    'SPLTTM' : '10分威力彩',
    'SPLTW2' : '威力彩',

    'SPLT' : '威力彩',
  },
  version25 : {
    sport : '体育-Ponymuah',
    live :'ライブ-Ezugi',
    electron : 'ゲームセンター-Evolution',

    sport_index : '体育',
    live_index :'ライブ',
    electron_index : 'ゲームセンター',

    PG : 'ゲームセンター-PocketGame',
    PP : 'ゲームセンター-PragmaticPlay',
    "sportsNum": "体育人数",
    "sportsAward": "体育当籤",
    "sportsBet": "体育ベット",
    "sportsProfit": "体育損益",

    electronNum : 'ゲームセンター人数',
    electronAward : 'ゲームセンター当籤',
    electronBet:'ゲームセンターベット',
    electronProfit : 'ゲームセンター損益',

    liveNum : 'ライブ人数',
    liveAward : 'ライブ当籤',
    liveBet:'ライブベット',
    liveProfit : 'ライブ損益',
		
	Num : '人数',
    Award : '当籤',
    Bet:'ベット',
    Profit : '損益',

    ourGame : 'オリジナルゲーム',
    "t1": "大量のゲーム、存分に楽しめるよう",
    "t2": "全世界の先頭を立つゲームプラットフォーム",
  },
  version26 : {
    liveTeamReport : 'リアルタイムチームレポート',
    tip2 : `24時間近くの時間しか選択できません`
  },
  version27 : {
    PGrecord : 'ゲームセンター記録-PocketGame',
    PPRecord : 'ゲームセンター記録-PragmaticPlay',

  }
}