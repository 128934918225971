module.exports = {
  "nav": {
    "shouye": "Home",
    "hanqing": "Markets",
    "jiaoyi": "Trade",
    "heyue": "Swap",
    "wode": "Me"
  },
  "common": {
    "login": "Log In",
    "register": "Sign Up",
    "loginregister": "Log In / Sign Up",
    "logout": "Log Out",
    "tip": "Tips",
    "delete": "Are you sure to delete?",
    "set": "Settings",
    "update": "Modify",
    "more": "More",
    "loading": "Loading",
    "zanwu": "No Record",
    "quxiao": "Cancel",
    "ok": "Confirm",
    "noyanzhengma": "Please enter the correct CAPTCHA",
    "clearStorage": "Clear Cache",
    "telErr": "Please enter the correct mobile number",
    "T404": "The platform is being updated, and access will be restored later"
  },
  "loginPage": {
    "mima": "Password",
    "passwordTxt": "Please enter the password"
  },
  "homePage": {
    "charge": "Deposit",
    "pickup": "Withdraw",
    "exchange": "Spot",
    "promotionaward": "Rebate"
  },
  "pageTitle": {
    "gonggaoxiangqing": "Announcement",
    "wodezichang": "Balances",
    "bangzhuxiangqing": "Details",
    "fundpwd": "Fund Password",
    "yuyanshezhi": "Language",
    "huazhuang2": "Transfer",
    "renzheng": "Authentication",
    "chooseAlterPhoneType": "Mobile number",
    "chooseAlterEmailType": "Email",
    "letterList": "Notifications",
    "appDownload": "Download the App",
    "buy": "BUY",
    "sell": "SELL",
    "cexiao": "Cancel",
    "time": "Time",
    "type": "Type",
    "jiage": "Price",
    "weituoliang": "Amount",
    "changjiao": "Executed",
    "weituoe": "Payment",
    "over": "All loaded",
    "weituojia": "Entrusting Price",
    "weituoer": "Total Amount",
    "quedingchedan": "Are you sure to cancel?",
    "chedaning": "Cancelling",
    "jiaoyidui": "Coin Pairs",
    "fangxiang": "Side",
    "chongzhi": "Reset",
    "xianjia": "Limit",
    "shijia": "Market",
    "plan": "Trigger",
    "quanbu": "All",
    "xianjiaweituo": "Limit",
    "yichexiao": "Cancelled",
    "time1": "Start time must be earlier than the end time",
    "time2": "Start time must be earlier than the end time",
    "time3": "End time must be earlier than the current time",
    "cedancg": "Cancelled successfully",
    "cedansb": "Failed to cancel",
    "mairujiage": "Unit Price",
    "yongxu": "SWAP",
    "zhanghu": "Equity",
    "yugu": "EST Liquidation Price",
    "baozheng": "Margin Rate",
    "kaicang": "Open",
    "baocang": "Burned",
    "pincang": "Close",
    "zhanghu2": "Account",
    "pingduo": "Close Long",
    "kaiduo": "Open Long",
    "pingkong": "Close Short",
    "kaigong": "Open Short",
    "cufajia": "Trigger Price",
    "zuiyou": "Best Price",
    "zhang": "Cont",
    "keyong": "Available",
    "kekaiduo": "Max. L",
    "kekaikong": "Max. S",
    "duocang": "Max (L)",
    "kongcang": "Max (S)",
    "kanzhang": "Long",
    "kandie": "Short",
    "shuliang": "Amount",
    "chicang": "Positions",
    "dangqianweituo": "Open Orders",
    "lishiweituo": "History",
    "shouyi": "P/L",
    "shouyilv": "P/L Ratio",
    "gangganbeishu": "Leverage",
    "chicangliang": "Position",
    "kepingliang": "Avail. Position",
    "kaichangjun": "Open Price",
    "zuixinjia": "Latest Price",
    "dangqianbaozheng": "Cur Margin",
    "duotou": "Long",
    "kongtou": "Short",
    "baozhengjin": "Margin",
    "weituoshuliang": "Entrusted Amount",
    "jiesuanweik": "Settlemet P/L",
    "chengjiaojia": "Final Price",
    "shouxufei": "Fee",
    "xiugaichenggong": "Modified successfully"
  },
  "views": {
    "wangjiPass": "Forgot password?",
    "confirmLogOut": "Are you sure to log out?",
    "personalCenter": "User Center",
    "shezhi": "Set successfully",
    "yuanyouxiang": "Old Email Code",
    "newyouxiang": "New Email Code",
    "fsyouxiang": "Send Code",
    "xyouxiang": "New Email",
    "loginPass": "Login Password",
    "baocun": "Save",
    "fschenggong": "Sent successfully",
    "inputyouxiang": "Please enter your new email address",
    "inputOld": "Please enter the old email verification code",
    "inputNew": "Please enter the new email verification code",
    "inputPass": "Please enter your login password",
    "OldPass": "Old Login Password",
    "inputOldPass": "Please enter your old login password",
    "NewPass": "New Login Password",
    "inputNewPass": "Please enter your new login password",
    "queren": "Confirm Password",
    "email": "Verification Code",
    "liangciPass": "The passwords entered are inconsistent",
    "shuruCode": "Please enter the verification code",
    "OldZJ": "Old Fund Password",
    "inputOldZJ": "Please enter your old fund password",
    "NewZJ": "New Fund Password",
    "inputNewZJ": "Please enter your new fund password",
    "augeren": "Personal Information",
    "aushang": "Upload ID Card",
    "aushenghe": "Review",
    "name": "Full Name",
    "inputName": "Please enter your full name",
    "huzhao": "Government-issued ID Number",
    "inputhuzhao": "Please enter your ID, passport or driver's lisence number",
    "next": "Next",
    "uphuzhao": "Upload the photo of your ID card",
    "shouhuzhao": "Upload photos of individuals with certificates",
    "tijiao": "Submit",
    "tip1": "1. Please make sure that your ID is not expired",
    "tip2": "2. The content of signature includes handwritten {hrefArry}, personal signature and current date",
    "tip3": "3. Please make sure that the photos and personal signatures are clearly visible",
    "shentijiao": "Information has been submitted, please wait for review",
    "fanhui": "Return",
    "bangding": "Bind",
    "inputEmail": "Please enter your email address",
    "inputPhone": "Please enter your mobile number",
    "kefu": "Please contact customer service"
  },
  "list": {
    "wufaxiugia": "You cannot modify multiple when you have positions or undealed orders",
    "submittip1": "To ensure the safety of your funds, please conduct real name authentication first",
    "submittip2": "To ensure the safety of your funds, please set the fund password first",
    "shuruweituo": "Please enter the entrusted price",
    "shuruweituoliang": "Please enter the entrusted amount",
    "dayukekai": "The amount cannot be greater than Max. L",
    "dayuduocang": "The amount cannot be greater than Max (S)",
    "dayukekaikong": "The amount cannot be more than Max. S",
    "dayukongcang": "The amount cannot be more than Max (L)",
    "shuruchufa": "Please enter the trigger price",
    "zhangfubang": "Ranking",
    "liang": " Vol",
    "safar": "Click the button below in Safari",
    "kuaisuanzhuang": "choose [Add to Home Screen]",
    "noAccount": "No account yet?",
    "LoginReg": "Register",
    "butigongfuwu": "Please understand that your IP or local service is not available at this time",
    "renzheng1": "Verified",
    "renzheng2": "Failed to verify",
    "renzheng3": "Under review",
    "renzheng4": "Verify",
    "shenheing": "Under review, please wait patiently",
    "inputzaicimima": "Please enter the password again",
    "xuantian": "Please enter the invitation code (optional)",
    "yuedu": "I agree to",
    "yonghuxieyi": "User Service Agreement",
    "hasAccount": "Already registered?",
    "denglu": "Log In",
    "gouxuanxieyi": "Please read and check the User Service Agreement",
    "inputzijinmima": "Please enter your fund password",
    "yijianfankui": "Feedback",
    "gao": "High",
    "di": "Low",
    "shengdu": "Order Book",
    "chengjiao": "Filled",
    "zixuan1": "Removed from favorites",
    "zixuan2": "Failed to remove",
    "zixuan3": "Failed to remove",
    "zixuan4": "Failed to add",
    "jiaoyie": "Total Amount",
    "chengjiaozonger": "Total Amount",
    "chengjiaojun": "Average Price",
    "chengjiaoliang": "Volume",
    "bigmairu": "Exceeding the maximun available amount ",
    "weituochenggong": "Traded successfully",
    "weituoshibai": "Failed to trade",
    "yue": "Balance",
    "bizhong": "Currency",
    "huazhuanshuliang": "Transfer Amount",
    "inputhzsl": "Please enter the transfer amount",
    "bunengweifu": "The transfer amount must be greater than 0",
    "dayuhuazhuan": "The transfer amount cannot be greater than the available amount in your account",
    "huazhuanchenggong": "Transferred successfully",
    "huazhuangshibai": "Failed to transfer"
  },
  "user": {
    "guizeshuoming": "Rules",
    "shenheweitongguo": "Failed to verify",
    "shiming": "Please complete real name authentication",
    "fuzhichenggong": "Copied Successfully",
    "frozen": "On Orders",
    "zhanghuquanyi": "Equity",
    "baozhenjin": "Avail. Margin",
    "jiaoyishijian": "Transaction Time"
  },
  "tradePage": {
    "CUSTOM": "Favorites"
  },
  "chart": {
    "minute": "Minute"
  },
  "charge": {
    "congbi": "Deposit Address",
    "baocun": "Save QR Code"
  },
  "heyuePage": {
    "yijianpingcang": "Flash Close",
    "copyerr": "Failed to copy",
    "pincang": "The cont of the close position cannot be 0"
  },
  "yaoqin": {
    "zuce": "Registion Date",
    "showPersonInfo": "See your profile",
    "myinvitelink": "Invitation Link",
    "my_column4": "Class",
    "yaoqing": "Invitation",
    "dengji": "Level Progress",
    "mubiao": " Target",
    "zong": "Total Rebates",
    "jiaoyi": "Transactions",
    "wode": "Invitation Code",
    "huodong": "Activity Bonus"
  },
  "version3": {
    "hash": "Hash Value",
    "betTip": "Guess the last bit of the hash value of the first ETH block every 10 minutes , 0-7 are Small, 8-f are Big, 0, 2, 4, 6, 8, a, c and e are Even, 1, 3, 5, 7, 9, b, d and f are Odd.",
    "betTip2": "Guess the last bit of the hash value, 0-7 are Small, 8-f are Big, 0, 2, 4 ,6, 8, a, c and e are Even, 1, 3, 5, 7, 9, b, d and f are Odd.",
    "betMoneyTip": "Amount of per bet",
    "doBet": "Bet Now",
    "issue": "No.",
    "blockHigh": "Block Height",
    "blockTime": "Block Time",
    "hash2": "Hash Value",
    "gameRecord": "Game Records",
    "betTime": "Order Time",
    "pickUpAddress": "Withdrawal Address",
    "arriveAccountNum": "Amount Received",
    "remark": "Remark",
    "contachService": "Contact customer services",
    "totalBetMoney": "Total Amount",
    "unOpen": "No Result",
    "maintain": "Maintaining",
    "hasBingGo": "Won",
    "unBingGo": "Lost",
    "outOfBalance": "The balance is not enough",
    "chooseBetNumTip": "Please select the betting option",
    "chooseBetMoney": "Please enter the amount of per bet",
    "minBetAmount": "The minimum amount of per bet is ",
    "maxBetAmount": "The maximum amount of per bet is ",
    "chooseWithDrawAddressTip": "Please select a withdrawal address",
    "inputRemarkTip": "Please enter your withdrawal address",
    "confirmOrder": "Confirm",
    "manji": "Maximum VIP level reached",
    "selWithAddress": "Please select a withdrawal address",
    "withNumTip": "The withdrawal amount should be ",
    "unLiuShui": "Unfinished Flow",
    "withdrawChannel": "Withdrawal Channel"
  },
  "version4": {
    "game": "Game",
    "baocangIntro": "Your {account} swap account's equity was 0 USDT at {date}, margin rate ≤ 0 and forced liquidation will be triggered.",
    "geshi": "Please enter the correct amount",
    "mingc": "Volume",
    "zhangd": "Change",
    "quanqiu": "Global",
    "yiliu": "A-team",
    "yinhang": "Security"
  },
  "version5": {
    "rechargeWay": "Deposit Channel",
    "inputChargeNum": "Please enter the deposit amount",
    "receivePeople": "Payee",
    "bankCard": "Bank Card",
    "bank": "Bank",
    "bankNumber": "Bank Account",
    "bankProvince": "Located Province",
    "bankCity": "Located City",
    "bankBrancher": "Branch bank",
    "rengong": "Manual Deposit&Withdrawal"
  },
  "version7": {
    "p6": "Please bind your band card first",
    "jinxingzhong": "Trading",
    "yiwancheng": "Done",
    "shibai": "Failed",
    "nichen": "Username",
    "p8": "Single Transaction Quota",
    "username": "Please enter the username"
  },
  "version8": {
    "noArrive0": "Amount received cannot be 0",
    "xuantian": "Please enter the invitation code",
    "result": "Results",
    "color": "Color",
    "hlRule": "Guess the number between 0-9. 1, 3, 5, 7, 9 are Green, 0, 2, 4, 6, 8 are Red, 0 and 5 are also Purple."
  },
  "Transfer": {
    "FriendsReport": "Team Report",
    "jintian": "Today",
    "zuotian": "Yesterday",
    'qiantian' : 'Day before yesterday',

    "benzhou": "This Week",
    "shangzhou": "Last Week",
    "benyue": "This Month",
    "shangyue": "Last Month",
    "zuce": "Registration Users",
    "shoucong": "First-deposit Users",
    "chongzhi": "Deposit Users",
    "tixian": "Withdrawal Users",
    "heyue": "Swap Users",
    "youxi": "Game Users",
    "touzu": "Game Betting",
    "zhongjiang": "Game Winning",
    "shimingrenshu": "Verification Users",
    "status_1": "Reviewing",
    "status_3": "Failed",
    "status_4": "Success",
    "jilu": "Record"
  },
  "version9": {
    "SD1MRule": "Guess the number of color discs (red and white). Discs of the same color have an even number is Even, and discs of the same color have an odd number is Odd.",
    "FourRed": "4-Red/0-White",
    "ThreeRedOneWhite": "3-Red/1-White",
    "FourWhite": "0-Red/4-White",
    "OneRedThreeWhite": "1-Red/3-White"
  },
  "version10": {
    "titMode3": "It needs to pay the mining fee to ensure that the transaction on the chain is processed when withdrawing. The current mining fee is {minTxFee} USDT.",
    "onGame": "Ongoing",
    "tuihui": "Returned",
    "hasJump": "Cashed Out",
    "player": "Players",
    "money": "Amount",
    "jump": "Cashout",
    "jumpMulti": "Cashout Multiple",
    "betMoney": "Order Amount",
    "autoJumpTip": "Auto Cashout Multiple (Optional)",
    "p1": "The minimum multiple must be greater than ",
    "p2": "The maximum multiple is ",
    "autoJump": "Auto Cashout",
    "boomValue": "Crashed Multiple",
    "crash": "Crashed",
    "noRepeatOrder": "Do not bet repeatedly",
    "jumpSuccess": "Cashed out",
    "tip1_plan": "1. The auto cashout multiple is optional. After filling in, you will automatically cash out after the aircraft reaches the corresponding multiple, or you can manually cash out before the aircraft crashes; if you have not filled in, you can still manually cash out before the aircraft crashes.",
    "tip2_plan": "2. We will take {amount}% of the profit of the cashout players.",
    "dizhi": "Please enter the correct address"
  },
  "version11": {
    "trend": "Trends",
    "goldenBroad": "Winners",
    "broadTxt": "Congrats, {userName} won <span class='Tcolor'>{winMoney}</span> USDT",
    "TS": "Crash",
    "CQK1M": "1min Blocks",
    "CQK5M": "5min Blocks",
    "CQK": "10min Blocks (Chain Up)",
    "HL": "1min Red_Green",
    "HL5M": "5min Red_Green",
	'RG30S' : '30s Red_Green',
    "SD1M": "1min Discs",
    "SD5M": "5min Discs",
    "Gogame": "Go to Play",
    "yinli": "Profit",
    "munber": "Profit = Trading Profit + Activity Bonus + Rebate",
    "Gyinkui": "Game Profit",
    "Hyinkui": "Swap Profit",
    "Hlijin": "Activity Bonus",
    "Yfanyong": "Rebate"
  },
  "version12": {
    "hl10mRule": "Guess the fisrt 0-9 counting from the last bit to the front of the hash value of the first ETH block every 10 minutes, 1, 3, 5, 7, 9 are Green, 0, 2, 4, 6, 8 are Red, 0 and 5 are also Purple.",
    "sd10mRule": "Guess the last four bits of the hash value of the first ETH block every 10 minutes, each code corresponds to a disc, 0-7 are white, 8-f are red. Discs of the same color have an even number is Even, and discs of the same color have an odd number is Odd.",
    "SD10M": "10min Discs (Chain Up)",
    "HL10M": "10min Red_Green (Chain Up)",
    "refresh": "Refresh"
  },
  "version13": {
    "kaijiangzhong": "Drawing",
    "canyu": "My",
    "kaijiang": "Finished",
    "join": "Join",
    "buyAmount": "Number of copies to purchase",
    "minBetAmount": "The minimum purchasing number of copies is: ",
    "maxBetAmount": "The maximum purchasing number of copies is: ",
    "tipMax": "Please kindly note that don't purchace over the maximum number of copies",
    "totalVal": "Total Value",
    "jindu": "Progress",
    "leave": "Remain",
    "goldNumber": "Winning Code",
    "myGoldNumber": "My Code",
    "val": "Value",
    "mode": "Remainder",
    "unJoin": "Not Participated",
    "calNumber": "Calculating",
    "eachPrice": "Price of per copy",
    "currentIssue": "Records in this round",
    "hisIssue": "50 records before the draw",
    "showDetail": "View",
    "buy": "Buy",
    "buyTime": "Buying Time",
    "luckNumber": "Prize Number",
    "totalBuyAmount": "Total Buying Amount",
    "buyTip2": "Please enter the correct format of per copy",
    "goldMember": "Award Member",
    "openTime": "Drawing Time",
    "Wtime": "Estimated maintenance time",
    "invitationNote": "Invitation Instructions",
    "p1": "<ins class='paramTitle'>Invite Friends</ins>Enter the invitation center, copy the invitation link or invitation code, and share it with your friends. Friends are able to become your subordinate member by registering with your invitation code.",
    "p2": "<ins class='paramTitle'>Earn Rebates</ins>You can get corresponding rebates when invitees trading, which is up to three levels of invitees. For example, if you invite your friend A, A invites B, and B invites C, then A, B, and C are able to trade games, swaps, and other transactions on the platform. You can get corresponding rebates in these actions as well.",
    "fuk": "Please pay",
    "guoq": "Expired",
    "exchtishi": "Please use your certificated bank card to remit funds to the above account.",
    "exchtishi2": "Please do not note BTC, USDT and other information in the process of remitting to prevent the interception of remittances or any other issues.",
    "quxiaoOrder": "Cancel the order",
    "chengong": "I've paid successfully",
    "fukuanren": "Payer Name",
    "fukuanrenTi": "Please enter the payer name",
    "zhanghao": "Payment Account",
    "zhanghaoTi": "Please enter your payment account",
    "yihang": "Paying Bank",
    "yihangTi": "Please enter your paying bank",
    "jietu": "Screenshot of Payment",
    "jietuTi": "Please upload your screenshot of payment",
    "queren": "Please confirm that you have paid",
    "queren2": "In case of malicious confirmation, the account will be frozen",
    "YYYG": "Lucky 1U",
    "dragon": "Ranking",
    "paimng": "Ranking"
  },
  "version15": {
    "only60Days": "Only keep data within 60 days",
    "only100Records": "Show only the last 100 records",
    "status": "Status",
    "luckyWheel": "Lucky Draw",
    "option1": "Bonus",
    "option2": "Thanks",
    "option3": "Once More",
    "freeTimes": "Drawing Opportunities",
    "p1": "You will get {registerGiveCount} opportunities to draw after successful registration.",
    "p2": "If the deposit amount reaches {todayRechargeCondition} USDT yesterday (subject to UTC+0 time zone), then you can get {rechargeGiveCount} drawing opportunities.",
    "p3": "If the effective betting amount (excluding spot and contract) reaches {todayBettingCondition} USDT yesterday (subject to UTC+0 time zone), then you can get {bettingGiveCount} drawing opportunities.",
    "continuDraw": "Continue to Draw",
    "noFreeTimeTip": "You've run out of all your drawing opportunities"
  },
  "version16": {
    "totalPerMoney": "Total Amount",
    "notFu": "Order amount cannot be 0",
    "find": "Discover",
    "channel": "Channel"
  },
  "version17": {
    "drawWarmTip": "Times of lucky draw may take ten minutes to half an hour to confirm. Please wait patiently.",
    "level": "Level",
    "upLimit": "Upgrade Limitation",
    "chatRoom": "Chat",
    "group": "Group Chats",
    "privateChat": "Private Chats",
    "groupManager": "Group Manager",
    "groupMember": "Group Member",
    "groupSetting": "Group Settings",
    "totalChargeMoney": "Cumulative Deposit Amount",
    "vip9Tip": "Maximum VIP level reached",
    "dataTime": "Data Time",
    "sortType": "Sort Order",
    "loginTime": "Log-in Time",
    "lowerPeople": "Invitees",
    "chargeNum": "Deposit Amount",
    "withdrawNum": "Withdrawal Amount",
    "lowerReport": "Team Report",
    "lowerUserBetNum": "Invitees' Betting",
    "lowerUserAwardNum": "Invitees' Winning",
    "lowerUserChargeNum": "Invitees' Deposit",
    "lowerUserWithdrawNum": "Invitees' Withdrawal",
    "firstRechargeNum": "First-deposit Amount",
    "warmTip": "Show today’s data automatically",
    "publishNotice": "Publish",
    "editNotice": "Edit Notice",
    "noticeTime": "Notice Time",
    "inputNoticeTip": "Please enter the content of notice",
    "tip": "After 'All Forbidden' is enabled, only group managers are allowed to speak",
    "allNotSpeak": "All Forbidden",
    "canSpeakLevel": "Speak Level",
    "eryuanqiquan": "Binary Option",
    "optionHelpText": "Predict the up and down of the next period of time. The price at the beginning of each period is the starting price, and the price at the end of each period is the settlement price. If the settlement price is greater than the starting price, it is up (long), and if the settlement price is less than the starting price, it is down (short).",
    "optionRecord": "Option Records",
    "calcuteDirection": "Prediction Direction",
    "startPrice": "Starting Price",
    "endPrice": "Settlement Price",
    "equal": "Equal",
    "waitResult": "To be settled",
    "up": "Long",
    "down": "Short",
    "findPassword": "Retrieve Password",
    "calTime": "Prediction Time",
    "calWeek": "Prediction Period",
    "kanzhang": "Long",
    "kandie": "Short"
  },
  "version18": {
    "issue": "No.",
    "vipSort": "VIP",
    "jinyan": "Muted",
    "weijinyan": "Unmuted",
    "confirmDelete": "Are you sure you want to delete the member?",
    "confirmUnSpeak": "Are you sure you want to mute the member?",
    "confirmSpeak": "Are you sure you want to unmute the member?",
    "confirmAdd": "Are you sure you want to add the member?",
    "addMember": "Add new member",
    "delMember": "Delete the member",
    "lockSpeak": "Muted",
    "unLockSpeak": "Unmuted",
    "lockSpeakSelMember": "Muted the Member",
    "unLockSpeakSelMember": "Unmuted the Member",
    "noSelMemberTip": "Please select the member",
    "recentlyPlayGame": "Frequently Played",
    "sendMsg": "Send Message",
    "addGroupMember": "Add New Member",
    "picTip": "[Image]",
    "time3": "1 week ago",
    "chooseAll": "Select Invert",
    "personalPage": "Personal Homepage",
    "CQK3M": "3min Blocks",
    "HL3M": "3min Red_Green",
    "SD3M": "3min Discs"
  },
  "version19": {
    "avatar": "Avatar",
    "share": "Share",
    "todayProfit": "Profit of Today",
    "followBet": "Follow up",
    "Jump": "GO",
    "over": "Finished",
    "optionNumber": "Option Users",
    "optionBet": "Option Betting",
    "optionReward": "Option Winning",
    "optionProfit": "Option Profit",
    "RG_2_0": "Red",
    "RG_5_1": "Purple",
    "RG_2_1": "Green",
    "DS_16_0": "1111",
    "DS_16_1": "0000",
    "DS_4_0": "1110",
    "DS_4_1": "1000",
    "DS_2_0": "Even",
    "DS_2_1": "Odd",
    "BLK_2_0": "Big",
    "BLK_2_1": "Small",
    "BLK_2_2": "Odd",
    "BLK_2_3": "Even",
    "BLK_4_0": "Big-Odd",
    "BLK_4_1": "Big-Even",
    "BLK_4_2": "Small-Odd",
    "BLK_4_3": "Small-Even"
  },
  "version20": {
    "server": "Customer Service",
    "teacher": "Supervisor",
    "financer": "Finance",
    "planer": "Planner",
    "chatPop": "Chat floating window",
    "cancelText": "Cancel floating window",
    "betNumber": "Betting options",
    "resendMsg": "Message sending failed. Do you want to resend the message?",
    "delThisMsg": "Delete the message",
    "delThisMemberAllMsg": "Delete all messages from the member",
    "forbitThisMember": "Muted the member",
    "delThisMember": "Delete the member",
    "delTipModal": "Please select the function to be operated"
  },
  "version21": {
    "USDT_account": "USDT Account",
    "closeSwapTip": "Please select the closing ratio",
    "hasRecharge": "Recharged",
    "numberCoin": "Digital Currency",
    "none": "-",
    "fastCharge": "Fast Recharge",
    "bankTransfer": "Bank Transfer",
    "setLegalTip": "Please select your fiat currency",
    "legalCoin": "Fiat Currency",
    "orderCreateTime": "Creation Time",
    "tradeOrderNumber": "Transaction No.",
    "completeTime": "Completion Time",
    "fastWithdraw": "Fast Withdrawal",
    "bindCardTip1": "Please fill in the full name on your valid ID card, as it cannot be modified after submission",
    "bindCardTip2": "Please fill in your bank card information"
  },
  "saas": {
    "fastChargeType": "Fast Channel Recharge",
    "numberCoinChargeType": "Digital Currency Recharge",
    "humanChargeType": "Manual Recharge",
    "fastChannelWithdrawType": "Fast Channel Withdrawal",
    "numberCoinWithdrawType": "Digital Currency Withdrawal",
    "humanWithdrawType": "Manual Withdrawal",
    "fastChannelBackType": "Fast Channel Return",
    "numberChannelBackType": "Digital Currency Return",
    "betType": "Bet",
    "betProfitType": "Profit",
    "betEqualType": "Equal",
    "gameBackRewardType": "Game Rebate",
    "swapBackRewardType": "Swap Rebate",
    "exchangeBackRewardType": "Spot Rebate",
    "tranInType": "Transfer In",
    "tranOutType": "Transfer Out",
    "chargeRewardType": "Recharge Bonus",
    "firstChargeRewardType": "First-deposit Bonus",
    "regRewardType": "Registration Bonus",
    "upGradeRewardType": "Promotion Bonus",
    "luckyRewardType": "Lucky Draw",
    "realNameRewardType": "Real Name Bonus",
    "betRewardType": "Betting Bonus",
    "dailyFirstChargeRewardType": "Daily First-deposit Bonus",
    "upScoreType": "Manual Increase",
    "downScoreType": "Manual Decrease",
    "unRecharge": "Not Recharged",
    "canWithdrawBalance": "Withdrawable Balance",
    "withPopTip1": "Withdrawal is not allowed if there is unfinished flow.",
    "withPopTip2": "The part of unfinished flow needs to pay {flowFee}% of the flow fee when withdrawing.",
    "alterBankCardNameTip": "Please contact customer service if you need to change your name",
    "arriveMoney": "Amount Received",
    "withdrawNum": "Withdrawal Amount",
    "failReason": "Failure Reason",
    "tradeHash": "TXID",
    "waitCoin": "Waiting for Loan",
    "zicang": "Assets",
    "otherMoney": "Estimation of Other Assets",
    "moneyList": "Asset List",
    "onlyShowSwap": "Swap Only",
    "hideZeroMoney": "Hide Small Balances",
    "chargeFlowTip1": "The unfinished flow will be increased by {deposit}% of the recharge amout of the USDT account. For example, if 1000 USDT is recharged, then will add {depositValue} USDT of unfinished flow.",
    "chargeFlowTip2": "The unfinished flow can be deducted through the effective bet of the game / binary option. For example, if the unfinished flow is 200USDT, and a valid bet of 10USDT is generated in the game, then there is 190USDT left in the unfinished flow.",
    "chargeRecord": "Deposit Records",
    "withdrawRecord": "Withdrawal Records"
  },
  "version22": {
    "verificationCode": "CAPTCHA",
    "userNameErr": "The format of the user name is incorrect. Please enter 6-32 letters or alphanumeric combinations that can contain. and @",
    "tip": "Tip",
    "because": "Reason",
    "no": "Number",
    "auditError": "Audit Failed",
    "auditSuccess": "Audit Succeeded",
    "fundPassword": "The format of fund password is incorrect. Please enter 6 digits",
    "allEntrust": "Order History",
    "dealAmount": "Transaction Amount",
    "watcherTeamForm": "View Team Reports",
    "overview": "Overview",
    "stopBet": "The order has been closed in this period. Please stop betting",
    "balance": "Available Balance",
    "exchangeOrderMinVolume": "The minimum entrusting amount is ",
    "exchangeOrderMaxVolume": "The maximun entrusting amount is ",
    "BLK5MC": "5min Blocks (Chain Up)",
    "RG5MC": "5min Red_Green (Chain Up)",
    "DS5MC": "5min Discs (Chain Up)",
    "forbidTrade": "The transaction module has prohibited transactions",
    "minTurnoverNum": "The minimum turnover is ",
    "minSwapOpenNum": "The minimum number of contracts is x",
    "maxSwapOpenNum": "The maximum number of contracts is x at this time",
    "activity": "Activity",
    "profitRecord": "Victories",
    "service": "Service",
    "help": "Help",
    "totalAssets": "Total Value",
    "readAllLetterTip": "Are you sure you want to set all messages as read?",
    "myTeam": "My Team",
    "lv1": "Class 1",
    "lv2": "Class 2",
    "lv3": "Class 3",
    "lvAll": "Class 1/2/3",
    "calDataTime": "Statistical Time",
    "dataUpdateTip": "Data update time: {date}",
    "total": "Total Copies",
    "lastTopUserName": "Superior User Name",
    "humanAdd": "Manual Increase",
    "humanSub": "Manual Decrease",
    "realNameTime": "Authentication Time",
    "chargeTime": "Deposit Time",
    "reSubmit": "Resubmit",
    "goldMoney": "Winning Amount",
    "username": "User",
    "myNum": "My Code",
    "waitOpen": "Waiting for the draw",
    "buyAmount": "Number of copies to purchase",
    "bugNum": "Purchase corresponding copies",
    "gainCode": "Get the lottery code",
    "totalNumOpenReward": "The lottery will be opened after the total number of copies is sold out",
    "winnerGainAllReward": "The winner will get all the prizes",
    "teamLevel": "Team Level",
    "SD_serialGame": "Discs",
    "RG_serialGame": "Red_Green",
    "BLK_serialGame": "Blocks",
    "codeTip": "Deposit Address QR Code",
    "relateTopUser": "Superior",
    "lv1Num": "Class 1 - Invitees",
    "lv2Num": "Class 2 - Invitees",
    "lv3Num": "Class 3 - Invitees",
    "singleLevelTxt": " Class",
    "wrondPasswordCorrect": "The format of login password is incorrect. Please enter a combination of 8-32 letters and numbers",
    "withdrawAccount": "Withdrawal Account",
    "optionModeShutTip": "{minute} minute mode is under maintenance currently",
    "optionModuleShutTip": "Under Maintenance",
    "gameClosed": "Game closed",
    "doBet": "BET",
    "reAlterBindCard": "Your fiat currency is inconsistent with the currency of your bank card. Please update the bank card information in time",
    "t2": "As of the sum of the time values（UTC+0）of the last 50 buying records of the game before the draw",
    "emailErr": "Please enter the correct email address",
    "bigSymTip": "represent <Big>",
    "smallSymTip": "represent <Small>",
    "EvenSymTip": "represent <Even>",
    "OddSymTip": "represent <Odd>",
    "exchangeNum": "Spot Users",
    "yesterdayAward": "Winning of Yesterday",
    "yijixiaji": "Class 1 - Invitee",
    "erjixiaji": "Class 2 - Invitee",
    "sanjixiaji": "Class 3 - Invitee",
    "p3": "<ins class='paramTitle'>Team Level</ins>The more class 1 - invitees promoted, there will be the  higher the team level, and the rebate you can enjoy. The team level is divided into LV1-LV6. See the following table for the upgrade rules, where N is the number of class 1 - invitees that have been charged.",
    "p4": "<ins class='paramTitle'>Rebate Ratio of Game, Binary Option, Seconds Contract and Amusement</ins>When invitees bet on game, binary option, seconds contract and amusement, you can get rebate corresponding to their bet amount.",
    "p5": "<ins class='paramTitle'>Rebate Ratio of Spot and Swap</ins>When invitees conduct spot and swap transactions, you can get rebate corresponding to their transaction commission. If the commission is in other currencies than USDT, the rebate will be converted into USDT according to the real-time exchange rate and issued to your USDT account.",
    "flag": "Features",
    "noZero": "The transfer amount cannot be 0",
    "networkTip": "Network error, please refresh the page and reload.",
    "inviteChargeTip": "The upgrade of team level depends on the number of class 1 - invitees who successfully recharge.",
    "canWithdrawNum": "Avail. Balance",
    "withTip": "Please enter the withdrawal amount",
    "withModalTip": "The service fee includes flow fee and mining fee.",
    "totalUpNum": "Total People",
    "chargeRangeTip": "The deposit amount should be ",
    "jiaocheng": "Instruction",
    "spare1": "Alternate Information 1",
    "spare2": "Alternate Information 2",
    "loadAllGroupMsg": "All messages within 7 days have been loaded",
    "realMember": "Full membership",
    "testMember": "Test member",
    "noBlankMsg": "You cannot send a blank message",
    "maxLength1500": "Message sending failed as the content is too long",
    "sendToMore": "Message sending failed as requests are too frequent",
    "countdown": "Countdown",
    "toLongRemark": "Too many words, please delete some words",
    "tip1": "<p>* The data is subject to the statistical time. The data is updated every hour. The latest update time is: {time}; Among them, registration users / verification users / first-deposit users / First-deposit amount are updated in real time</p>",
    "tip2": "<p>* USDT balance has nothing to do with the statistical time, and always displays the current total balance of the team</p>",
    "tip3": "* The amount of deposit / withdrawal / betting / winning / rebate is subject to the statistical time. The data is updated every hour. The latest update time is: {time}",
    "tip4": "* Other data is independent of the statistical time, and the latest data is always displayed",
    "wrongImgType": "Unsupported file format",
    "addNewNotice": "Add",
    "noChoose": "Not Selected",
    "nullCoin": "/",
    "thankyou": "Thanks for participation",
    "oncemore": "Once More",
    "tip5": "Since the exchange rate may fluctuate, please refer to the actual amount",
    "xiugaichenggong": "Modification succeeded, please login again",
    "entrustNoZero": "Entrusting amount cannot be 0",
    "herTeam": "His Team",
    "betMoney": "Please enter the order amount",
    addFilterMember : 'Add all filtered members',
    delFilterMember : 'Delete all filtered members',
    forbidFilterMember : 'Mute all filtered members',
    freeFilterMember : 'Unmute all filtered members',
    theme1 : 'Game Theme 1',
    theme2 : 'Game Theme 2',
    theme3 : 'Ex Theme 1',
    theme4 : 'Ex Theme 2',
    themeTitle : 'Theme',
    maxTradeMoney: 'The maximum turnover is '
  },
  "version23": {
    inviteCenter: "Invitation Center",
    search: 'Search',
    active: 'Action',
    openDirect: 'Result Direction',
    profitAndLossAmount: 'P/L',
    levelMax: "Highest level reached",
    next: 'The next page',
    prev: 'The previous page',
    RD100N : 'Random 100',
    secondRecord : 'Seconds Contract Records',
    entrusting : 'In Consignment',
    secondOption : 'Seconds Contract',
    secondNum : 'Seconds Contract Users',
    secondReward : 'Seconds Contract Winning',
    secondBet : 'Seconds Contract Betting',
    secondProfit : 'Seconds Contract Profit',
    rebackTip : 'The rebate will be paid every hour, please wait patiently.',
    qiquan : 'Option',
    exportBtn : 'Export',
    "SB1M": "1min FlyingBoat",
    "SB3M": "3min FlyingBoat",
    "SB5M": "5min FlyingBoat",
    "SBTM": "10min FlyingBoat",
  },
  version24 : {
    goldBoom : 'Stepping on a mine',
    'MS' : 'Minesweeping',
    spRecord : 'Sports Records-Ponymuah',
    "QT1M": "1min SICBO",
    "QT3M": "3min SICBO",
    "QT5M": "5min SICBO",
    "QTTM": "10min SICBO",
    "KN1M": "1min KENO",
    "KN3M": "3min KENO",
    "KN5M": "5min KENO",
    "KNTM": "10min KENO",
    "electronRecord": "Arcade Records-Evolution",
    evoLiveRecord : '"Live Records-Evolution',
    pbRecord : 'Sports Records-PinnacleBet',
    "LOT1M": "1min LOTTO",
    "LOT3M": "3min LOTTO",
    "LOT5M": "5min LOTTO",
    "LOTTM": "10min LOTTO",
    'LOTW3' : 'LOTTO',

    "RLT1M": "1min Roulette",
    "RLT3M": "3min Roulette",
    "RLT5M": "5min Roulette",
    "RLTTM": "10min Roulette",
    "LK281M": "1min Lucky 28",
    "LK283M": "3min Lucky 28",
    "LK285M": "5min Lucky 28",
    "LK28TM": "10min Lucky 28",
    "liveRecord": "Live Records-Ezugi",
    LIMBO : 'LIMBO',
    HILO : 'HILO',
    'FSLOT' : 'Fruit',
    'RULTI' : 'Wheel',
    'RULTI_2-075_0' : 'Black',
    'RULTI_3-176_0' : 'Red',
    'RULTI_5-4_0' : 'Blue',
    'RULTI_54_0' : 'Green',
	'RB' : 'Red_Black',
	'RB_2-142_0' : 'Red',
	'RB_15_0' : 'White',
	'RB_2-142_1' : 'Black',
	
    'FST' : '4-Star Lottery',
    'FST1M' : '1min 4-Star Lottery',
    'FST5M' : '5min 4-Star Lottery',
    'FSTTM' : '10min 4-Star Lottery',
    'FST1D' : '1day 4-Star Lottery',

    'TS' : '3-Star Lottery',
    'TS1M' : '1min 3-Star Lottery',
    'TS5M' : '5min 3-Star Lottery',
    'TSTM' : '10min 3-Star Lottery',
    'TS1D' : '1day 3-Star Lottery',

    'TWTL' : 'Daily cash',
    'TWTL1M' : '1min Daily cash',
    'TWTL5M' : '5min Daily cash',
    'TWTLTM' : '10min Daily cash',
    'TWTL1D' : 'Daily cash',

    'TWLT1M' : '1min Big Lotto',
    'TWLT5M' : '5min Big Lotto',
    'TWLTTM' : '10min Big Lotto',
    'TWLTW2' : 'Big Lotto',
    'TWLT' : 'Big Lotto',


    'SPLT1M' : '1min Super Lotto',
    'SPLT5M' : '5min Super Lotto',
    'SPLTTM' : '10min Super Lotto',
    'SPLTW2' : 'Super Lotto',

    'SPLT' : 'Super Lotto',
  },
  version25 : {
    sport : 'Sports-Ponymuah',
    live :'Live-Ezugi',
    electron : 'Arcade-Evolution',
    sport_index : 'Sports',
    live_index :'Live',
    electron_index : 'Arcade',

    PG : 'Arcade-PocketGame',
    PP : 'Arcade-PragmaticPlay',
    "sportsNum": "Sports Users",
    "sportsAward": "Sports Winning",
    "sportsBet": "Sports Betting",
    "sportsProfit": "Sports Profit",

    electronNum : 'Arcade Users',
    electronAward : 'Arcade Winning',
    electronBet:'Arcade Betting',
    electronProfit : 'Arcade Profit',

    liveNum : 'Live Users',
    liveAward : 'Live Winning',
    liveBet:'Live Betting',
    liveProfit : 'Live Profit',
		
	Num : 'Users',
    Award : 'Winning',
    Bet:'Betting',
    Profit : 'Profit',

    ourGame : 'Original Games',
    "t1": "Tons of Games, One-stop Play",
    t2 : 'Global leading gaming platform'
  },
  version26 : {
    liveTeamReport : 'Real-time team reports',
    tip2 : `Can only select the time within the last 24 hours`
  },
  version27 : {
    PGrecord : 'Arcade Records-PocketGame',
    PPRecord : 'Arcade Records-PragmaticPlay',

  }
}