module.exports = {
  "nav": {
    "shouye": "首页 ",
    "hanqing": "行情",
    "jiaoyi": "交易",
    "heyue": "合约",
    "wode": "我的"
  },
  "common": {
    "login": "登录",
    "register": "注册",
    "loginregister": "登录/注册",
    "logout": "退出登录",
    "tip": "温馨提示",
    "delete": "确定要删除吗？",
    "set": "设置",
    "update": "修改",
    "more": "更多",
    "loading": "加载中",
    "zanwu": "暂无数据",
    "quxiao": "取消",
    "ok": "确定",
    "noyanzhengma": "验证码不正确",
    "clearStorage": "清除缓存",
    "telErr": "手机号码格式不正确",
    "T404": "系统正在更新中，即將恢复访问"
  },
  "loginPage": {
    "mima": "密码",
    "passwordTxt": "请输入密码"
  },
  "homePage": {
    "charge": "充值",
    "pickup": "提现",
    "exchange": "现货",
    "promotionaward": "返佣"
  },
  "pageTitle": {
    "gonggaoxiangqing": "公告",
    "wodezichang": "我的资产",
    "bangzhuxiangqing": "详情",
    "fundpwd": "资金密码",
    "yuyanshezhi": "语言",
    "huazhuang2": "划转",
    "renzheng": "实名认证",
    "chooseAlterPhoneType": "手机号",
    "chooseAlterEmailType": "邮箱",
    "letterList": "消息",
    "appDownload": "下载APP",
    "buy": "买入",
    "sell": "卖出",
    "cexiao": "撤单",
    "time": "时间",
    "type": "类型",
    "jiage": "价格",
    "weituoliang": "委托量",
    "changjiao": "已成交",
    "weituoe": "委托金额",
    "over": "已全部加载",
    "weituojia": "委托价",
    "weituoer": "委托总额",
    "quedingchedan": "是否确认撤单?",
    "chedaning": "撤单中",
    "jiaoyidui": "交易对",
    "fangxiang": "方向",
    "chongzhi": "重置",
    "xianjia": "限价",
    "shijia": "市价",
    "plan": "计划",
    "quanbu": "全部",
    "xianjiaweituo": "限价委托",
    "yichexiao": "已撤单",
    "time1": "开始时间不能大于结束时间",
    "time2": "结束时间不能小于开始时间",
    "time3": "结束时间不能大于当前时间",
    "cedancg": "撤单成功",
    "cedansb": "撤单失败",
    "mairujiage": "单价",
    "yongxu": "永续",
    "zhanghu": "账户总权益",
    "yugu": "预估强平价",
    "baozheng": "保证金率",
    "kaicang": "开仓",
    "baocang": "爆仓",
    "pincang": "平仓",
    "zhanghu2": "账户",
    "pingduo": "平多",
    "kaiduo": "开多",
    "pingkong": "平空",
    "kaigong": "开空",
    "cufajia": "触发价",
    "zuiyou": "最优价格",
    "zhang": "张",
    "keyong": "可用",
    "kekaiduo": "可开多",
    "kekaikong": "可开空",
    "duocang": "多仓可平",
    "kongcang": "空仓可平",
    "kanzhang": "看涨",
    "kandie": "看跌",
    "shuliang": "数量",
    "chicang": "当前持仓",
    "dangqianweituo": "当前委托",
    "lishiweituo": "历史委托",
    "shouyi": "收益",
    "shouyilv": "收益率",
    "gangganbeishu": "杠杆倍数",
    "chicangliang": "持仓量",
    "kepingliang": "可平量",
    "kaichangjun": "开仓均价",
    "zuixinjia": "最新价",
    "dangqianbaozheng": "当前保证金",
    "duotou": "多仓",
    "kongtou": "空仓",
    "baozhengjin": "保证金",
    "weituoshuliang": "委托数量",
    "jiesuanweik": "结算盈亏",
    "chengjiaojia": "成交价",
    "shouxufei": "手续费",
    "xiugaichenggong": "修改成功"
  },
  "views": {
    "wangjiPass": "忘记密码？",
    "confirmLogOut": "确定要退出登录吗？",
    "personalCenter": "个人中心",
    "shezhi": "设置成功",
    "yuanyouxiang": "旧邮箱验证码",
    "newyouxiang": "新邮箱验证码",
    "fsyouxiang": "发送验证码",
    "xyouxiang": "新邮箱",
    "loginPass": "登录密码",
    "baocun": "保存",
    "fschenggong": "发送成功",
    "inputyouxiang": "请输入新邮箱",
    "inputOld": "请输入旧邮箱验证码",
    "inputNew": "请输入新邮箱验证码",
    "inputPass": "请输入登录密码",
    "OldPass": "旧登录密码",
    "inputOldPass": "请输入旧登录密码",
    "NewPass": "新登录密码",
    "inputNewPass": "请输入新登录密码",
    "queren": "确认密码",
    "email": "邮箱验证码",
    "liangciPass": "两次输入的密码不一致",
    "shuruCode": "请输入验证码",
    "OldZJ": "旧资金密码",
    "inputOldZJ": "请输入旧资金密码",
    "NewZJ": "新资金密码",
    "inputNewZJ": "请输入新资金密码",
    "augeren": "个人信息",
    "aushang": "上传证件",
    "aushenghe": "审核",
    "name": "姓名",
    "inputName": "请输入您的真实姓名",
    "huzhao": "身份证件号码",
    "inputhuzhao": "请输入身份证件号码",
    "next": "下一步",
    "uphuzhao": "上传证件照片",
    "shouhuzhao": "上传手持证件照",
    "tijiao": "提交",
    "tip1": "1、证件必须在有效期内",
    "tip2": "2、个人签名的内容包含手写的{hrefArry}、本人签字和当前日期",
    "tip3": "3、请确保照片和个人签字的内容清晰可见",
    "shentijiao": "信息已提交，等待审核中",
    "fanhui": "返回",
    "bangding": "绑定",
    "inputEmail": "请输入邮箱",
    "inputPhone": "请输入手机号",
    "kefu": "请联系客服"
  },
  "list": {
    "wufaxiugia": "您当前有持仓或委托中的订单，无法修改倍数",
    "submittip1": "为了您的资金安全，请先进行实名认证",
    "submittip2": "为了您的资金安全，请先设置资金密码",
    "shuruweituo": "请输入委托价格",
    "shuruweituoliang": "请输入委托量",
    "dayukekai": "当前委托量大于可开多数量",
    "dayuduocang": "当前委托量大于多仓可平数量",
    "dayukekaikong": "当前委托量大于可开空数量",
    "dayukongcang": "当前委托量大于空仓可平数量",
    "shuruchufa": "请输入触发价",
    "zhangfubang": "涨幅榜",
    "liang": "量",
    "safar": "Safari浏览器中点击下方按钮",
    "kuaisuanzhuang": "选择【添加到主屏幕】",
    "noAccount": "没有账号?",
    "LoginReg": "立即注册",
    "butigongfuwu": "您的IP或所在地区暂不提供服务",
    "renzheng1": "已认证",
    "renzheng2": "认证失败",
    "renzheng3": "审核中",
    "renzheng4": "认证",
    "shenheing": "正在审核中，请耐心等待",
    "inputzaicimima": "请再次输入密码",
    "xuantian": "请输入邀请码（选填）",
    "yuedu": "我已阅读并同意",
    "yonghuxieyi": "用户协议",
    "hasAccount": "已有账号?",
    "denglu": "立即登录",
    "gouxuanxieyi": "请阅读并勾选用户协议",
    "inputzijinmima": "请输入资金密码",
    "yijianfankui": "意见反馈",
    "gao": "高",
    "di": "低",
    "shengdu": "深度",
    "chengjiao": "成交",
    "zixuan1": "已取消自选",
    "zixuan2": "取消自选失败",
    "zixuan3": "添加自选成功",
    "zixuan4": "添加自选失败",
    "jiaoyie": "交易总额",
    "chengjiaozonger": "成交总额",
    "chengjiaojun": "成交均价",
    "chengjiaoliang": "成交量",
    "bigmairu": "超出最大可交易数量",
    "weituochenggong": "委托成功",
    "weituoshibai": "委托失败",
    "yue": "余额",
    "bizhong": "币种",
    "huazhuanshuliang": "划转数量",
    "inputhzsl": "请输入划转数量",
    "bunengweifu": "划转数量不能为负数",
    "dayuhuazhuan": "当前划转数量大于账户可划转数量",
    "huazhuanchenggong": "划转成功",
    "huazhuangshibai": "划转失败"
  },
  "user": {
    "guizeshuoming": "规则",
    "shenheweitongguo": "审核未通过",
    "shiming": "请完成实名认证",
    "fuzhichenggong": "复制成功",
    "frozen": "冻结",
    "zhanghuquanyi": "账户权益",
    "baozhenjin": "可用保证金",
    "jiaoyishijian": "交易时间"
  },
  "tradePage": {
    "CUSTOM": "自选"
  },
  "chart": {
    "minute": "分钟"
  },
  "charge": {
    "congbi": "充值地址",
    "baocun": "保存二维码图片"
  },
  "heyuePage": {
    "yijianpingcang": "一键平仓",
    "copyerr": "复制失败",
    "pincang": "平仓张数不能为0"
  },
  "yaoqin": {
    "zuce": "注册时间",
    "showPersonInfo": "查看您的个人信息",
    "myinvitelink": "邀请链接",
    "my_column4": "级别",
    "yaoqing": "邀请",
    "dengji": "等级成长进度",
    "mubiao": "目标",
    "zong": "总返佣",
    "jiaoyi": "交易明细",
    "wode": "邀请码",
    "huodong": "活动礼金"
  },
  "version3": {
    "hash": "哈希值",
    "betTip": "猜每10分钟第一个ETH区块的哈希值末位，0-7为小，8-f为大；0、2、4、6、8、a、c、e为双，1、3、5、7、9、b、d、f为单。",
    "betTip2": "猜哈希值末位，0-7为小，8-f为大；0、2、4、6、8、a、c、e为双，1、3、5、7、9、b、d、f为单。",
    "betMoneyTip": "单注金额",
    "doBet": "立即投注",
    "issue": "No.",
    "blockHigh": "区块高度",
    "blockTime": "出块时间",
    "hash2": "哈希值",
    "gameRecord": "游戏记录",
    "betTime": "下单时间",
    "pickUpAddress": "提现地址",
    "arriveAccountNum": "到账数量",
    "remark": "备注",
    "contachService": "联系客服",
    "totalBetMoney": "下单总额",
    "unOpen": "未开奖",
    "maintain": "维护中",
    "hasBingGo": "已中奖",
    "unBingGo": "未中奖",
    "outOfBalance": "余额不足",
    "chooseBetNumTip": "请选择投注选项",
    "chooseBetMoney": "请输入单注金额",
    "minBetAmount": "单注最小金额为",
    "maxBetAmount": "单注最大金额为",
    "chooseWithDrawAddressTip": "请选择地址",
    "inputRemarkTip": "请输入提现地址",
    "confirmOrder": "确认订单",
    "manji": "VIP等级已满",
    "selWithAddress": "请选择提现地址",
    "withNumTip": "提现数量应为",
    "unLiuShui": "未完成流水",
    "withdrawChannel": "提现通道"
  },
  "version4": {
    "game": "游戏",
    "baocangIntro": "在{date}，您的{account}合约账户总权益为 0 USDT，保证金率≤0，触发强制平仓，也就是爆仓。",
    "geshi": "请输入正确的金额格式",
    "mingc": "成交量",
    "zhangd": "涨跌",
    "quanqiu": "全球运营",
    "yiliu": "一流团队",
    "yinhang": "银行级加密"
  },
  "version5": {
    "rechargeWay": "充值通道",
    "inputChargeNum": "请输入充值金额",
    "receivePeople": "收款人",
    "bankCard": "银行卡",
    "bank": "银行",
    "bankNumber": "银行账号",
    "bankProvince": "开户省份",
    "bankCity": "开户城市",
    "bankBrancher": "开户支行",
    "rengong": "人工充提"
  },
  "version7": {
    "p6": "请先绑定银行卡",
    "jinxingzhong": "交易中",
    "yiwancheng": "已完成",
    "shibai": "交易失败",
    "nichen": "用户名",
    "p8": "单笔限额",
    "username": "请输入用户名"
  },
  "version8": {
    "noArrive0": "到账金额不能为0",
    "xuantian": "请输入邀请码",
    "result": "结果",
    "color": "颜色",
    "hlRule": "猜0-9的数值，1、3、5、7、9为绿色，0、2、4、6、8为红色，0或5同时也为紫色。"
  },
  "Transfer": {
    "FriendsReport": "团队报表",
    "jintian": "今天",
    'qiantian' : '前天',
    "zuotian": "昨天",
    "benzhou": "本周",
    "shangzhou": "上周",
    "benyue": "本月",
    "shangyue": "上月",
    "zuce": "注册人数",
    "shoucong": "首充人数",
    "chongzhi": "充值人数",
    "tixian": "提现人数",
    "heyue": "合约人数",
    "youxi": "游戏人数",
    "touzu": "游戏投注",
    "zhongjiang": "游戏中奖",
    "shimingrenshu": "实名人数",
    "status_1": "审核中",
    "status_3": "失败",
    "status_4": "成功",
    "jilu": "记录"
  },
  "version9": {
    "SD1MRule": "猜色碟的红白数量，相同颜色的色碟有偶数个为双，相同颜色的色碟有奇数个为单。",
    "FourRed": "4红0白",
    "ThreeRedOneWhite": "3红1白",
    "FourWhite": "0红4白",
    "OneRedThreeWhite": "1红3白"
  },
  "version10": {
    "titMode3": "提现时，需支付矿费以确保链上交易得到处理，当前矿费为 {minTxFee} USDT。",
    "onGame": "进行中",
    "tuihui": "已退回",
    "hasJump": "已跳伞",
    "player": "玩家",
    "money": "金额",
    "jump": "跳伞",
    "jumpMulti": "跳伞倍数",
    "betMoney": "下单金额",
    "autoJumpTip": "自动跳伞倍数（选填）",
    "p1": "最小跳伞倍数应大于",
    "p2": "最大跳伞倍数为",
    "autoJump": "自动跳伞",
    "boomValue": "爆点倍数",
    "crash": "坠毁",
    "noRepeatOrder": "请勿重复下注",
    "jumpSuccess": "跳伞成功",
    "tip1_plan": "1、自动跳伞倍数为选填项，填写后，飞机到达对应倍数后您将自动跳伞，也可在飞机坠毁前进行手动跳伞；若未填写，仍可以在飞机坠毁前进行手动跳伞",
    "tip2_plan": "2、我们会从已跳伞玩家的盈利金额中抽水{amount}%",
    "dizhi": "请输入正确的地址"
  },
  "version11": {
    "trend": "走势",
    "goldenBroad": "中奖播报",
    "broadTxt": "恭喜 {userName} 喜中 <span class='Tcolor'>{winMoney}</span> USDT",
    "TS": "跳伞",
    "CQK1M": "1分区块",
    "CQK5M": "5分区块",
    "CQK": "10分区块（链上）",
    "HL": "1分红绿",
    "HL5M": "5分红绿",
    'RG30S' : '30秒红绿',
    "SD1M": "1分色碟",
    "SD5M": "5分色碟",
    "Gogame": "进入游戏",
    "yinli": "盈利金额",
    "munber": "盈利金额=交易盈亏+活动礼金+返佣",
    "Gyinkui": "游戏盈亏",
    "Hyinkui": "合约盈亏",
    "Hlijin": "活动礼金",
    "Yfanyong": "返佣"
  },
  "version12": {
    "hl10mRule": "猜每10分钟内第一个ETH的哈希值从末位往前数的第一个0-9的数值，1、3、5、7、9为绿色，0、2、4、6、8为红色，0或5同时也为紫色。",
    "sd10mRule": "猜每10分钟内第一个ETH区块的哈希值末四位，每一位对应一个色碟，0-7为白，8-f为红，相同颜色的色碟有偶数个为双，相同颜色的色碟有奇数个为单。",
    "SD10M": "10分色碟（链上）",
    "HL10M": "10分红绿（链上）",
    "refresh": "刷新"
  },
  "version13": {
    "kaijiangzhong": "开奖中",
    "canyu": "我的",
    "kaijiang": "已开奖",
    "join": "参与",
    "buyAmount": "请输入购买份数",
    "minBetAmount": "最小购买份数为",
    "maxBetAmount": "最大购买份数为",
    "tipMax": "请勿超过最大份数",
    "totalVal": "总价值",
    "jindu": "进度",
    "leave": "剩余",
    "goldNumber": "中奖号码",
    "myGoldNumber": "我的号码",
    "val": "数值",
    "mode": "数值",
    "unJoin": "未参与",
    "calNumber": "计算中",
    "eachPrice": "每份价格",
    "currentIssue": "本期参与记录",
    "hisIssue": "开奖前50个参与记录",
    "showDetail": "查看",
    "buy": "购买",
    "buyTime": "购买时间",
    "luckNumber": "兑奖号码",
    "totalBuyAmount": "购买总额",
    "buyTip2": "请输入正确的份数格式",
    "goldMember": "获奖会员",
    "openTime": "开奖时间",
    "Wtime": "预计维护时间",
    "invitationNote": "邀请说明",
    "p1": "<ins class='paramTitle'>邀请好友</ins>进入“邀请中心”，复制邀请链接或邀请码，分享给您的好友。好友通过您的邀请码注册就可以成为您的下级。",
    "p2": "<ins class='paramTitle'>赚取返佣</ins>下级进行交易的时候，您可以获得相应的返佣，最多支持三级下级。 举个例子，您邀请了好友A，A邀请了B，B邀请了C，则A、B、C在平台内进行游戏、合约等交易，您都可以获得相应的返佣。",
    "fuk": "请付款",
    "guoq": "已过期",
    "exchtishi": "请使用本人实名银行卡向以上账号转账",
    "exchtishi2": "在转账过程中请勿备注BTC、USDT等信息，防止汇款被拦截等问题",
    "quxiaoOrder": "取消订单",
    "chengong": "我已付款成功",
    "fukuanren": "付款姓名",
    "fukuanrenTi": "请填写付款姓名",
    "zhanghao": "付款账号",
    "zhanghaoTi": "请填写付款账号",
    "yihang": "付款银行",
    "yihangTi": "请填写付款银行",
    "jietu": "付款截图",
    "jietuTi": "请上传付款截图",
    "queren": "请确认您已付款",
    "queren2": "恶意确认将直接冻结账户",
    "YYYG": "幸运1U",
    "dragon": "排行榜",
    "paimng": "排名"
  },
  "version15": {
    "only60Days": "仅保留60天内的记录",
    "only100Records": "仅展示最近100条记录",
    "status": "状态",
    "luckyWheel": "幸运抽奖",
    "option1": "奖金",
    "option2": "谢谢参与",
    "option3": "再来一次",
    "freeTimes": "抽奖次数",
    "p1": "注册成功后即可获得 {registerGiveCount} 次抽奖机会。",
    "p2": "昨日（以UTC+0时区为准）充值金额达到 {todayRechargeCondition} USDT，可获得 {rechargeGiveCount} 次抽奖机会。",
    "p3": "昨日（以UTC+0时区为准）有效投注金额（不包含现货、合约）达到 {todayBettingCondition} USDT，可获得 {bettingGiveCount} 次抽奖机会。",
    "continuDraw": "继续抽奖",
    "noFreeTimeTip": "您的抽奖次数已用完"
  },
  "version16": {
    "totalPerMoney": "下单总额",
    "notFu": "下单金额不能为负数",
    "find": "发现",
    "channel": "频道"
  },
  "version17": {
    "drawWarmTip": "赠送的抽奖次数，可能需要十分钟到半小时才能确认，请耐心等候",
    "level": "等级",
    "upLimit": "升级门槛",
    "chatRoom": "聊天",
    "group": "群组",
    "privateChat": "私聊",
    "groupManager": "管理员",
    "groupMember": "群成员",
    "groupSetting": "群设置",
    "totalChargeMoney": "累计充值",
    "vip9Tip": "VIP等级已满",
    "dataTime": "数据时间",
    "sortType": "排序方式",
    "loginTime": "登录时间",
    "lowerPeople": "下级人数",
    "chargeNum": "充值金额",
    "withdrawNum": "提现金额",
    "lowerReport": "团队报表",
    "lowerUserBetNum": "下级投注",
    "lowerUserAwardNum": "下级中奖",
    "lowerUserChargeNum": "下级充值",
    "lowerUserWithdrawNum": "下级提现",
    "firstRechargeNum": "首充金额",
    "warmTip": "默认显示今天数据",
    "publishNotice": "发布",
    "editNotice": "编辑公告",
    "noticeTime": "公告时间",
    "inputNoticeTip": "请输入公告内容",
    "tip": "全员禁言启用后，只允许群管理员发言",
    "allNotSpeak": "全员禁言",
    "canSpeakLevel": "发言等级",
    "eryuanqiquan": "二元期权",
    "optionHelpText": "预测下一时间段的涨跌。每期开始时的价格为起始价，每期结束时的价格为结算价，结算价大于起始价则为涨，结算价小于起始价则为跌。",
    "optionRecord": "期权记录",
    "calcuteDirection": "预测方向",
    "startPrice": "起始价",
    "endPrice": "结算价",
    "equal": "无涨跌",
    "waitResult": "待结算",
    "up": "涨",
    "down": "跌",
    "findPassword": "找回密码",
    "calTime": "预测时间",
    "calWeek": "预测周期",
    "kanzhang": "看涨",
    "kandie": "看跌"
  },
  "version18": {
    "issue": "期号",
    "vipSort": "VIP等级",
    "jinyan": "已禁言",
    "weijinyan": "未禁言",
    "confirmDelete": "确定要删除成员吗？",
    "confirmUnSpeak": "确定要禁言成员吗？",
    "confirmSpeak": "确定要解禁成员吗？",
    "confirmAdd": "确定要添加成员吗？",
    "addMember": "添加成员",
    "delMember": "删除成员",
    "lockSpeak": "禁言",
    "unLockSpeak": "解禁",
    "lockSpeakSelMember": "禁言成员",
    "unLockSpeakSelMember": "解禁成员",
    "noSelMemberTip": "请选择会员",
    "recentlyPlayGame": "最近常玩",
    "sendMsg": "发消息",
    "addGroupMember": "添加成员",
    "picTip": "[图片]",
    "time3": "1星期前",
    "chooseAll": "反选",
    "personalPage": "个人主页",
    "CQK3M": "3分区块",
    "HL3M": "3分红绿",
    "SD3M": "3分色碟"
  },
  "version19": {
    "avatar": "头像",
    "share": "分享",
    "todayProfit": "今日盈利",
    "followBet": "跟投",
    "Jump": "前往",
    "over": "已结束",
    "optionNumber": "期权人数",
    "optionBet": "期权投注",
    "optionReward": "期权中奖",
    "optionProfit": "期权盈亏",
    "RG_2_0": "红",
    "RG_5_1": "紫",
    "RG_2_1": "绿",
    "DS_16_0": "1111",
    "DS_16_1": "0000",
    "DS_4_0": "1110",
    "DS_4_1": "1000",
    "DS_2_0": "双",
    "DS_2_1": "单",
    "BLK_2_0": "大",
    "BLK_2_1": "小",
    "BLK_2_2": "单",
    "BLK_2_3": "双",
    "BLK_4_0": "大单",
    "BLK_4_1": "大双",
    "BLK_4_2": "小单",
    "BLK_4_3": "小双"
  },
  "version20": {
    "server": "客服",
    "teacher": "导师",
    "financer": "财务",
    "planer": "计划员",
    "chatPop": "聊天浮窗",
    "cancelText": "取消浮窗",
    "betNumber": "投注选项",
    "resendMsg": "消息发送失败，是否重发消息？",
    "delThisMsg": "删除此条消息",
    "delThisMemberAllMsg": "删除此成员全部消息",
    "forbitThisMember": "禁言成员",
    "delThisMember": "删除成员",
    "delTipModal": "请选择要操作的功能"
  },
  "version21": {
    "USDT_account": "USDT账户",
    "closeSwapTip": "请选择平仓比例",
    "hasRecharge": "已充值",
    "numberCoin": "数字货币",
    "none": "-",
    "fastCharge": "快捷充值",
    "bankTransfer": "银行转账",
    "setLegalTip": "请选择您使用的法定货币",
    "legalCoin": "法定货币",
    "orderCreateTime": "创建时间",
    "tradeOrderNumber": "交易单号",
    "completeTime": "完成时间",
    "fastWithdraw": "快捷提现",
    "bindCardTip1": "请填写您有效身份证件上的完整姓名，提交后不可修改",
    "bindCardTip2": "请填写您的银行卡信息"
  },
  "saas": {
    "fastChargeType": "快捷通道充值",
    "numberCoinChargeType": "数字货币充值",
    "humanChargeType": "人工充值",
    "fastChannelWithdrawType": "快捷通道提现",
    "numberCoinWithdrawType": "数字货币提现",
    "humanWithdrawType": "人工提现",
    "fastChannelBackType": "快捷通道退回",
    "numberChannelBackType": "数字货币退回",
    "betType": "下注",
    "betProfitType": "盈利",
    "betEqualType": "平局",
    "gameBackRewardType": "游戏返佣",
    "swapBackRewardType": "合约返佣",
    "exchangeBackRewardType": "现货返佣",
    "tranInType": "转入",
    "tranOutType": "转出",
    "chargeRewardType": "充值礼金",
    "firstChargeRewardType": "首充礼金",
    "regRewardType": "注册礼金",
    "upGradeRewardType": "晋级礼金",
    "luckyRewardType": "幸运抽奖",
    "realNameRewardType": "实名礼金",
    "betRewardType": "投注礼金",
    "dailyFirstChargeRewardType": "每日首充礼金",
    "upScoreType": "人工增加",
    "downScoreType": "人工减少",
    "unRecharge": "未充值",
    "canWithdrawBalance": "可提余额",
    "withPopTip1": "提现时，存在未完成流水，不可提现。",
    "withPopTip2": "提现时，未完成流水的部分需支付{flowFee}%的流水手续费。",
    "alterBankCardNameTip": "如需修改姓名，请联系客服",
    "arriveMoney": "到账金额",
    "withdrawNum": "提现数量",
    "failReason": "失败原因",
    "tradeHash": "交易哈希",
    "waitCoin": "等待放款",
    "zicang": "资产",
    "otherMoney": "其他资产估算",
    "moneyList": "资产列表",
    "onlyShowSwap": "只看合约",
    "hideZeroMoney": "隐藏0资产",
    "chargeFlowTip1": "充值到USDT账户的余额，会增加{deposit}%的未完成流水。例如充值1000USDT，则未完成流水增加{depositValue}USDT。",
    "chargeFlowTip2": "未完成流水可通过游戏/二元期权的有效投注扣除。例如未完成流水为200USDT，在游戏内产生了10USDT的有效投注，则未完成流水还剩190USDT。",
    "chargeRecord": "充值记录",
    "withdrawRecord": "提现记录"
  },
  "version22": {
    "verificationCode": "图形验证码",
    "userNameErr": "用户名格式错误，请输入可包含.和@的6-32位的字母或字母数字组合",
    "tip": "提示",
    "because": "原因",
    "no": "号码",
    "auditError": "审核失败",
    "auditSuccess": "审核成功",
    "fundPassword": "资金密码格式错误，请输入6位数字",
    "allEntrust": "全部委托",
    "dealAmount": "成交金额",
    "watcherTeamForm": "查看团队报表",
    "overview": "总览",
    "stopBet": "本期已封单，停止下注",
    "balance": "可用余额",
    "exchangeOrderMinVolume": "最小委托量为",
    "exchangeOrderMaxVolume": "最大委托量为",
    "BLK5MC": "5分区块（链上）",
    "RG5MC": "5分红绿（链上）",
    "DS5MC": "5分色碟（链上）",
    "forbidTrade": "该交易模块已禁止交易",
    "minTurnoverNum": "最小成交额为",
    "minSwapOpenNum": "最小张数为x张",
    "maxSwapOpenNum": "本次的最大张数为x张",
    "activity": "活动",
    "profitRecord": "战绩",
    "service": "客服",
    "help": "帮助",
    "totalAssets": "总资产",
    "readAllLetterTip": "确定要将所有消息置为已读吗？",
    "myTeam": "我的团队",
    "lv1": "1级",
    "lv2": "2级",
    "lv3": "3级",
    "lvAll": "123级",
    "calDataTime": "统计时间",
    "dataUpdateTip": "数据更新时间：{date}",
    "total": "总份数",
    "lastTopUserName": "上级用户名",
    "humanAdd": "人工增加",
    "humanSub": "人工减少",
    "realNameTime": "实名时间",
    "chargeTime": "充值时间",
    "reSubmit": "重新提交",
    "goldMoney": "中奖金额",
    "username": "用户",
    "myNum": "我的号码",
    "waitOpen": "等待开奖",
    "buyAmount": "购买份数",
    "bugNum": "购买相应份数",
    "gainCode": "获得兑奖码",
    "totalNumOpenReward": "总份数售完后开奖",
    "winnerGainAllReward": "中奖者获得全部奖金",
    "teamLevel": "团队等级",
    "SD_serialGame": "猜色碟",
    "RG_serialGame": "猜红绿",
    "BLK_serialGame": "猜区块",
    "codeTip": "充值地址二维码",
    "relateTopUser": "所属上级",
    "lv1Num": "1级人数",
    "lv2Num": "2级人数",
    "lv3Num": "3级人数",
    "singleLevelTxt": "级",
    "wrondPasswordCorrect": "登录密码格式错误，请输入8-32位的字母与数字的组合",
    "withdrawAccount": "提现账户",
    "optionModeShutTip": "当前{minute}分钟模式处在维护中",
    "optionModuleShutTip": "维护中",
    "gameClosed": "游戏已关闭",
    "doBet": "投注",
    "reAlterBindCard": "您的法定货币与银行卡的币种不一致，请及时更新银行卡信息",
    "t2": "截至开奖前该款游戏最后50个购买记录的时间（UTC+0）数值之和",
    "emailErr": "请输入正确的邮箱地址",
    "bigSymTip": "表示<大>",
    "smallSymTip": "表示<小>",
    "EvenSymTip": "表示<双>",
    "OddSymTip": "表示<单>",
    "exchangeNum": "现货人数",
    "yesterdayAward": "昨日中奖",
    "yijixiaji": "1级下级",
    "erjixiaji": "2级下级",
    "sanjixiaji": "3级下级",
    "p3": "<ins class='paramTitle'>团队等级</ins>推广的一级下级越多，团队等级越高，可享受的返佣越高。团队等级分为LV1-LV6，升级规则见如下表格，其中N为已充值的一级下级人数。",
    "p4": "<ins class='paramTitle'>游戏、期权、秒合约、娱乐返佣比例</ins>下级进行游戏投注、二元期权投注、秒合约投注、娱乐投注，您可获得其投注额相应比例的返佣。",
    "p5": "<ins class='paramTitle'>现货、合约返佣比例</ins>下级进行现货交易、合约交易，您可获得其交易手续费相应比例的返佣。若手续费为USDT外的其他币种，则返佣将根据实时汇率换算为USDT发放到您的USDT账户中。",
    "flag": "特征",
    "noZero": "划转数量不能为0",
    "networkTip": "网络错误，请刷新页面重新加载",
    "inviteChargeTip": "团队等级的升级，取决于充值成功的一级下级人数",
    "canWithdrawNum": "可提余额",
    "withTip": "请输入提现数量",
    "withModalTip": "手续费包含流水手续费和矿费。",
    "totalUpNum": "总人数",
    "chargeRangeTip": "充值金额应为",
    "jiaocheng": "教程",
    "spare1": "备用1",
    "spare2": "备用2",
    "loadAllGroupMsg": "已加载七天内全部消息",
    "realMember": "正式会员",
    "testMember": "测试会员",
    "noBlankMsg": "不能发送空白消息",
    "maxLength1500": "消息发送失败，内容过长",
    "sendToMore": "消息发送失败，请求过于频繁",
    "countdown": "倒计时",
    "toLongRemark": "字数过多，请删减",
    "tip1": "<p>* 数据以统计时间为准，每小时更新一次数据，最近更新时间为：{time}；其中，注册人数/实名人数/首充人数/首充金额是实时更新的</p>",
    "tip2": "<p>* USDT余额与统计时间无关，总是显示团队当前总余额</p>",
    "tip3": "* 充值/提现/投注/中奖/返佣金额以统计时间为准，每小时更新一次数据，最近更新时间为：{time}",
    "tip4": "* 其他数据与统计时间无关，总是显示当前最新数据",
    "wrongImgType": "不支持的文件格式",
    "addNewNotice": "新增",
    "noChoose": "暂不选择",
    "nullCoin": "无",
    "thankyou": "谢谢参与",
    "oncemore": "再来一次",
    "tip5": "汇率可能变动，以实际金额为准",
    "xiugaichenggong": "修改成功，请重新登录",
    "entrustNoZero": "委托量不能为0",
    "herTeam": "他的团队",
    "betMoney": "请输入下单金额",
    addFilterMember : '添加筛选后全部成员',
    delFilterMember : '删除筛选后全部成员',
    forbidFilterMember : '禁言筛选后全部成员',
    freeFilterMember : '解禁筛选后全部成员',
    fastOrder : '立即下单',
    setTimeOrder : '定时下单',
    theme1 : '游戏主题1',
    theme2 : '游戏主题2',
    theme3 : '交易所主题1',
    theme4 : '交易所主题2',
    themeTitle : '主题',
    maxTradeMoney: '最大成交额为'
  },
  "version23": {
    inviteCenter: "邀请中心",
    search: '搜索',
    active: '操作',
    openDirect: '开奖方向',
    profitAndLossAmount: '盈亏金额',
    levelMax: "已达到最高等级",
    next: '下一页',
    prev: '上一页',
    RD100N : '随机100',
    secondRecord : '秒合约记录',
    entrusting : '委托中',
    secondOption : '秒合约',
    secondNum : '秒合约人数',
    secondReward : '秒合约中奖',
    secondBet : '秒合约投注',
    secondProfit : '秒合约盈亏',
    rebackTip : '返佣每小时发放一次，请耐心等待',
    qiquan : '期权',
    exportBtn : '导出',
    SB1M : '1分飞艇',
    SB3M : '3分飞艇',
    SB5M : '5分飞艇',
    SBTM : '10分飞艇',
  },
  version24 : {
    goldBoom : '踩雷',
    'MS' : '扫雷',
    spRecord : '体育记录-Ponymuah',
    'QT1M' : '1分骰宝',
    'QT3M' : '3分骰宝',
    'QT5M' : '5分骰宝',
    'QTTM' : '10分骰宝',
    'KN1M' : '1分KENO',
    'KN3M' : '3分KENO',
    'KN5M' : '5分KENO',
    'KNTM' : '10分KENO',
    electronRecord : '电游记录-Evolution',
    'RLT1M' : '1分轮盘',
    'RLT3M' : '3分轮盘',
    'RLT5M' : '5分轮盘',
    'RLTTM' : '10分轮盘',
    'LOT1M' : '1分LOTTO',
    'LOT3M' : '3分LOTTO',
    'LOT5M' : '5分LOTTO',
    'LOTTM' : '10分LOTTO',
    'LOTW3' : 'LOTTO',
    
    'LK281M' : '1分幸运28',
    'LK283M' : '3分幸运28',
    'LK285M' : '5分幸运28',
    'LK28TM' : '10分幸运28',
    liveRecord : '真人记录-Ezugi',
    evoLiveRecord : '真人记录-Evolution',
    pbRecord : '体育记录-PinnacleBet',

    LIMBO : 'LIMBO',
    HILO : 'HILO',
    'FSLOT' : '水果',
    'RULTI' : '转盘',
    'RULTI_2-075_0' : '黑',
    'RULTI_3-176_0' : '红',
    'RULTI_5-4_0' : '蓝',
    'RULTI_54_0' : '绿',
    'RB' : '红黑',
    'RB_2-142_0' : '红',
    'RB_15_0' : '白',
    'RB_2-142_1' : '黑',

    'FST' : '四星彩',
    'FST1M' : '1分四星彩',
    'FST5M' : '5分四星彩',
    'FSTTM' : '10分四星彩',
    'FST1D' : '1天四星彩',

    'TS' : '三星彩',
    'TS1M' : '1分三星彩',
    'TS5M' : '5分三星彩',
    'TSTM' : '10分三星彩',
    'TS1D' : '1天三星彩',

    'TWTL' : '今日彩',
    'TWTL1M' : '1分今日彩',
    'TWTL5M' : '5分今日彩',
    'TWTLTM' : '10分今日彩',
    'TWTL1D' : '1天今日彩',

    'TWLT1M' : '1分大乐透',
    'TWLT5M' : '5分大乐透',
    'TWLTTM' : '10分大乐透',
    'TWLTW2' : '大乐透',
    'TWLT' : '大乐透',


    'SPLT1M' : '1分威力彩',
    'SPLT5M' : '5分威力彩',
    'SPLTTM' : '10分威力彩',
    'SPLTW2' : '威力彩',

    'SPLT' : '威力彩',
    
  },
  version25 : {
    sport : '体育-Ponymuah',
    live :'真人-Ezugi',
    electron : '电游-Evolution',
    PG : '电游-PocketGame',
    PP : '电游-PragmaticPlay',

    electron_index : '电游',
    sport_index : '体育',
    live_index :'真人',

    sportsNum : '体育人数',
    sportsAward : '体育中奖',
    sportsBet:'体育投注',
    sportsProfit : '体育盈亏',

    electronNum : '电游人数',
    electronAward : '电游中奖',
    electronBet:'电游投注',
    electronProfit : '电游盈亏',

    liveNum : '真人人数',
    liveAward : '真人中奖',
    liveBet:'真人投注',
    liveProfit : '真人盈亏',
		
	Num : '人数',
    Award : '奖金',
    Bet:'投注',
    Profit : '盈亏',

    ourGame : '原创游戏',
    t1 : '海量游戏 一站畅玩',
    t2 : '全球领先的游戏平台'
  },
  version26 : {
    liveTeamReport : '实时团队报表',
    tip2 : `只能选择近24小时内的时间`
  },
  version27 : {
    PGrecord : '电游记录-PocketGame',
    PPRecord : '电游记录-PragmaticPlay'

  }
}